
* {
    margin: 0px;
    padding: 0px;
}

.full-height {
    height: auto;
}

.top-bg {

}

.bottom-bg {
    float: right;
    position: absolute;
    bottom: 0px;
}

.img-resposive {
    width: 100%;
    height: auto;
}

.content {
    text-align: center;
    margin-top: 15%;
}

.title-info {
    font-size: 38px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 20px;
}

.user-name {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
}

.paragraph {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
}

.get-started {
    margin-top: 80px;
    margin-bottom: 150px;
}

.get-started a {
    background-color: #d7184c;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    padding: 18px 65px;
    border-radius: 10px;
    font-size: 25px;
    font-weight: 600;
}

.gap {
    height: 55px;
}


@media only screen and (max-width: 768px) {
    .top-bg {
        width: 60%;
    }

    .bottom-bg {
        position: relative;
        bottom: 0px;
        right: 0px;
        width: 60%;
    }

    .content {
        text-align: center;
        margin-bottom: 0%;
        margin-top: 0px;
        padding: 20px 30px;
    }

    .title-info {
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
    }

    .paragraph {
        font-size: 15px;
    }

    .user-name {
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        margin-top: 10px;
        margin-bottom: 25px;
    }

    .get-started {
        margin-top: 65px;
        margin-bottom: 30px;
    }

    .get-started a {
        background-color: #d7184c;
        color: #fff;
        padding: 12px 40px;
        border-radius: 10px;
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
    }

    .img-resposive {
        width: 70%;
    }
}
