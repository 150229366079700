/* Colors
--------------------*/
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d7d9df;
  border-radius: 50px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}
table th {
  font-size: 14px;
  padding: 0.9rem;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
table th:nth-child(1) {
  padding-left: 47px !important;
}
table th:last-child {
  padding-right: 25px !important;
}
table td {
  vertical-align: middle !important;
  font-size: 14px;
}
table td:nth-child(1) {
  padding-left: 45px !important;
}
table td:last-child {
  padding-right: 21px !important;
}

.table-dark {
  background: #FFFFFF;
  position: relative;
}
.table-dark thead {
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
}
.table-dark thead th {
  color: #141944;
  border-color: #E3E3E3;
  background: #F6F6F6;
}
.table-dark thead td {
  border-color: red;
}

.card-header {
  padding: 0px;
  margin-top: 10px;
  background-color: white;
  border-bottom: none;
  border: 0px solid black;
}

.back-button {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  color: #333;
}
.back-button:hover {
  color: #a4a4a4;
  text-decoration: none;
}

.button-area {
  width: 23vh;
}

.box-icon {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  cursor: pointer;
}
.box-icon .notify-dot {
  padding: 0px;
  background: #F6D3D3;
  border: 0.5px solid #FFFFFF;
  color: #ffffff;
  border-radius: 50%;
  line-height: 0.8;
  height: 16px;
  width: 16px;
  text-align: center;
  position: absolute;
  top: -5px;
  right: -9px;
}
.box-icon .notify-dot span {
  position: center;
  font-size: 11px;
  font-weight: 600;
  color: #EB3535;
}

.circle-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #EFE7FF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-icon i {
  font-size: 22px;
}
.circle-icon h6 {
  font-size: 14px;
}
.circle-icon h4 {
  font-size: 22px;
}

.search-sortby {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.has-search {
  position: relative;
}

.has-search .form-control {
  padding-left: 2.375rem;
  border-radius: 25px;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  top: 0;
  left: 0;
}

.sort-by {
  position: relative;
}

.sort-by-header {
  display: flex;
  align-items: baseline;
  cursor: pointer;
}
.sort-by-header p {
  padding-bottom: 0px;
}

.sort-by-dropdown {
  position: absolute;
  width: 168px;
  height: 120px;
  top: 100%;
  left: -93px;
  list-style: none;
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

.sort-by-dropdown li {
  padding-top: 7px;
  padding-bottom: 8px;
  padding-left: 20px;
  border-bottom: 1px solid #ececec;
}

.sort-by-dropdown li:last-child {
  border-bottom: none;
}

.sort-by-dropdown a {
  font-size: 14px;
  text-align: center;
  color: #475467;
}

.sort-by-dropdown {
  opacity: 1;
  pointer-events: auto;
}

.task-list h6 {
  font-size: 20px;
  color: #141944;
}

.top-box {
  padding: 0 15px;
  justify-content: space-between;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
}

.total-students {
  display: flex;
}

.table-shadow {
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
}

.box-border {
  border: 2px solid #CFCFCF;
  border-radius: 12px;
}

.box-border-sm {
  border: 1px solid #E6E6E6;
}

.table-light {
  background: #FFFFFF;
  position: relative;
}
.table-light thead {
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
}
.table-light thead th {
  border-color: #E3E3E3;
  background: #f5f5f5;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #141944;
  border-bottom: 1px solid #E3E3E3;
}
.table-light tbody tr td {
  border-color: #E3E3E3;
}
.table-light tbody tr td h6 {
  font-weight: 500;
  font-size: 14px;
  color: #4575EE;
}
.table-light tbody tr td p {
  font-weight: 600;
  font-size: 14px;
  color: #475467;
  margin-bottom: 5px;
}
.table-light tbody tr td .btn-md {
  background-color: transparent;
  border: 1px solid grey;
  color: gray;
}
.table-light tbody tr:hover {
  background-color: #fafafa;
}

.assignment-submission tbody tr td .submission-status {
  font-size: 10px;
}
.assignment-submission tbody tr td .submission-status.submitted {
  color: #30B264;
}
.assignment-submission tbody tr td .submission-status.not-submitted {
  color: #FAA31A;
}
.assignment-submission tbody tr td .submission-status.missed {
  color: red;
}
.assignment-submission tbody tr td .fa-clock {
  font-size: 15px;
  color: #30B264;
}

.page-navigation {
  padding: 0px 282px;
  display: flex;
  justify-content: center;
}
.page-navigation .pagination {
  width: 630px;
  justify-content: space-between;
}
.page-navigation .pagination .page-item {
  display: flex;
  align-items: center;
}
.page-navigation .pagination .page-item .page-link {
  border: none;
  font-size: 14px;
  color: #8d8d8d;
  background-color: transparent;
}
.page-navigation .pagination .page-item .page-link span {
  font-size: 20px;
}
.page-navigation .pagination .page-item .page-active {
  font-weight: 600;
  color: #212121;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

.rbt-token {
  background-color: #808299;
  border-radius: 25px;
  color: #ffffff;
  padding: 5px 20px;
  margin: 3px;
}

.rbt-token-active {
  background-color: #141944;
}

.rbt-input-multi.form-control {
  height: auto !important;
}

label {
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.custom-file-label {
  left: 0px !important;
  right: 15px !important;
  height: calc(1.5em + 0.75rem + 8px) !important;
  border-radius: 25px !important;
  padding: 0.53rem 0.75rem !important;
}

.custom-file-label:after {
  height: calc(1.5em + 0.75rem + 6px) !important;
  border-radius: 25px !important;
  padding: 0.53rem 0.75rem !important;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
  outline: 0px;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button {
  outline: 0px;
}

.btn-none {
  background: none;
  border: 0px;
  color: rgba(255, 255, 255, 0.5);
  outline: 0 !important;
}
.btn-none:hover {
  color: #fff;
}

.btn-none-default {
  background: none;
  border: 0px;
  color: #1c285a;
  outline: 0 !important;
}
.btn-none-default:hover {
  opacity: 0.8;
}

.btn-group-sm > .btn, .btn-sm {
  padding: 3px;
  font-size: 0.75rem;
  width: 30px;
  height: 30px;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #d6dbe3;
}

/*---Theme Custom
---------------------------------------------------*/
.c-purple {
  color: #722CFF !important;
}

.c-blue {
  color: #4575EE !important;
}

.c-red {
  color: #FD5750 !important;
}

.c-success-green {
  color: #41ec9d !important;
}

.c-assesment-success {
  color: #23d0f0 !important;
}

.c-orange {
  color: #FAA31A !important;
}

.c-light-orange {
  color: #FFF3E0 !important;
}

.c-deep-orange {
  color: #FB6340 !important;
}

.c-grey {
  color: #8a8b8c !important;
}

.middle-center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobile {
  display: none;
}
@media only screen and (max-width: 480px) {
  .mobile {
    display: block;
  }
}

.desktop {
  display: block;
}
@media only screen and (max-width: 480px) {
  .desktop {
    display: none;
  }
}

.school-bg {
  background-image: url("school_bg.png") !important;
  background-size: 710px;
  background-position: center;
  background-position-y: 108px;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 480px) {
  .school-bg {
    background-size: 590px;
  }
}

.login-school-bg {
  background-image: url("school_bg.png") !important;
  background-size: 530px;
  background-position-y: 165px;
  background-position-x: center;
  background-repeat: no-repeat;
}

.bg-assignment-dark {
  background-color: #d9eef3 !important;
}

.bg-assignment-light {
  background-color: #F1FAFC !important;
}

.bg-event-dark {
  background-color: #FAA31A !important;
}

.bg-event-light {
  background-color: #fff7e8 !important;
}

.bg-c-purple {
  background-color: #722CFF !important;
}

.bg-c-blue-light {
  background-color: #E7EDFF;
}

.bg-c-orange-light {
  background-color: #FFF3E0;
}

.bg-c-red-light {
  background-color: #FFE8E7;
}

.bg-c-dim-red {
  background-color: rgba(216, 20, 20, 0.1);
}

.bg-c-purple-light {
  background-color: #EFE7FF;
}

.bg-c-light-grey {
  background-color: #fdfdfd !important;
}

.bg-c-blue {
  background-color: #4575EE !important;
}

.bg-c-red {
  background-color: #FD5750 !important;
}

.bg-c-orange {
  background-color: #FAA31A !important;
}

.bg-c-light-orange {
  background-color: #FFF3E0 !important;
}

.bg-c-deep-orange {
  background-color: #FB6340 !important;
}

.border-c-purple {
  border-color: #722CFF !important;
}

.border-c-blue {
  border-color: #4575EE !important;
}

.border-c-red {
  border-color: #FD5750 !important;
}

.border-c-orange {
  border-color: #FAA31A !important;
}

.border-c-light-orange {
  border-color: #FFF3E0 !important;
}

.border-c-deep-orange {
  border-color: #FB6340 !important;
}

/* Radius
--------------------*/
.radius-10 {
  border-radius: 10px !important;
}

.radius-15 {
  border-radius: 15px !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.radius-25 {
  border-radius: 25px !important;
}

.radius-50-percent {
  border-radius: 50% !important;
}

/* widths
-----------*/
.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

/* Fonts
--------------------*/
f-30px {
  font-size: 30px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

/* Positions
--------------------*/
.right-0 {
  right: 0;
}

/* Alert boxes
--------------------*/
.alert-box-default {
  background-color: #f1f3f9;
  padding: 23px 20px;
  margin-bottom: 15px;
  border: 1px dashed #d2d8ea;
  border-radius: 6px;
}

/* Buttons
--------------------*/
.btn-light {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  height: auto;
  padding: 10px 12px;
}

.btn-dark {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  border-color: #071036;
  background: #071036;
  height: auto;
  padding: 10px 12px;
  line-height: 1.3;
}
.btn-dark i {
  color: #FFFFFF;
}
.btn-dark:hover {
  background: transparent;
  color: #071036;
}
.btn-dark:hover i {
  color: #071036;
}

.btn-outline-dark {
  font-size: 14px;
  color: #071036;
  border-color: #071036;
  height: auto;
  padding: 11px 12px;
  line-height: 1.3;
}
.btn-outline-dark i {
  color: #071036;
}
.btn-outline-dark:hover {
  background: #071036;
}
.btn-outline-dark:hover i {
  color: #FFFFFF;
}

.btn-outline-dark-more {
  width: 100%;
  font-size: 14px;
  color: #071036;
  border: 1px dashed #07103661;
  height: calc(1.5em + 0.75rem + 16px);
}
.btn-outline-dark-more i {
  color: #071036;
}
.btn-outline-dark-more:hover {
  background: #071036;
  color: #FFFFFF;
}
.btn-outline-dark-more:hover i {
  color: #FFFFFF;
}

.btn-outline-light {
  font-size: 14px;
  color: #FFFFFF;
  border-color: #FFFFFF;
  height: calc(1.5em + 0.75rem + 8px);
}
.btn-outline-light i {
  color: #FFFFFF;
}
.btn-outline-light:hover i {
  color: #FFFFFF;
}

.btn-circle-blue {
  background-color: #4575EE;
  color: #ffffff;
  width: 38px;
  height: 38px;
  padding: 0px;
  border: 0px;
  border-radius: 50%;
  transition: 0.3s;
}
.btn-circle-blue:hover {
  background-color: gray;
}

.btn-circle-orange {
  background-color: #FAA31A;
  color: #ffffff;
  width: 30px;
  height: 30px;
  padding: 0px;
  border: 0px;
  border-radius: 50%;
  transition: 0.3s;
}
.btn-circle-orange:hover {
  background-color: gray;
}

.btn-icon-assignment {
  background-color: transparent;
  padding: 0px;
  color: #3593a5;
  display: flex;
  align-items: center;
  border: 0px;
}
.btn-icon-assignment i {
  font-size: 14px;
  margin-right: 5px;
}
.btn-icon-assignment p {
  font-size: 14px;
  margin: 0px !important;
  color: #3593a5 !important;
}

.btn-red-lg {
  background-color: #FD5750;
  color: #ffffff;
  padding: 15px 25px;
  border: 0px;
  border-radius: 25px;
  width: 100%;
  transition: 0.3s;
}
.btn-red-lg:hover {
  background-color: gray;
}

.btn-red-sm {
  background-color: #FD5750;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
  border: 0px;
  border-radius: 25px;
  transition: 0.3s;
}
.btn-red-sm:hover {
  background-color: gray;
}

.btn-red-sm-bordered {
  background-color: transparent;
  color: #FD5750;
  border: 1px solid #FD5750;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  transition: 0.3s;
  border-radius: 25px;
}
.btn-red-sm-bordered a {
  color: #FD5750;
}
.btn-red-sm-bordered i {
  margin-right: 10px;
}
.btn-red-sm-bordered:hover {
  background-color: #FD5750;
  color: #FFFFFF;
}

.btn-purple-sm {
  background-color: #722CFF;
  color: #ffffff;
  font-size: 12px;
  padding: 2px 10px;
  border: 0px;
  border-radius: 5px;
  transition: 0.3s;
}
.btn-purple-sm:hover {
  background-color: gray;
}

.btn-purple-sm-bordered {
  background-color: transparent;
  color: #4b5ead;
  border: 1px solid #4b5ead;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  border-radius: 5px;
  transition: 0.3s;
}
.btn-purple-sm-bordered a {
  color: #4b5ead;
}
.btn-purple-sm-bordered i {
  margin-right: 5px;
}
.btn-purple-sm-bordered:hover {
  background-color: gray;
}

.btn-purple-md {
  background-color: #F7DEDE;
  color: #FE2E2E;
  padding: 5px 10px;
  transition: 0.3s;
  font-size: 13px;
  font-weight: 700;
  border: 0px solid #FE2E2E;
  border-radius: 25px;
  width: 11vh;
}
.btn-purple-md:hover {
  background-color: #fcebeb;
  color: #FE2E2E;
}

.btn-purple-lg {
  background-color: #722CFF;
  color: #ffffff;
  padding: 15px 25px;
  border: 0px;
  width: 100%;
  transition: 0.3s;
  border-radius: 25px;
}
.btn-purple-lg:hover {
  background-color: gray;
}

.btn-blue-sm {
  background-color: #4575EE;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
  border: 0px;
  transition: 0.3s;
  border-radius: 25px;
}
.btn-blue-sm:hover {
  background-color: gray;
}

.btn-blue-sm-bordered {
  background-color: transparent;
  color: #4575EE;
  border: 1px solid #4575EE;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  transition: 0.3s;
  border-radius: 25px;
}
.btn-blue-sm-bordered a {
  color: #4575EE;
}
.btn-blue-sm-bordered i {
  margin-right: 5px;
}
.btn-blue-sm-bordered:hover {
  background-color: #4575EE;
  color: #FFFFFF;
}

.btn-blue-lg {
  background-color: #4575EE;
  color: #ffffff;
  padding: 15px 25px;
  border: 0px;
  width: 100%;
  transition: 0.3s;
  border-radius: 25px;
}
.btn-blue-lg:hover {
  background-color: gray;
}

.btn-orange-sm {
  background-color: #FAA31A;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
  border: 0px;
  transition: 0.3s;
  border-radius: 25px;
}
.btn-orange-sm:hover {
  background-color: gray;
}

.btn-orange-sm-bordered {
  background-color: transparent;
  color: #FAA31A;
  border: 1px solid #FAA31A;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  transition: 0.3s;
  border-radius: 25px;
}
.btn-orange-sm-bordered a {
  color: #FAA31A;
}
.btn-orange-sm-bordered i {
  margin-right: 10px;
}
.btn-orange-sm-bordered:hover {
  background-color: #FAA31A;
  color: #FFFFFF;
}

.btn-orange-lg {
  background-color: #FAA31A;
  color: #ffffff;
  padding: 15px 25px;
  border: 0px;
  width: 100%;
  transition: 0.3s;
  border-radius: 25px;
}
.btn-orange-lg:hover {
  background-color: gray;
}

.btn-deep-orange-lg {
  background-color: #FB6340;
  color: #ffffff;
  padding: 15px 25px;
  border: 0px;
  width: 100%;
  transition: 0.3s;
  border-radius: 25px;
}
.btn-deep-orange-lg:hover {
  background-color: gray;
}

.btn-light-orange {
  background-color: #FFF3E0;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;
}
.btn-light-orange:hover {
  background-color: #fcdbb4;
}

.btn-light-purple {
  background-color: #DCCBFF;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;
}
.btn-light-purple:hover {
  background-color: #bdade5;
}

.btn-light-blue {
  background-color: #E7EDFF;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;
}
.btn-light-blue:hover {
  background-color: #b4c7ff;
}

.btn-light-green {
  background-color: #E2FFEF;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;
}
.btn-light-green:hover {
  background-color: #a2d5a3;
}

.btn-light-pink {
  background-color: #FFDDFA;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;
}
.btn-light-pink:hover {
  background-color: #e3aedb;
}

.btn-light-grey {
  background-color: #EFEFEF;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;
}
.btn-light-grey:hover {
  background-color: #dddddd;
}

.btn-light-cyan {
  background-color: #E0F9FF;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;
}
.btn-light-cyan:hover {
  background-color: #b2edfd;
}

.btn-light-red {
  background-color: #FFDBDD;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;
}
.btn-light-red:hover {
  background-color: #ffb6b9;
}

.btn-light-whitesmoke {
  background-color: #F1FFD5;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;
}
.btn-light-whitesmoke:hover {
  background-color: #d0eea6;
}

.btn-deep-blue-lg {
  background-color: #FF0505;
  color: #ffffff;
  padding: 10px 25px;
  width: 100%;
  transition: 0.3s;
  border: 2px solid #1c285a;
  border-radius: 50px;
  cursor: pointer;
}
.btn-deep-blue-lg:hover {
  border: 2px solid #FF0505;
  background-color: transparent;
  color: #FF0505;
}

.btn-md {
  padding: 5px 20px;
  border: 0px;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid grey;
  color: gray;
}
.btn-md:hover {
  color: black;
  background-color: #dedede !important;
}

.dropdown-sidenav {
  background-color: transparent;
  border: 0px;
  font-size: 14px;
  transition: 0.3s;
}
.dropdown-sidenav h6 {
  color: #071036;
}
.dropdown-sidenav .sb-nav-link-icon i {
  font-size: 20px;
  color: #071036;
}
.dropdown-sidenav .sb-nav-link-icon img {
  width: 34px;
}
.dropdown-sidenav:hover {
  background-color: rgba(255, 216, 216, 0.71);
  border-radius: 6px;
}
@media only screen and (max-width: 1280px) {
  .dropdown-sidenav {
    font-size: 12px;
  }
}

.sidenav-last-child {
  width: 234px;
}
.sidenav-last-child .sidenav-border:nth-last-child {
  border-bottom: 0px solid #e9e6fb !important;
}
.sidenav-last-child .sidenav-border .dropdown-sidenav {
  width: 100%;
}
.sidenav-last-child .sidenav-border .dropdown-sidenav span {
  display: flex;
  justify-content: space-between;
  width: 16vw;
}

.sidenav-submenu {
  padding-left: 0px;
  list-style: none;
  margin-top: 0px;
}
.sidenav-submenu a {
  color: black;
  font-size: 13px;
  padding: 5px 0px;
}
.sidenav-submenu a li {
  padding: 0px 0px;
}
.sidenav-submenu a li .sub-menu {
  display: flex;
  align-items: baseline;
  width: 90%;
}
.sidenav-submenu a li .sb-nav-link-icon {
  color: #475467;
  font-size: 9px;
}

.rbc-calendar {
  padding: 15px 25px;
  border-radius: 6px;
  margin-bottom: 30px;
}

.rbc-off-range-bg {
  background: #f1f3f9;
}

.rbc-header {
  padding: 15px;
}

.rbc-date-cell {
  padding-right: 0px;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 10px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  text-align: center;
  background: transparent;
}

.rbc-toolbar button {
  border: 1px solid #ddd8f9;
}

.rbc-toolbar button:hover {
  color: #000000;
  background-color: #edeaff;
  border-color: #bcb8d4;
}

.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: white;
  background-color: #7764e4;
  border-color: #7764e4;
  outline: 0;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: 0px 0px 0px;
  color: white;
  background-color: #7764e4;
  border-color: #7764e4;
  font-weight: 500;
}

.rbc-month-view {
  background: #293152;
  border-radius: 15px;
}

.rbc-month-header {
  color: #FFFFFF;
}

.rbc-month-row {
  overflow: visible;
}

.rbc-toolbar {
  margin-bottom: 30px;
}
.rbc-toolbar .rbc-toolbar-label {
  font-size: 20px;
  font-weight: 600;
}

/* MUI Custom */
.Component-iconContainer-2 .close {
  opacity: 0.5;
  color: #FFFFFF;
}

.jss2 .close {
  color: #FFFFFF;
}

.MuiPaper-root {
  background-color: #141944 !important;
  color: #FFFFFF !important;
  border-radius: 15px !important;
}

.MuiTableCell-body {
  color: rgba(255, 255, 255, 0.8) !important;
}

.MuiSvgIcon-root {
  color: gainsboro !important;
}

.MuiButton-root {
  color: rgba(255, 255, 255, 0.8) !important;
}

.MUIDataTableSelectCell-headerCell-41 {
  background-color: #141944 !important;
}

.MUIDataTableHeadCell-fixedHeader-51 {
  background-color: #141944 !important;
}

.MuiTypography-colorInherit {
  color: rgba(255, 255, 255, 0.8) !important;
}

/* Headings
--------------------*/
.left-icon-heading-sm {
  display: flex;
  align-items: center;
}
.left-icon-heading-sm i {
  margin-right: 7px;
}
.left-icon-heading-sm p {
  margin: 0;
  font-weight: 700;
  font-size: 17px;
}

/* Inputs
--------------------*/
.custom-file-input {
  height: 10px;
}

.input-default {
  width: 100%;
}
.input-default::placeholder {
  color: red;
  font-size: 12px;
}
.input-default .form-control {
  height: calc(1.5em + 0.75rem + 8px);
  font-size: 14px;
  border-radius: 25px;
  border: 0px solid #A3A9B3;
  background-color: #F0F2F5;
  opacity: 1;
  overflow: auto;
}
.input-default .block-group .add-assets {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.input-default .block-group .add-assets i {
  font-size: 20px;
  color: #1c285a;
  margin-right: 10px;
}
.input-default .block-group .add-assets p {
  color: #1c285a;
  margin: 0px;
}

.input-default-white {
  width: 100%;
}
.input-default-white::placeholder {
  color: red;
  font-size: 12px;
}
.input-default-white .form-control {
  height: calc(1.5em + 0.75rem + 8px);
  font-size: 14px;
  border-radius: 25px;
  border: 1px solid #e0e3e9;
  opacity: 1;
  background: #FFFFFF;
  overflow: auto;
}
.input-default-white .block-group .add-assets {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.input-default-white .block-group .add-assets i {
  font-size: 20px;
  color: #1c285a;
  margin-right: 10px;
}
.input-default-white .block-group .add-assets p {
  color: #1c285a;
  margin: 0px;
}
.input-default-white .css-yk16xz-control {
  height: calc(1.5em + 0.75rem + 8px);
  font-size: 14px;
  border-radius: 25px;
  border: 1px solid #e0e3e9;
  opacity: 1;
  background: #FFFFFF;
  overflow: auto;
}
.input-default-white .css-1pahdxg-control {
  height: calc(1.5em + 0.75rem + 8px);
  font-size: 14px;
  border-radius: 25px;
  border: 1px solid #e0e3e9;
  opacity: 1;
  background: #FFFFFF;
  overflow: auto;
}

.body-water-mark {
  margin-top: 4%;
  display: flex;
}
.body-water-mark i {
  font-size: 6rem;
  color: #1c285a;
  opacity: 0.1;
  margin-right: 10px;
}
.body-water-mark h5 {
  width: 338px;
  opacity: 0.3;
  line-height: 1.4;
  font-size: 1rem;
  color: #1c285a;
}

.bg-watermark {
  position: absolute;
  top: 100%;
  right: -16%;
}
.bg-watermark img {
  opacity: 0.035;
  width: 100%;
}

.input-reg {
  width: 100%;
}
.input-reg label {
  margin-bottom: 20px;
  font-weight: 600;
}
.input-reg .form-control {
  background-color: #f1f1f5;
  height: calc(1.5em + 0.75rem + 12px);
  border: 1px solid #d3d8e3;
  opacity: 0.8;
  padding: 0.375rem 1.4rem;
  border-radius: 25px;
}
.input-reg .block-group .add-assets {
  display: flex;
  align-items: center;
}
.input-reg .block-group .add-assets i {
  font-size: 20px;
  color: #1c285a;
  margin-right: 10px;
}
.input-reg .block-group .add-assets p {
  color: #1c285a;
  margin: 0px;
}

.input-secondary input {
  background-color: white;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: 100%;
  padding: 2px 8px;
  min-height: 52px;
}
.input-secondary input[type=select] {
  min-height: 52px;
  max-height: 52px;
}
.input-secondary input[type=radio] {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 5px;
}

.list-group {
  margin-bottom: 18px;
}
.list-group .list-group-item {
  background-color: #fbfbfb;
  border: 1px solid #d2d8f1;
  border-radius: 15px !important;
}
.list-group .list-group-item:hover {
  background-color: #f1f3f9;
  cursor: pointer;
}
.list-group .list-group-item p {
  font-size: 12px;
  color: #bcc3da;
  margin: 0px;
}
.list-group .list-group-item i {
  font-size: 20px;
}

/* Blocks
--------------------*/
.block-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.block-group .block {
  width: calc(100% - 0px);
}
.block-group .block-10 {
  width: calc(10% - 0px);
}
.block-group .block-20 {
  width: calc(20% - 0px);
}
.block-group .block-25 {
  width: calc(25% - 0px);
}
.block-group .block-30 {
  width: calc(30% - 0px);
}
.block-group .block-33 {
  width: calc(33.33% - 0px);
}
.block-group .block-40 {
  width: calc(40% - 0px);
}
.block-group .block-50 {
  width: calc(50% - 0px);
}
.block-group .block-60 {
  width: calc(60% - 0px);
}
.block-group .block-70 {
  width: calc(70% - 0px);
}
.block-group .block-80 {
  width: calc(80% - 0px);
}
.block-group .block-90 {
  width: calc(90% - 0px);
}
@media only screen and (max-width: 560px) {
  .block-group .block-10 {
    width: 100%;
  }
  .block-group .block-20 {
    width: 100%;
  }
  .block-group .block-25 {
    width: 100%;
  }
  .block-group .block-30 {
    width: 100%;
  }
  .block-group .block-33 {
    width: 100%;
  }
  .block-group .block-40 {
    width: 100%;
  }
  .block-group .block-50 {
    width: 100%;
  }
  .block-group .block-60 {
    width: 100%;
  }
  .block-group .block-70 {
    width: 100%;
  }
  .block-group .block-80 {
    width: 100%;
  }
  .block-group .block-90 {
    width: 100%;
  }
}

/* Side Nav Area
--------------------*/
.sb-nav-fixed #layoutSidenav {
  display: flex;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
  width: 314px;
  height: 95vh;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  min-width: 300px;
  padding-top: 0px;
  padding-bottom: 30px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 20px;
  margin-top: 80px;
  background-color: white;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
  position: fixed;
  min-width: 300px;
  max-width: 300px;
  max-height: 90vh;
  overflow: overlay;
  z-index: 2;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu:hover {
  overflow: overlay;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .brand {
  padding: 1.75rem 1.8rem 0.75rem;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .brand h4 {
  font-size: 1.7rem;
  font-weight: 600;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .brand img {
  width: 180px;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .nav {
  padding-left: 10px;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .nav .sb-sidenav-menu-heading {
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  color: #1c285a;
  opacity: 1;
  padding: 1.75rem 1.6rem 1rem;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .nav .nav-link {
  display: flex;
  padding: 0;
  margin: 0 0px;
  color: #182f46;
  border-radius: 6px;
  cursor: pointer;
  outline: 0;
  text-align: left;
  font-size: 14px;
  transition: 0.3s;
  border: 0;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .nav .nav-link:hover {
  border-radius: 6px;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .nav .nav-link h6 {
  font-size: 16px;
  font-weight: 700;
  color: #071036;
  margin-bottom: 0;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .nav .nav-link button {
  color: black;
  display: flex;
  align-items: baseline;
  text-align: left;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .nav .nav-link button svg {
  padding-top: 7px;
  margin-right: 7px;
  height: 25px;
  transform: scale(1.3);
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .nav .nav-link a {
  color: black;
}
@media only screen and (max-width: 1600px) {
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .brand img {
    width: 150px;
  }
}
@media only screen and (max-width: 1280px) {
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
    display: none;
  }
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .brand h4 {
    font-size: 1.5rem;
  }
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
    min-width: 50px;
  }
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu .sidenav-border h6 {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
    display: none;
  }
}

/* Main Area
--------------------*/
.react-datepicker-popper {
  z-index: 111;
}

.sb-nav-fixed #layoutSidenav {
  background-color: #F2F2F2;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
  width: 100%;
  min-height: calc(100vh - 0px);
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_content .content-wrap {
  padding-top: 80px;
  left: 0px;
  right: 0px;
  overflow: visible;
  position: relative;
  margin-left: 0px;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_content .content-wrap main h3 {
  font-size: 24px;
  font-weight: 600;
  color: #071036;
}
@media only screen and (max-width: 480px) {
  .sb-nav-fixed #layoutSidenav #layoutSidenav_content {
    padding-left: 0px;
    margin-left: 0px;
  }
  .sb-nav-fixed #layoutSidenav #layoutSidenav_content .content-wrap {
    padding-top: 120px;
  }
}

/* Grids
--------------------*/
.grid-fixed-1 {
  width: 100%;
  max-height: 40vh;
}
.grid-fixed-1 img {
  position: relative;
  object-fit: cover;
  width: calc(100% - 0px);
  max-height: 40vh;
  margin-bottom: 4px;
  border-radius: 10px;
}

.single-img-custom {
  height: 229px;
  overflow: auto;
  margin-bottom: 10px;
}
.single-img-custom .grid-fixed-1 {
  width: 100%;
  max-height: max-content;
}
.single-img-custom .grid-fixed-1 img {
  position: relative;
  object-fit: fill;
  width: auto;
  max-height: max-content;
  margin-bottom: 20px;
  border-radius: 15px;
}

.grid-even-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.grid-even-2 img {
  position: relative;
  object-fit: cover;
  width: calc(50% - 2px);
  margin-bottom: 4px;
  border-radius: 5px;
}

.grid-fixed-2 {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 5px;
}
.grid-fixed-2:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.grid-fixed-2:nth-child(2) {
  border-radius: 0 5px 5px 0;
}
.grid-fixed-2:nth-child(n+3) {
  display: none;
}
.grid-fixed-2 img {
  object-fit: cover;
  width: 100%;
  margin-bottom: 4px;
}

.grid-fixed-over-4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
}
.grid-fixed-over-4 div img {
  position: relative;
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  min-height: 300px;
  border-radius: 5px;
}
.grid-fixed-over-4 div img:nth-child(n+5) {
  display: none;
}
.grid-fixed-over-4 div:nth-child(1) {
  border-radius: 5px 0px 0px 0px;
}
.grid-fixed-over-4 div:nth-child(2) {
  border-radius: 0px 5px 0px 0px;
}
.grid-fixed-over-4 div:nth-child(3) {
  border-radius: 0px 5px 5px 0px;
}
.grid-fixed-over-4 div:nth-child(4) {
  position: relative;
  border-radius: 0px 5px 5px 0px;
  filter: brightness(0.2);
}
.grid-fixed-over-4 div:nth-child(4) .count-overlay {
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
}
.grid-fixed-over-4 div:nth-child(n+5) {
  display: none;
}
.grid-fixed-over-4 .count-overlay {
  display: none;
}

.grid-fixed-4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
}
.grid-fixed-4 div img {
  position: relative;
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  min-height: 300px;
  border-radius: 5px;
}
.grid-fixed-4 div img:nth-child(n+5) {
  display: none;
}
.grid-fixed-4 div:nth-child(1) {
  border-radius: 5px 0px 0px 0px;
}
.grid-fixed-4 div:nth-child(2) {
  border-radius: 0px 5px 0px 0px;
}
.grid-fixed-4 div:nth-child(3) {
  border-radius: 0px 5px 5px 0px;
}
.grid-fixed-4 div:nth-child(4) {
  border-radius: 0px 5px 5px 0px;
}
.grid-fixed-4 div:nth-child(n+5) {
  display: none;
}

.grid-fixed-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
}
.grid-fixed-3 div img {
  position: relative;
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  min-height: 300px;
  border-radius: 5px;
}
.grid-fixed-3 div img:nth-child(n+4) {
  display: none;
}
.grid-fixed-3 div:nth-child(1) {
  border-radius: 5px 0px 0px 0px;
  grid-area: 1/span 2;
}
.grid-fixed-3 div:nth-child(2) {
  border-radius: 0px 5px 0px 0px;
}
.grid-fixed-3 div:nth-child(3) {
  border-radius: 0px 5px 5px 0px;
}
.grid-fixed-3 div:nth-child(n+4) {
  display: none;
}

/* Cards
--------------------*/
.card-default {
  padding: 15px 15px;
  margin-bottom: 15px;
  background-color: #FFFFFF;
  border-radius: 20px;
  border-color: none;
}
.card-default .card-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 10px -25px -14px -28px; */
  padding: 10px 0px;
}
.card-default .card-heading h4 {
  font-weight: 500;
  font-size: 20px;
  color: #475467;
}
.card-default .card-heading h4 svg {
  margin-right: 8px;
}
.card-default .card-heading .title {
  display: flex;
  align-items: center;
}
.card-default .card-heading .title img {
  margin-right: 10px;
  vertical-align: middle;
}
.card-default .card-heading .title h6 {
  margin: 0px;
}
.card-default .card-heading .title p {
  font-weight: 500;
  font-size: 18px;
  color: #475467;
  padding-left: 9px;
  margin-bottom: 0px;
}
.card-default .card-heading .nav {
  border-bottom: 0px solid #e0e0e0;
  margin-top: 20px;
  margin-bottom: 12px;
  box-shadow: RGB(255 255 255/10%) 0px 2px 0px 0px inset, RGB(50 50 93/25%) 0px 10px 31px -20px, RGB(0 0 0/30%) 0px 24px 2px -30px;
  padding: 0 14px;
}
.card-default .card-heading .nav .nav-item a {
  font-size: 14px;
  font-weight: 400;
  color: #878787;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 25px;
  padding-bottom: 8px;
}
.card-default .card-heading .nav-pills .nav-link.active, .card-default .card-heading .nav-pills .show > .nav-link {
  color: white;
  background-color: #E58F06;
  border-radius: 16px;
  padding: 8px 20px;
}
@media only screen and (max-width: 1600px) {
  .card-default {
    padding: 20px 20px;
    margin-bottom: 20px;
  }
  .card-default .card-heading .title img {
    margin-right: 6px;
    width: 16px;
  }
  .card-default .card-heading .title h6 {
    font-size: 12px;
  }
  .card-default .card-heading button {
    font-size: 10px;
  }
}
@media only screen and (max-width: 560px) {
  .card-default {
    width: 100%;
  }
}

.card-default-transparent {
  padding: 0px 15px;
  background-color: transparent;
  border-radius: 6px;
}
.card-default-transparent .card-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 10px -25px -14px -28px; */
  padding: 0px 0px 7px 0px;
}
.card-default-transparent .card-heading .title {
  display: flex;
  align-items: center;
}
.card-default-transparent .card-heading .title svg {
  margin-right: 10px;
}
.card-default-transparent .card-heading .title i {
  font-size: 22px;
  margin-right: 10px;
  vertical-align: middle;
  color: #1c285a;
  opacity: 0.4;
}
.card-default-transparent .card-heading .title h6 {
  font-weight: 500;
  margin: 0px;
}
.card-default-transparent .card-heading button {
  border: 0;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  height: 39px;
  background: #F0F2F5;
  border-radius: 60px;
  color: #475467;
  font-weight: 500;
  font-size: 14px;
}
.card-default-transparent .card-heading button svg {
  margin-right: 7px;
}
.card-default-transparent .card-heading button i {
  color: #475467;
  margin-right: 7px;
}
.card-default-transparent .card-heading button:hover i {
  transform: rotate(90deg);
  transition: 0.3s;
}
.card-default-transparent .card-heading button:hover {
  background-color: #e3e5ee;
  transition: 0.3s;
}
@media only screen and (max-width: 1600px) {
  .card-default-transparent {
    padding: 20px 20px;
    margin-bottom: 20px;
  }
  .card-default-transparent .card-heading .title img {
    margin-right: 6px;
    width: 16px;
  }
  .card-default-transparent .card-heading .title h6 {
    font-size: 12px;
  }
  .card-default-transparent .card-heading button {
    font-size: 10px;
  }
  .card-default-transparent .circle-icon {
    align-items: center;
    background: #0066ff;
    border-radius: 50%;
    display: flex;
    height: 46px;
    justify-content: center;
    width: 46px;
  }
}
@media only screen and (max-width: 560px) {
  .card-default-transparent {
    width: 100%;
  }
}

.card-sm {
  padding: 18px 26px;
  margin-bottom: 25px;
  background-color: #FFFFFF;
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  -webkit-box-shadow: 0px 4px 6px 4px RGB(146 153 177/4%);
  box-shadow: 0px 4px 6px 4px RGB(146 153 177/4%);
}

.card-sm-dark {
  padding: 12px 20px;
  margin-bottom: 25px;
  background-color: #141944;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  -webkit-box-shadow: 0px 4px 6px 4px RGB(146 153 177/4%);
  box-shadow: 0px 4px 6px 4px RGB(146 153 177/4%);
}

.card-sm-dark-borderd {
  padding: 12px 20px;
  margin-bottom: 25px;
  background-color: #1c285a12;
  border: 1px solid #b0b2c5;
  border-radius: 20px;
  -webkit-box-shadow: 0px 4px 6px 4px RGB(146 153 177/4%);
  box-shadow: 0px 4px 6px 4px RGB(146 153 177/4%);
}

.card-alt {
  padding: 18px;
  margin-bottom: 30px;
  background-color: #F6F9FC;
  border-radius: 6px;
}
.card-alt .card-alt-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gainsboro;
  margin: 0px -18px;
  margin-bottom: 24px;
  padding: 0px 18px;
  padding-bottom: 18px;
}
.card-alt .card-alt-heading .title {
  display: flex;
  align-items: center;
}
.card-alt .card-alt-heading .title img {
  margin-right: 10px;
  vertical-align: middle;
}
.card-alt .card-alt-heading .title h6 {
  margin: 0px;
  font-size: 16px;
  color: #475467;
}

.qns-heading {
  border-radius: 10px;
  padding: 12px 18px;
  background: #1c285a;
}
.qns-heading h6 {
  margin: 0rem;
  font-size: 18px;
  font-weight: 700;
  color: #FFFFFF;
  text-transform: capitalize;
}

.ans-card {
  position: relative;
  padding: 15px 15px;
  margin-bottom: 16px;
  border-radius: 20px;
  background-color: #ffffff;
  width: max-content;
}
.ans-card .score {
  background: transparent;
  padding: 15px 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  border-radius: 20px 20px 0px 20px;
  text-align: center;
  float: unset;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.ans-card .score p {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #141944;
  margin-right: 10px;
}
.ans-card .score .state {
  display: flex;
  align-items: center;
}
.ans-card .score .state h4 {
  font-size: 32px;
  font-weight: 600;
  color: #4575EE;
  margin-right: 10px;
  margin-bottom: 0px;
}
.ans-card .score button {
  top: 22px;
  right: 22px;
  height: auto;
  width: auto;
  padding: 8px 11px;
}

/* ImageUploader
--------------------*/
.avatar img {
  object-fit: cover;
  vertical-align: middle;
  width: 45px;
  height: 45px;
  border: 3px solid #f1f3f9;
  border-radius: 50%;
  font-size: 12px;
}

.post-by {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.post-by .avatar {
  margin-right: 10px;
}
.post-by .avatar img {
  width: 45px;
  height: 45px;
}
.post-by .post-by-info h6 {
  font-size: 16px;
  font-weight: 600;
}
.post-by .post-by-info p {
  font-size: 12px;
  color: #acb3c9;
  margin-bottom: 0px;
}

.post-by-sm {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.post-by-sm .avatar {
  margin-right: 10px;
}
.post-by-sm .avatar img {
  width: 35px;
  height: 35px;
}
.post-by-sm .post-by-info h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}
.post-by-sm .post-by-info p {
  font-size: 14px;
  color: #b3b3b3;
  margin-bottom: 0px;
}

.comment-area {
  display: flex;
  margin-top: 50px;
}
.comment-area .avatar img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.comment-area .card-alt {
  width: auto;
  min-width: 100px;
  margin-bottom: 0px;
  padding: 8px 12px;
}
.comment-area .card-alt h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}
.comment-area .card-alt p {
  font-size: 12px;
  color: #949aa9;
  margin-bottom: 3px;
}

.add-comment-area {
  display: flex;
  align-items: center;
}
.add-comment-area .avatar img {
  width: 45px;
  height: 45px;
  margin-right: 6px;
  border: 3px solid #f1f3f9;
}
.add-comment-area .input-default {
  width: 100%;
  position: relative;
}
.add-comment-area .input-default::placeholder {
  color: red;
}
.add-comment-area .input-default button {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 5px;
  padding: 0px 9px;
}
.add-comment-area .input-default .block-group .add-assets {
  display: flex;
  align-items: center;
}
.add-comment-area .input-default .block-group .add-assets i {
  font-size: 20px;
  color: #1c285a;
  margin-right: 10px;
}
.add-comment-area .input-default .block-group .add-assets p {
  color: #1c285a;
  margin: 0px;
}

/* Top Bar
--------------------*/
#topBar .card-default {
  padding: 9px 40px 9px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  z-index: 2;
  border-radius: 0;
  box-shadow: 0px 1px 8px 1px RGB(28 40 90/5%);
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(1) {
  display: flex;
  justify-content: start;
  align-items: center;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(1) .toggle-sidenav {
  margin-right: 15px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(1) .toggle-sidenav button:focus {
  outline: 0px;
  box-shadow: none;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(1) .toggle-sidenav button img {
  width: 24px;
  vertical-align: middle;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(1) .logo-icon img {
  width: 38px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(1) h5 {
  color: #071036;
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 0;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 10px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown i {
  color: #1c285a;
  font-size: 20px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown .dropdown-item {
  display: flex;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown .dropdown-item i {
  color: #fcb038;
  font-size: 12px;
  margin-right: 10px;
  margin-top: 6px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications img {
  margin: 0px;
  margin-right: 15px;
  width: 26px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .notify-dot {
  padding: 0px;
  background-color: red;
  color: #ffffff;
  border-radius: 50%;
  line-height: 1.1;
  height: 19px;
  width: 19px;
  text-align: center;
  position: absolute;
  top: -7px;
  right: 23px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .notify-dot span {
  margin: 0px;
  padding: 0px;
  font-size: 11px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu {
  top: 38px !important;
  left: -312px !important;
  padding: 0px;
  width: auto;
  min-width: 400px;
  transform: unset !important;
  right: 0;
  background: #e6ecff;
  border-color: #d0d8f7;
  border-radius: 15px;
  -webkit-box-shadow: 0px 8px 16px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 12px 0px RGB(0 0 0/9%);
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu .heading {
  position: relative;
  padding: 15px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e8e8e8;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu .heading h6 {
  font-size: 14px;
  font-weight: 500;
  color: #8d95a2;
  margin: 0px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu .heading .arrow-top-light {
  position: absolute;
  top: -20px;
  left: 43px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu a {
  padding: 5px 18px;
  font-size: 13px;
  border-bottom: 1px solid #ededed;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu a p {
  font-size: 12px;
  margin: 0px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu a p a {
  color: black;
  padding: 0px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu a .important {
  font-weight: 400;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu a .important img {
  width: 16px;
  vertical-align: text-bottom;
  margin-right: 6px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu a .alert-dot {
  background-color: red;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 50%;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu a .brand-dot {
  background-color: green;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 50%;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu .dropdown-divider {
  margin: 0px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu button {
  font-size: 14px;
  font-weight: 500;
  color: #304189;
  padding-top: 0px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications .dropdown-menu .scroll-area {
  margin-bottom: 20px;
  padding: 20px 10px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 10px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications img {
  margin: 0px;
  margin-right: 15px;
  width: 26px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown button {
  display: flex;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown button i {
  color: #1c285a;
  font-size: 20px;
  margin-right: 10px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown button p {
  color: #000000;
  margin-right: 25px;
  margin-bottom: 0;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown .dropdown-item {
  display: flex;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown .dropdown-item i {
  color: #fcb038;
  font-size: 12px;
  margin-right: 10px;
  margin-top: 6px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .bell-icon {
  display: inline-block;
  position: relative;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .bell-icon .notify-dot {
  padding: 0px;
  background: #F6D3D3;
  border: 0.5px solid #FFFFFF;
  color: #ffffff;
  border-radius: 50%;
  line-height: 0.8;
  height: 16px;
  width: 16px;
  text-align: center;
  position: absolute;
  top: -4px;
  right: 3px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .bell-icon .notify-dot span {
  position: center;
  font-size: 11px;
  font-weight: 600;
  color: #EB3535;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu {
  top: 25px !important;
  left: -312px !important;
  padding: 0px;
  width: auto;
  min-width: 400px;
  transform: unset !important;
  right: 0;
  background: #FFFFFF;
  border: 0;
  border-radius: 10px;
  -webkit-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.2);
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .notification-badge {
  padding: 6px 22px 6px 6px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #222222;
  justify-content: space-between;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .notification-badge .btns {
  display: flex;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .notification-badge .btns button {
  margin-left: 2px;
  background-color: #E7ECF8;
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding-right: 6px;
  color: #000000;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .notification-badge .btns button span {
  border-radius: 50%;
  color: #E34343;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .notification-badge .btns .unread-messages {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 400;
  color: #8C8C8C;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .notification-badge .btns .unread-messages p {
  margin-bottom: 0px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .notification-badge .mark-allread {
  display: flex;
  align-items: center;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .notification-badge .mark-allread p {
  font-size: 10px;
  margin-bottom: 0;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .notification-badge .mark-allread .my-svg {
  width: 10px;
  height: 6px;
  margin-right: 4px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu h6 {
  text-align: left;
  padding-left: 30px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px 10px 0px 0px;
  background: #FFFFFF;
  margin-bottom: 0;
  border-bottom: 2px solid #000000;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .heading {
  position: relative;
  padding: 15px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e8e8e8;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .heading h6 {
  font-size: 14px;
  font-weight: 500;
  color: #8d95a2;
  margin: 0px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .heading .arrow-top-light {
  position: absolute;
  top: -20px;
  left: 43px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .dropdown-divider {
  margin: 0px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .all-button-area {
  padding: 11px 20px 13px 20px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .all-button-area .all-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ededed;
  padding: 8px 10px;
  border-radius: 10px;
  border: 0;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .all-button-area .all-button:hover {
  background: gainsboro;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area {
  max-height: 500px;
  border-radius: 0;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .notification-block {
  background-color: #EFEFEF;
  padding: 18px;
  padding-top: 12px;
  padding-bottom: 0px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .notification-block .notify-content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cacaca;
  padding-bottom: 12px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .notification-block .notify-content .circle-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #E3E3E3;
  display: flex;
  align-items: center;
  justify-content: center;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .notification-block .notify-content .circle-icon svg {
  font-size: 14px;
  color: #002fff;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .notification-block .notify-content .desc {
  margin-left: 10px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .notification-block .notify-content .desc h4 {
  margin: 0;
  font-size: 14px;
  margin-bottom: 4px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .notification-block .notify-content .desc p {
  margin: 0;
  font-size: 10px;
  color: #8C8C8C;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .notification-block .notify-content .notification-time p {
  font-size: 10px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .read-notification-block {
  background-color: #FFFFFF;
  padding: 18px;
  padding-top: 12px;
  padding-bottom: 0px;
  transition: background-color 0.3s ease;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .read-notification-block:hover {
  background-color: #EFEFEF;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .read-notification-block .notify-content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cacaca;
  padding-bottom: 12px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .read-notification-block .notify-content .circle-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #E3E3E3;
  display: flex;
  align-items: center;
  justify-content: center;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .read-notification-block .notify-content .circle-icon svg {
  font-size: 14px;
  color: #475467;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .read-notification-block .notify-content .desc {
  margin-left: 10px;
  width: fit-content;
  opacity: 0.5;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .read-notification-block .notify-content .desc h4 {
  margin: 0;
  font-size: 14px;
  margin-bottom: 4px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .notifications .dropdown-menu .scroll-area .read-notification-block .notify-content .desc p {
  margin: 0;
  font-size: 10px;
  color: #8C8C8C;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .profile-dropdown {
  display: flex;
  align-items: center;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .profile-dropdown .avatar {
  margin-right: 3px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .profile-dropdown .avatar img {
  width: 35px;
  height: 35px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .profile-dropdown .dropdown a {
  color: #000000;
  font-size: 13px;
  font-weight: 600;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .profile-dropdown .dropdown .dropdown-menu {
  top: 12px !important;
  right: -1px !important;
  left: auto !important;
  padding: 1.5rem 0;
  background-color: #ffffff;
  border: 1px solid RGB(207 211 224/38%);
  box-shadow: 0px 4px 15px RGB(0 0 0/8%);
  border-radius: 6px;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .profile-dropdown .dropdown .dropdown-menu:before {
  content: "";
  position: absolute;
  right: 61px;
  left: 300px;
  page-break-after: always;
  top: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #eefbfd transparent;
  z-index: 9999;
}
#topBar .card-default .bar-area .block-group .inline-block:nth-child(3) .profile-dropdown .dropdown .dropdown-menu .dropdown-item {
  padding: 0.3rem 1.5rem;
  font-size: 14px;
}
@media only screen and (max-width: 1600px) {
  #topBar .card-default .bar-area .block-group .inline-block:nth-child(1) h5 {
    font-size: 18px;
  }
  #topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .notifications img {
    width: 15px;
  }
  #topBar .card-default .bar-area .block-group .inline-block:nth-child(2) .profile-dropdown .avatar img {
    width: 32px;
    height: 32px;
  }
}

/* Content Area
--------------------*/
#contentArea {
  display: flex;
}
#contentArea .block-center {
  width: calc(65% - 2vh);
  margin: 0px 3% 0px 1%;
}
#contentArea .block-center .add-post-area .top-section {
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 10px;
  align-items: center;
}
#contentArea .block-center .add-post-area .top-section .form-control {
  height: 44px;
  background: #F0F2F5;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 59px;
  overflow: hidden;
}
#contentArea .block-center .add-post-area .top-section .form-control:hover {
  overflow: overlay;
}
#contentArea .block-center .add-post-area .input-default {
  width: 100%;
}
#contentArea .block-center .add-post-area .input-default::placeholder {
  color: red;
}
#contentArea .block-center .add-post-area .input-default .block-group .block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#contentArea .block-center .add-post-area .input-default .block-group .block .add-assets {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
#contentArea .block-center .add-post-area .input-default .block-group .block .add-assets .box-icon {
  width: 34.94px;
  height: 34.87px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  cursor: pointer;
  margin-right: 16px;
}
#contentArea .block-center .add-post-area .input-default .block-group .block .add-assets i {
  font-size: 20px;
  color: #1c285a;
  margin-right: 10px;
}
#contentArea .block-center .add-post-area .input-default .block-group .block .add-assets p {
  font-weight: 400;
  font-size: 16px;
  color: #1c285a;
  margin: 0px;
}
#contentArea .block-center .add-post-area .input-default .block-group .block .submit-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#contentArea .block-center .add-post-area .input-default .block-group .block .submit-area p {
  margin-bottom: 0px;
  margin-right: 10px;
  color: #ced3e3;
  font-size: 14px;
}
#contentArea .block-center .comment-area {
  display: flex;
  margin-top: 50px;
}
#contentArea .block-center .comment-area .avatar img {
  margin-right: 15px;
}
#contentArea .block-center .comment-area .card-alt {
  width: 100%;
}
#contentArea .block-center .comment-area .card-alt h6 {
  font-weight: 600;
  margin-bottom: 15px;
}
#contentArea .block-center .comment-area .card-alt p {
  font-size: 14px;
}
#contentArea .block-center .add-comment-area {
  display: flex;
}
#contentArea .block-center .add-comment-area .input-default {
  width: 100%;
  display: flex;
}
#contentArea .block-center .add-comment-area .input-default::placeholder {
  color: red;
}
#contentArea .block-center .add-comment-area .input-default button {
  font-weight: 500;
  font-size: 12px;
}
#contentArea .block-center .add-comment-area .input-default .block-group .add-assets {
  display: flex;
  align-items: center;
}
#contentArea .block-center .add-comment-area .input-default .block-group .add-assets i {
  font-size: 20px;
  color: #1c285a;
  margin-right: 10px;
}
#contentArea .block-center .add-comment-area .input-default .block-group .add-assets p {
  color: #1c285a;
  margin: 0px;
}
#contentArea .block-center .view-post-area .post-content h5 {
  font-weight: 600;
  margin-bottom: 15px;
}
#contentArea .block-center .view-post-area .post-content p {
  margin-bottom: 5px;
}
#contentArea .block-center .view-post-area .post-content .thumb-lg {
  width: 100%;
}
#contentArea .block-center .view-post-area .post-content .thumb-lg img {
  width: 100%;
  height: auto;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 5px;
}
#contentArea .block-center .view-post-area .post-content .post-by {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
#contentArea .block-center .view-post-area .post-content .post-by .avatar {
  margin-right: 10px;
}
#contentArea .block-center .view-post-area .post-content .post-by .post-by-info h6 {
  font-weight: 600;
  margin-bottom: 5px;
}
#contentArea .block-center .view-post-area .post-content .post-by .post-by-info p {
  font-size: 12px;
  color: #acb3c9;
  margin-bottom: 0px;
}
#contentArea .block-center .view-post-area .post-status {
  font-size: 12px;
  font-weight: 600;
  color: #323232;
  margin-top: 12px;
  margin-bottom: 10px;
}
#contentArea .block-center .view-post-area .like-comment-share {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 0px;
  padding: 5px 0px;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}
#contentArea .block-center .view-post-area .like-comment-share .box {
  display: flex;
  align-items: center;
  margin-right: 0px;
  background: #FFFFFF;
  padding: 8px 10px;
  box-shadow: 0px 3px 3px 0px RGB(146 153 177/5%);
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s;
}
#contentArea .block-center .view-post-area .like-comment-share .box:hover {
  background: #e9eff7;
}
#contentArea .block-center .view-post-area .like-comment-share .box img {
  width: 30px;
  margin-right: 10px;
}
#contentArea .block-center .view-post-area .like-comment-share .box i {
  font-size: 20px;
  margin-right: 10px;
  color: #1c285a;
}
#contentArea .block-center .view-post-area .like-comment-share .box p {
  font-weight: 400;
  font-size: 16px;
  color: #949aa9;
  margin: 0px;
}
#contentArea .block-center .view-post-area .like-comment-share .box p b {
  color: black;
}
#contentArea .block-center .view-post-area .like-comment-share .comment-count {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  padding: 5px 10px;
  justify-content: center;
  box-shadow: 0px 3px 3px 0px RGB(146 153 177/5%);
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s;
}
#contentArea .block-center .view-post-area .like-comment-share .comment-count p {
  font-weight: 400;
  font-size: 16px;
  color: #475467;
}
#contentArea .block-center .view-post-area .like-comment-share .comment-count p b {
  font-weight: 400;
  font-size: 16px;
  color: #475467;
}
#contentArea .block-center .view-post-area .like-comment-share .comment-count:hover {
  background: #e9eff7;
}
#contentArea .block-center .view-post-area .like-comment-share .comment-count img {
  width: 28px;
}
#contentArea .block-center .view-post-area .comment-area {
  display: flex;
  margin-top: 0px;
  background: transparent;
  padding: 8px 10px;
  padding-bottom: 0px;
  border-radius: 15px;
}
#contentArea .block-center .view-post-area .comment-area:first-child {
  padding-top: 10px;
}
#contentArea .block-center .view-post-area .comment-area .avatar {
  z-index: 1;
}
#contentArea .block-center .view-post-area .comment-area .avatar img {
  margin-right: -8px;
  padding: 3px;
  background: #FFFFFF;
}
#contentArea .block-center .view-post-area .comment-area .card-alt {
  width: auto;
  min-width: 100px;
  margin-bottom: 0px;
  padding: 7px 16px;
  background-color: #f0f2f5;
  border-radius: 20px;
}
#contentArea .block-center .view-post-area .comment-area .card-alt h6 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}
#contentArea .block-center .view-post-area .comment-area .card-alt p {
  font-size: 12px;
  color: #000000;
  margin-bottom: 0px;
}
#contentArea .block-right {
  width: calc(28% - 8px);
  position: fixed;
  right: 30px;
  padding-bottom: 120px;
  height: 100%;
  overflow: hidden;
}
#contentArea .block-right:hover {
  overflow: overlay;
}
#contentArea .block-right .card-default-transparent .sidebar-btn-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  padding: 0 15px;
}
#contentArea .block-right .card-default-transparent .sidebar-btn-group button {
  font-weight: 400;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 12px;
  height: 32px;
  line-height: 16px;
}
#contentArea .block-right .card-default-transparent .sidebar-btn-group button span {
  margin-right: 6px;
}
#contentArea .block-right .card-default-transparent #calender {
  padding: 0 0px;
}
#contentArea .block-right .card-default-transparent #calender .header {
  background-color: #fff3f4;
  padding: 15px 20px;
  border-radius: 6px;
  margin-bottom: 15px;
}
#contentArea .block-right .card-default-transparent #calender .header h6 {
  margin: 0px;
}
#contentArea .block-right .card-default-transparent #calender .block-group {
  justify-content: start;
}
#contentArea .block-right .card-default-transparent #calender .block-group .block-25 {
  width: calc(25% - 5px);
  height: 5vw;
  margin-bottom: 0px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}
#contentArea .block-right .card-default-transparent #calender .block-group .block-25:hover {
  background-color: #f7f9ff;
  transition: 0.2s;
}
#contentArea .block-right .card-default-transparent #calender .block-group .block-25 .reminder {
  background-color: transparent;
  padding: 3px 0px;
  border-radius: 6px;
  margin-left: 0px;
}
#contentArea .block-right .card-default-transparent #calender .block-group .block-25 .reminder p {
  font-size: 0.6vw;
  font-weight: 500;
  margin: 0px;
}
#contentArea .block-right .card-default-transparent #calender .block-group .block-25 .reminder .event {
  background: #FFF3E0;
  color: #E68F06 !important;
  border-radius: 25px;
  padding: 0.2vh 1vh;
  text-align: center;
  width: fit-content;
  margin-bottom: 0.6vh;
}
#contentArea .block-right .card-default-transparent #calender .block-group .block-25 .reminder .assignment {
  background: #E7EDFF;
  color: #4575EE !important;
  border-radius: 25px;
  padding: 0.5vh 0.6vh;
  text-align: center;
  width: fit-content;
}
#contentArea .block-right .card-default-transparent #calender .block-group .block-25 .date {
  margin-left: 0px;
}
#contentArea .block-right .card-default-transparent #calender .block-group .block-25 .date h4 {
  margin: 0px 0px 5px 0px;
  font-weight: 600;
  font-size: 0.6vw;
  color: #47474F;
}
@media (max-width: 1642px) {
  #contentArea .block-right .card-default-transparent #calender .block-group .block-25 .reminder p {
    font-size: 0.5vw;
  }
  #contentArea .block-right .card-default-transparent #calender .block-group .block-25 .reminder .assignment {
    font-size: 0.4vw;
  }
}
#contentArea .block-right .card-default-transparent .tabs .tab-content {
  max-height: 39vh;
  overflow: hidden;
  box-shadow: inset 0px -7px 6px -4px RGB(0 0 0/8%);
  padding: 12px;
  border-radius: 15px;
}
#contentArea .block-right .card-default-transparent .tabs .tab-content:hover {
  overflow: overlay;
  scroll-behavior: smooth;
}
#contentArea .block-right .card-default-transparent hr {
  margin: 10px 16px;
  background: #f7f9ff;
}
#contentArea .block-right hr {
  margin: 20px 16px;
  background: #f7f9ff;
}
#contentArea #newAssigments .task-list textarea {
  background-color: #f1f3f9;
}
#contentArea .card-default .submission-search .submission h2 {
  font-size: 20px;
}
#contentArea .card-default .submission-search .submission h3 {
  font-size: 16px;
}
#contentArea #assigmentTaskSubmitView .card-default .qns-heading {
  margin-bottom: 30px;
  padding: 20px 25px;
  border-left: 8px solid #11cdef;
}
#contentArea #assigmentTaskSubmitView .card-default .left-icon-heading-sm {
  margin-bottom: 15px;
  color: #004c58;
}
#contentArea #assigmentTaskSubmitView .card-default .ans-card .ans-desc {
  background-color: #ffffff;
  display: flex;
  padding: 20px 30px;
  border-radius: 8px;
  margin-bottom: 32px;
  height: 299px;
  overflow: auto;
}
#contentArea #assigmentTaskSubmitView .card-default .ans-card .ans-desc p {
  font-size: 14px;
  line-height: 1.9;
  margin-bottom: 0px;
}
#contentArea #assigmentTaskSubmitView .card-default .ans-card .comment-area {
  margin-top: 18px;
}
#contentArea #assigmentTaskSubmitView .card-default .ans-card .comment-area .card-alt {
  background-color: #d9eef3;
}
#contentArea #assigmentTaskSubmitView .card-default .ans-card .add-comment-area {
  margin-top: 20px;
}
@media only screen and (max-width: 1674px) {
  #contentArea .block-center {
    width: calc(65% - 3vh);
    margin: 0px 3% 0px 1%;
  }
  #contentArea .block-right {
    width: calc(27% - 0px);
  }
}

#reg_layout {
  display: flex;
}
#reg_layout #reg_content {
  background-color: #e3e4e9;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  flex-grow: 1;
  min-height: calc(100vh - 56px);
}
#reg_layout #reg_content #registrations {
  margin-top: 5%;
  background-image: url("school_bg.png");
}
#reg_layout #reg_content #registrations .container h4 {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 70px;
}
#reg_layout #reg_content #registrations .container .card-default {
  padding: 45px 45px;
  padding-top: 50px;
  margin: 0 auto;
  width: 73%;
}
#reg_layout #reg_content #registrations .container .card-default .input-reg .block-group {
  margin-bottom: 20px;
}
#reg_layout #reg_content #registrations .container .card-default .input-reg .block-group .block-50 {
  width: calc(50% - 25px);
}
#reg_layout #reg_content #registrations .container .card-default .input-reg .block-group .block {
  width: calc(100% - 0px);
}
#reg_layout #reg_content #registrations .container .card-default .input-reg img {
  font-size: 55px;
  color: #141944;
  margin-top: 20px;
}
#reg_layout #reg_content #registrations .container .card-default .input-reg h5 {
  font-size: 24px;
  font-weight: 600;
}
#reg_layout #reg_content #registrations .container .card-default .input-reg button {
  margin-top: 10px;
  margin-bottom: 5px;
}
#reg_layout #reg_content #registrations .container .card-default .input-reg button p {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
}
#reg_layout #reg_content #registrations .container .card-default .input-reg .forget-pass-btn:hover {
  color: #FFFFFF;
}
#reg_layout #reg_content #registrations .container .card-default .input-reg .forget-pass-btn a {
  width: 100%;
  color: #1c285a;
}
#reg_layout #reg_content #registrations .container .card-default .input-reg .forget-pass-btn a:hover {
  color: #FFFFFF;
}
#reg_layout #reg_content #registrations .container .footer-instruction {
  margin-top: 40px;
  text-align: center;
}
#reg_layout #reg_content #registrations .container .footer-instruction p {
  font-size: 16px;
  color: #697195;
  line-height: 2.5;
}
@media only screen and (max-width: 992px) {
  #reg_layout #reg_content #registrations .container .card-default {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  #reg_layout #reg_content #registrations .container h4 {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 35px;
  }
  #reg_layout #reg_content #registrations .container .card-default {
    padding: 20px 25px;
    padding-top: 30px;
  }
  #reg_layout #reg_content #registrations .container .card-default .input-reg label {
    margin-bottom: 10px;
  }
  #reg_layout #reg_content #registrations .container .card-default .input-reg .block-group .block-50 {
    width: 100%;
  }
  #reg_layout #reg_content #registrations .container .card-default .input-reg button {
    margin-top: 10px;
  }
}
#reg_layout #reg_content #registrations #login .container .brand {
  text-align: center;
  margin-bottom: 36px;
}
#reg_layout #reg_content #registrations #login .container .brand img {
  width: 200px;
}
#reg_layout #reg_content #registrations #login .container h4 {
  padding-top: 20px;
}
#reg_layout #reg_content #registrations #login .container .card-default {
  width: 42%;
  padding: 32px 38px 30px 38px;
  border-radius: 20px;
  box-shadow: 0px 2px 2px 1px RGB(209 212 221/30%);
}
#reg_layout #reg_content #registrations #login .container .card-default img {
  width: 210px;
  margin-bottom: 35px;
}
#reg_layout #reg_content #registrations #login .container .card-default h5 {
  margin-bottom: 35px;
}
#reg_layout #reg_content #registrations #login .container .footer-instruction .btn-group {
  display: flex;
  justify-content: center;
  width: 55%;
  margin: 0 auto;
  margin-top: 20px;
}
#reg_layout #reg_content #registrations #login .container .footer-instruction .btn-group span {
  font-weight: 300;
}
#reg_layout #reg_content #registrations #login .container .footer-instruction .btn-group button {
  min-width: 125px;
  border: 0px;
  font-size: 14px;
  outline: 0;
}
#reg_layout #reg_content #registrations #login .container .footer-instruction .btn-group button:hover {
  background-color: transparent;
}
#reg_layout #reg_content #registrations #login .container .footer-instruction .btn-group button a {
  text-decoration: none;
}
#reg_layout #reg_content #registrations #login .container .footer-instruction .btn-group button a:hover {
  color: black;
}
@media only screen and (max-width: 992px) {
  #reg_layout #reg_content #registrations #login .container .card-default {
    width: 80%;
  }
  #reg_layout #reg_content #registrations #login .container .card-default .block-group .block-50 {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  #reg_layout #reg_content #registrations #login .container .brand {
    margin-bottom: 20px;
  }
  #reg_layout #reg_content #registrations #login .container .card-default {
    width: 100%;
    padding: 30px 20px 20px 20px;
  }
  #reg_layout #reg_content #registrations #login .container .card-default img {
    width: 200px;
  }
  #reg_layout #reg_content #registrations #login .container .card-default .block-group .block-50 {
    width: 100%;
  }
  #reg_layout #reg_content #registrations #login .container .footer-instruction .btn-group {
    width: 100%;
  }
  #reg_layout #reg_content #registrations #login .container .footer-instruction .btn-group button {
    min-width: 60px;
  }
}

#regCoolingPeriod .content-wrap {
  padding: 0px 20px;
  text-align: center;
}
#regCoolingPeriod .content-wrap .illustration {
  margin-bottom: 55px;
}
#regCoolingPeriod .content-wrap .illustration img {
  width: 70%;
}
#regCoolingPeriod .content-wrap h5 {
  font-weight: 600;
  margin-bottom: 20px;
}
#regCoolingPeriod .content-wrap p {
  line-height: 1.7;
}
#regCoolingPeriod .content-wrap a {
  color: orangered;
}

.all-notification-section {
  height: 70vh;
}

/* Tabs
--------------------*/
.tab-default {
  position: relative;
}
.tab-default .tabs .nav {
  border-bottom: 2px solid gray;
  margin-top: 12px;
  padding-bottom: 0px;
  display: grid;
  grid-template-columns: 17% 20% 71%;
}
.tab-default .tabs .nav first-child {
  align-self: end;
}
.tab-default .tabs .nav .nav-item {
  padding-right: 50px;
  align-items: baseline;
}
.tab-default .tabs .nav .nav-item a {
  font-size: 16px;
  font-weight: 500;
  color: #888888;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 8px;
}
.tab-default .tabs .nav .nav-item .notification-button {
  border-bottom: 0;
  color: black;
  background-color: #ffffff;
  font-size: 16px;
}
.tab-default .tabs .nav .nav-item .notify-dot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  width: 26px;
  height: 19px;
  background: #E8E8E8;
  border-radius: 29px;
}
.tab-default .tabs .nav .nav-item .notify-dot span {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #878787;
}
.tab-default .tabs .nav .search-sort {
  padding-left: 22vh;
}
.tab-default .tabs .nav .search-sort .sort-by-header p {
  padding-right: 7px;
  margin-bottom: 0px;
}
.tab-default .tabs .nav-pills .nav-link.active, .tab-default .tabs .nav-pills .show > .nav-link {
  color: #141944;
  background-color: #ffffff;
  border-radius: 0px;
  padding: 10px 0px;
  font-size: 16px;
  border-bottom: 3px solid #FAA31A;
}
.tab-default .tabs .nav-pills .nav-link.active .notify-dot {
  background-color: #FFF3E0;
}
.tab-default .tabs .nav-pills .nav-link.active .notify-dot span {
  color: #ff9c19;
}
.tab-default .tabs .nav-pills .nav-link .notify-dot span {
  color: #878787;
}
.tab-default .tabs .tab-content > .active {
  display: block;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.tab-default .tabs .tab-content {
  -ms-overflow-y: auto;
}
.tab-default .tabs .tab-content .tab-pane .list-group {
  margin-bottom: 10px;
  margin-right: 10px;
}
.tab-default .tabs .tab-content .tab-pane .list-group .list-group-item {
  padding: 13px 0px;
  background-color: #ffffff;
  border: 0px solid #d2d8f1;
  border-radius: 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.tab-default .tabs .tab-content .tab-pane .list-group .list-group-item h6 {
  font-size: 12px;
  color: #1c285a;
  margin-bottom: 0px;
  line-height: 1.3;
}
.tab-default .tabs .tab-content .tab-pane .list-group .list-group-item p {
  font-size: 14px;
  color: #23d0f0;
}
.tab-default .tabs .tab-content .tab-pane .list-group .list-group-item p i {
  font-size: 12px;
  color: #1c285a;
  margin-right: 10px;
}
.tab-default .tabs .tab-content .tab-pane .list-group .list-group-item p span {
  font-weight: 500;
  font-size: 14px;
  color: #475467;
}
.tab-default .tabs .tab-content .tab-pane .list-group .list-group-item p span strong {
  color: black;
}
.tab-default .tabs .tab-content .tab-pane .list-group .list-group-item .date {
  color: #166b7b !important;
  font-weight: 500;
}
.tab-default .tabs .tab-content .tab-pane .list-group .list-group-item a {
  font-weight: 400;
  font-size: 14px;
  color: #475467;
}
.tab-default .add-more {
  position: absolute;
  top: 0px;
  right: 0px;
}

.tab-orange .tabs .nav {
  border-bottom: 0px solid #e0e0e0;
  margin-top: 20px;
  margin-bottom: 12px;
  box-shadow: RGB(255 255 255/10%) 0px 2px 0px 0px inset, RGB(50 50 93/25%) 0px 10px 31px -20px, RGB(0 0 0/30%) 0px 24px 2px -30px;
  padding: 0 14px;
}
.tab-orange .tabs .nav .nav-item a {
  font-size: 14px;
  font-weight: 400;
  color: #878787;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 25px;
  padding-bottom: 8px;
}
.tab-orange .tabs .nav-pills .nav-link.active, .tab-orange .tabs .nav-pills .show > .nav-link {
  color: white;
  background-color: #E58F06;
  border-radius: 16px;
  padding: 8px 20px;
}
.tab-orange .tabs .tab-content {
  -ms-overflow-y: auto;
}
.tab-orange .tabs .tab-content .tab-pane .list-group {
  margin-bottom: 5px;
}
.tab-orange .tabs .tab-content .tab-pane .list-group .list-group-item {
  padding: 10px 12px;
  background-color: #fdfdfd;
  border: 1px solid #d2d8f1;
}
.tab-orange .tabs .tab-content .tab-pane .list-group .list-group-item h6 {
  margin-bottom: 0px;
  line-height: 1.3;
  font-weight: 500;
  font-size: 14px;
  color: #4575EE;
}
.tab-orange .tabs .tab-content .tab-pane .list-group .list-group-item h5 {
  margin-bottom: 0px;
  line-height: 1.3;
  font-weight: 600;
  font-size: 16px;
  color: #141944;
}
.tab-orange .tabs .tab-content .tab-pane .list-group .list-group-item p {
  font-weight: 500;
  font-size: 12px;
  color: #878787;
}
.tab-orange .tabs .tab-content .tab-pane .list-group .list-group-item .date {
  color: #166b7b !important;
  font-width: 500;
}
@media only screen and (max-width: 1280px) {
  .tab-orange .tabs .nav .nav-item a {
    font-size: 12px;
  }
}

.tab-blue {
  position: relative;
}
.tab-blue .tabs .nav {
  border-bottom: 0px solid #e0e0e0;
  margin-bottom: 20px;
  box-shadow: RGB(255 255 255/10%) 0px 2px 0px 0px inset, RGB(50 50 93/25%) 0px 10px 31px -20px, RGB(0 0 0/30%) 0px 24px 2px -30px;
  padding: 0 14px;
}
.tab-blue .tabs .nav .nav-item a {
  font-size: 14px;
  font-weight: 400;
  color: #878787;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 25px;
  padding-bottom: 8px;
}
.tab-blue .tabs .nav .nav-item .notification-button {
  border-bottom: 0;
  color: black;
  background-color: #ffffff;
  font-size: 16px;
}
.tab-blue .tabs .nav .nav-item .notify-dot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  width: 26px;
  height: 19px;
  background: #E8E8E8;
  border-radius: 29px;
}
.tab-blue .tabs .nav .nav-item .notify-dot span {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #878787;
}
.tab-blue .tabs .nav .search-sort {
  padding-left: 22vh;
}
.tab-blue .tabs .nav .search-sort .sort-by-header p {
  padding-right: 7px;
}
.tab-blue .tabs .nav-pills .nav-link.active, .tab-blue .tabs .nav-pills .show > .nav-link {
  color: white;
  background-color: #11CDEF;
  border-radius: 16px;
  padding: 8px 20px;
}
.tab-blue .tabs .tab-content {
  -ms-overflow-y: auto;
}
.tab-blue .tabs .tab-content .tab-pane .list-group {
  margin-bottom: 5px;
}
.tab-blue .tabs .tab-content .tab-pane .list-group .list-group-item {
  padding: 10px 12px;
  background: #ffffff;
  border: 1px solid #EBEBEB;
}
.tab-blue .tabs .tab-content .tab-pane .list-group .list-group-item h6 {
  margin-bottom: 0px;
  line-height: 1.3;
  font-weight: 500;
  font-size: 14px;
  color: #4575EE;
}
.tab-blue .tabs .tab-content .tab-pane .list-group .list-group-item h5 {
  margin-bottom: 0px;
  line-height: 1.3;
  font-weight: 600;
  font-size: 16px;
  color: #141944;
}
.tab-blue .tabs .tab-content .tab-pane .list-group .list-group-item p {
  font-weight: 500;
  font-size: 12px;
  color: #878787;
}
.tab-blue .tabs .tab-content .tab-pane .list-group .list-group-item .date {
  color: #166b7b !important;
  font-width: 500;
}
.tab-blue .add-more {
  position: absolute;
  top: 0px;
  right: 0px;
}

.event-detail {
  height: 90vh;
  overflow: overlay;
  padding: 0px;
}
.event-detail .eventview-header {
  padding: 18px 19px 0px 40px;
  border-bottom: 0.7px solid #C5C5C5;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);
}
.event-detail .eventview-header .block-group {
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 40px;
}
.event-detail .eventview-header .block-group .block-25 {
  width: calc(26% - 0px);
}
.event-detail .eventview-header .block-group .block-25 .event-title {
  font-weight: 400;
  font-size: 16px;
  color: #475467;
}
.event-detail .eventview-header .block-group .block-25 .event-desc {
  font-weight: 600;
  font-size: 17px;
  color: #141944;
}
.event-detail .eventview-header .block-group .block-40 {
  width: calc(40% - 110px);
}
.event-detail .eventview-header .block-group .block-40 .event-title {
  font-weight: 400;
  font-size: 16px;
  color: #475467;
}
.event-detail .eventview-header .block-group .block-40 .event-desc {
  font-weight: 600;
  font-size: 17px;
  color: #141944;
}
.event-detail .comment-section {
  position: relative;
  height: 266px;
  background: #FFFFFF;
  border: 1.5px solid #E3E3E3;
  border-radius: 12px;
  margin: 20px 38px;
  padding: 15px 10px 10px 10px;
}
.event-detail .comment-section .add-comment-area {
  bottom: 0;
  position: absolute;
  width: 98%;
  margin-bottom: 15px;
}
.event-detail .comment-section .add-comment-area .input-default input {
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 30px;
}
.event-detail .comment-section .comment-area-event {
  display: flex;
  justify-content: space-between;
  width: 99%;
}
.event-detail .comment-section .comment-area-event .avatar {
  margin-right: 10px;
}
.event-detail .comment-section .comment-area-event .card-alt-event .card-p-event {
  margin: 0px;
}
.event-detail .comment-section .comment-area-event .card-alt-event .card-p-date {
  margin-top: 5px;
  font-weight: 300;
  font-size: 12px;
  color: #878787;
}
.event-detail ul {
  list-style: none;
}
.event-detail .nav-tabs .nav-item.show .nav-link, .event-detail .nav-tabs .nav-link {
  border-radius: 25px;
  margin-right: 20px;
  background: #3642551f;
  color: #81849b;
}
.event-detail .nav-tabs .nav-item.show .nav-link, .event-detail .nav-tabs .nav-link.active {
  background: #1c285a;
  color: #FFFFFF;
}

.invitation-status {
  margin: 32px 0px 28px 38px;
}
.invitation-status p {
  font-weight: 500;
  font-size: 18px;
  color: #141944;
}

.no-members-available svg {
  transform: scale(1);
}
.no-members-available p {
  font-weight: 400;
  font-size: 16px;
  color: #475467;
  display: flex;
  justify-content: center;
}

.event-news {
  padding: 0px;
}

/* Global Media
--------------------*/
.display-lg {
  display: none;
}

/* Pages
--------------------*/
/* Grade Book */
#gradeBook .table-col-scroll-custom {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
#gradeBook .table-col-scroll-custom ::-webkit-scrollbar {
  width: 3px !important;
  height: 5px;
  cursor: pointer;
}
#gradeBook .table-col-scroll-custom ::-webkit-scrollbar-track {
  background: #10193e !important;
  cursor: pointer;
}
#gradeBook .table-col-scroll-custom ::-webkit-scrollbar-thumb {
  background: #5b6685;
  border-radius: 50px;
  cursor: pointer;
}
#gradeBook .table-col-scroll-custom ::-webkit-scrollbar-thumb:hover {
  background: #7b8ab5;
  cursor: pointer !important;
}
#gradeBook .table-col-scroll-custom table td {
  max-width: 150px !important;
  overflow-x: auto;
}
#gradeBook .table-col-scroll-custom table td .makeStyles-root-1 {
  width: 500px;
}

/* All Notification */
#allNotificationPage .card-default {
  padding-left: 0;
  padding-right: 0;
}
#allNotificationPage .card-default .card-header {
  padding: 0 46px;
}
#allNotificationPage .card-default .tab-default .tabs .tab-content .tab-pane .list-group {
  margin-bottom: 4px;
  margin-right: 0px;
}
#allNotificationPage .card-default .tab-default .tabs .tab-content .tab-pane .list-group:nth-child(odd) {
  background-color: #F7F7F7;
}
#allNotificationPage .card-default .tab-default .tabs .tab-content .tab-pane .list-group .list-group-item {
  padding: 10px 36px;
  margin: 0;
  background-color: transparent;
}
#allNotificationPage .card-default .tab-default .nav {
  padding: 0 46px;
}

@media only screen and (max-width: 1280px) {
  body {
    font-size: 0.8rem;
  }

  h1, .h1 {
    font-size: 2.5rem;
  }

  h2, .h2 {
    font-size: 2rem;
  }

  h3, .h3 {
    font-size: 1.75rem;
  }

  h4, .h4 {
    font-size: 1.5rem;
  }

  h5, .h5 {
    font-size: 1.1rem;
  }

  h6, .h6 {
    font-size: 1rem;
  }

  .container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .custom-width-full {
    width: 100%;
    padding: 0px 46px;
  }

  .display-lg {
    display: block;
  }
}
.result-area .block-group .block-25 {
  width: calc(25% - 16px);
}

.ad-sense-position-sidenav {
  position: absolute;
  left: 0;
  bottom: 0;
}

/* Modals
--------------*/
.modal-content {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header {
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-header .circle-icon {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background: rgba(216, 20, 20, 0.1);
  display: flex;
  align-items: center;
}

.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834) url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center no-repeat;
  z-index: 1;
}

.spinner-reg {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.page-navigation {
  padding: 10px 282px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #E3E3E3;
}

.paginationBttns {
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  color: #8d8d8d;
  background-color: transparent;
}

.previousBttn {
  border: none;
  font-size: 14px;
  color: #8d8d8d;
  background-color: transparent;
}

.nextBttn {
  border: none;
  font-size: 14px;
  color: #8d8d8d;
  background-color: transparent;
}

.paginationBttns a:hover {
  color: #525252;
}

.paginationActive a {
  font-weight: 700;
  color: #212121;
}

.employee-list {
  padding: 0px;
}
.employee-list .title {
  width: 100%;
  padding: 26px 50px;
  display: flex;
  justify-content: space-between;
}
.employee-list .title p {
  font-weight: 500;
  font-size: 18px;
  color: #475467;
  padding-left: 9px;
  margin-bottom: 0px;
}
.employee-list .page-navigation {
  padding: 10px 32vw;
}

.student-list .page-navigation {
  padding: 10px 32vw;
}

