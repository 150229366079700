.btn-brand-md {
    font-size: 12px;
    font-weight: 500;
    background-color: #00c4ca;
    color: #fff;
    padding: 11px 30px;
    border: 0px;
    min-width: 140px;
    border-radius: 25px;
    height: auto;
    line-height: 1.5;
    transition: .3s;
    cursor: pointer;
}

.btn-brand-md:hover {
    background-color: #243651;
    color: #ffffff;
}

.btn-brand-trans {
    background-color: transparent;
    border: 2px solid #00c4ca;
    font-size: 1rem;
    font-weight: 700;
    width: 100%;
    padding: 24px;
    border-radius: 25px;
    color: #74c7cf;
    line-height: 0;
    transition: .2s;
    cursor: pointer;
}


.btn-green-trans {
    background-color: transparent;
    border: 2px solid #00a652;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    padding: 24px;
    border-radius: 25px;
    color: #32a654;
    line-height: 0;
    transition: .2s;
    cursor: pointer;
}

.modal-dialog {
    max-width: 475px;
    margin: 122px auto;
}

.modal.show .modal-dialog {
    transform: translate(0);
}

#loginToInvest .modal-content {
    text-align: center;
    border-radius: 15px;
    box-shadow: 3px 4px 13px #404040;
}


.btn-red-trans {
    background-color: transparent;
    border: 2px solid #d7184c;
    padding: 24px;
    border-radius: 25px;
    color: #d7184c;
    font-size: 1rem;
    font-weight: 700;
    line-height: 0;
    height: auto;
    transition: .2s;
    cursor: pointer;
}


#completeRegistration .modal-body .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}

#completeRegistration .modal-body {
    padding-bottom: 60px;
}


#completeRegistration .modal-content {
    text-align: center;
    border-radius: 4px;
    box-shadow: 3px 4px 13px #404040;
}

.modal-dialog .modal-content .modal-body {
    font-size: 15px;
    font-weight: 300;
    color: #364255;
    padding: 5px 45px;
    padding-right: 33px;
    padding-bottom: 20px;
}

.modal-dialog .modal-content button.close {
    display: block;
}

/*map*/
.map-responsive {
    overflow: hidden;
    padding-bottom: 50%;
    position: relative;
    height: 0;
}

.map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

/*end map*/


/*Property Details*/
.column_two {
    float: left;
    width: 50%;
    padding: 10px;
    height: 600px; /* Should be removed. Only for demonstration */
}

.row_tow:after {
    content: "";
    display: table;
    clear: both;
}


.column_one {
    float: left;
    width: 50%;
    padding: 10px;
    height: 600px; /* Should be removed. Only for demonstration */
}

.row_one:after {
    content: "";
    display: table;
    clear: both;
}

/*Property Details End*/

@media (max-width: 480px) {
    .modal-dialog .modal-content .modal-body {
        padding: 20px 40px !important;
    }

    .modal-body-height {
        height: 48vh !important;
    }

    .header-tnc h3 {
        text-align: left !important;
    }
}
