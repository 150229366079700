@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

* {
  margin: 0px;
  padding: 0px;
  font-family: 'Noto Sans', sans-serif;
}

//Mixin Mobile
@mixin xs {
  @media only screen and (max-width: 320px) {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

@mixin semism {
  @media only screen and (max-width: 560px) {
    @content;
  }
}

@mixin md {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}

@mixin custom-1280 {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

body {
  font-family: 'Poppins', sans-serif;
}

#registration {

  //Global Classes

  .flex-start {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
  }

  .flex-between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  //Animation Transitions
  .slideUp {
    animation: Slide_Up 0.6s ease;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .slideLeft {
    animation: Slide_Left 0.7s ease;
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(25px);
    }
    100% {
      transform: translateX(0);
    }
  }

  .slidein {
    position: absolute;
    left: 50%;
    margin-left: -266px;
    animation-duration: 0.5s;
    animation-name: slidein;
  }

  @keyframes slidein {
    from {
      margin-top: 0px;
      width: 538px;
      transition: ease-in-out;
    }
    to {
      margin-top: 19px;
      width: 538px;
    }
  }

  .slideinsuccess {
    width: 90%;
    animation-duration: 0.5s;
    animation-name: slideinsuccess;
  }

  @keyframes slideinsuccess {
    from {
      margin-top: 0px;
      width: 538px;
      transition: ease-in-out;
    }
    to {
      margin-top: 19px;
      width: 538px;
    }
  }

  .fade-in {
    animation: fadeIn ease 0.5s;
    -webkit-animation: fadeIn ease 0.5s;
    -moz-animation: fadeIn ease 0.5s;
    -o-animation: fadeIn ease 0.5s;
    -ms-animation: fadeIn ease 0.5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  //End Animation Transitions

  .w-10 {
    width: 10% !important;
  }

  .w-15 {
    width: 15% !important;
  }

  .w-20 {
    width: 20% !important;
  }

  .w-80 {
    width: 80%;
  }

  .w-70 {
    width: 70%;
  }

  .w-60 {
    width: 60%;
  }

  //Margins
  .mt-40p {
    margin-top: 40px;
  }

  .ml-10p {
    margin-left: 10px;
  }

  .ml-15p {
    margin-left: 15px;
  }

  .ml-12p {
    margin-left: 12px;
  }

  .mb-0p {
    margin-bottom: 0px !important;
  }

  .mt-25p {
    margin-top: 25px;
  }

  //Gaps
  .gap-50 {
    height: 50px;
  }

  .v-gap-60 {
    height: 60px;
  }

  .error-space {
    height: 50px;
  }

  //colors
  $brand: #00c4ca;
  $mild-brand: #008082;
  $dark-blue: #243651;
  $mild-blue: #283e5b;
  /* Secondary
    -----------------*/
  $alert: #e53a63;
  $dark-alert: #a92c56;
  $fresh-green: #27debf;
  $gray: #647693;
  /* Complimentary
    -----------------*/
  $dark-grey: #ececec;

  .c-grey {
    color: #8a8b8c;
  }

  .c-brand {
    color: #678e93;
  }

  .c-orange {
    color: #f37452;
  }

  .c-white-orange-bk {
    color: #ffffff;
    background-color: #f37452;
  }

  .c-b-red {
    color: #8f0e49;
  }

  .brand-highlight {
    color: $brand !important;
  }

  .alert-highlight {
    color: $alert !important;
  }

  //Line heights
  .lh-1-9 {
    line-height: 1.9;
  }

  //Texts
  .text-sm {
    color: #364255;
    font-size: 14px;
  }

  .fw-600 {
    font-weight: 600;
  }

  //Text case
  .uppercase {
    text-transform: uppercase;
  }

  //Buttons
  .btn.disabled, .btn:disabled {
    opacity: 0.5;
  }

  .btn-custom.disabled, .btn-custom:disabled {
    opacity: 0.5;
  }

  button:disabled {
    opacity: 0.5;
  }

  .lg-button {
    .btn {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      border: 0px;
      border-radius: 25px;
      text-align: center;
      padding: 10px 20px;
      margin: 0 auto;
      background-color: #e53a63;
      width: 50%;
    }
  }

  .lg-button {
    text-align: center;

    .btn-custom {
      color: #ffffff;
      font-weight: 600;
      border: 0px;
      border-radius: 25px;
      text-align: center;
      margin: 0 auto;
      width: 50%;
      font-size: 16px;
      padding: 10px 20px;
      background-color: #e53a63;
      transition: 0.3s;
      cursor: pointer;
    }
  }

  .md-button {
    .btn {
      color: #ffffff;
      font-size: 21px;
      font-weight: 600;
      width: 275px;
      padding: 15px;
      margin: 0 auto;
      background-image: linear-gradient(45deg, #f08493, #a80050);
      border: 0px;
      border-radius: 25px;
    }
  }

  .sm-button {
    .btn {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      width: 200px;
      padding: 10px 20px;
      text-align: center;
      background-color: #e53a63;
      border: 0px;
      border-radius: 25px;
    }
  }

  .thin-button {
    .btn {
      color: #ffffff;
      font-size: 22px;
      font-weight: 600;
      width: 265px;
      padding: 14px 10px;
      text-align: center;
      background-image: linear-gradient(45deg, #f08493, #a80050);
      border: 0px;
      border-radius: 25px;
    }
  }

  //Button effect
  //.lg-button {
  //  .btn {
  //    color: #ffffff;
  //    font-size: 21px;
  //    font-weight: 600;
  //    border: 0px;
  //    border-radius: 25px;
  //    text-align: center;
  //    padding: 20px 10px;;
  //    margin: 0 auto;
  //    background: linear-gradient(90deg, var(--c1, #f08493), var(--c2, #a80050) 51%, var(--c1, #f08493)) var(--x, 0)/ 200%;
  //    width: 100%;
  //    transition: 0.5s;
  //  }
  //}

  .lg-button {
    .btn:hover {
      background-color: #a92c56;
    }
  }

  .lg-button {
    .btn-custom:hover {
      background-color: #a92c56;
      transition: 0.5s;
    }
  }

  .md-button {
    .btn {
      color: #ffffff;
      font-size: 21px;
      font-weight: 600;
      width: 275px;
      padding: 15px;
      margin: 0 auto;
      background-image: linear-gradient(90deg, var(--c1, #f08493), var(--c2, #a80050) 51%, var(--c1, #f08493)) var(--x, 0)/ 200%;
      border: 0px;
      border-radius: 25px;
    }
  }

  .md-button {
    .btn:hover {
      --x: 100%;
    }
  }

  //.sm-button {
  //  .btn {
  //    color: #ffffff;
  //    font-size: 20px;
  //    font-weight: 600;
  //    width: 200px;
  //    padding: 16px 10px;
  //    text-align: center;
  //    background: linear-gradient(90deg, var(--c1, #f08493), var(--c2, #a80050) 51%, var(--c1, #f08493)) var(--x, 0)/ 200%;
  //    transition: 0.5s;
  //    border-radius: 25px;
  //  }
  //}

  .sm-button {
    .btn:hover {
      background-color: #a92c56;
    }
  }

  .btn.focus, .btn:focus {
    box-shadow: 0px 0px 0px;
  }

  .button-click {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0 auto;
    transition: all 0.4s ease-in;

    span[class^='ion'] {
      position: relative;
    }

    //start click effect
    &:before {
      content: '';
      background-color: aliceblue;
      //border-radius: 50%;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale(0.001, 0.001);
    }

    &:focus {
      outline: 0;
      color: #fff;

      &:before {
        animation: effect_dylan 0.8s ease-out;
      }
    }
  }

  @keyframes effect_dylan {
    50% {
      transform: scale(1.5, 1.5);
      opacity: 0;
    }
    99% {
      transform: scale(0.001, 0.001);
      opacity: 0;
    }
    100% {
      transform: scale(0.001, 0.001);
      opacity: 1;
    }
  }

  //Button effect end
  //Buttons End

  //Error Massage
  .register_error {
    color: #c51a4c;
    padding: 12px 15px;
    background: #fafafa;
    border-left: 6px solid #ca315f;
    border-right: 6px solid #fafafa;
    border-radius: 0px;
    box-shadow: 2px 2px 7px -1px #e0e0e0;
    text-align: left;
    margin-top: 19px;
    margin-bottom: 16px;
    transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    width: 538px;
    opacity: 0.8;
    cursor: pointer;
  }

  .register_error:hover {
    opacity: 1;
  }

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1;
    color: #8e8e8e;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }

  //Error Massage End
  //Success Massage
  .success_msg {
    color: #097684;
    padding: 12px 15px;
    background: #fafafa;
    border-left: 6px solid #097684;
    border-right: 6px solid #fafafa;
    border-radius: 0px;
    box-shadow: 2px 2px 7px -1px #e0e0e0;
    text-align: left;
    margin-top: 19px;
    margin-bottom: 16px;
    transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    width: 538px;
    opacity: 0.8;
    cursor: pointer;
  }

  .success_msg:hover {
    opacity: 1;
  }

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1;
    color: #8e8e8e;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }

  //Success Massage End

  nav {
    .wrapper {
      text-align: center;
      padding: 15px 0px;
      box-shadow: 1px 1px 3px #dedede;

      img {
        width: auto;
        max-width: 225px;
      }
    }

    .login-wrapper {
      text-align: center;
      padding: 40px 0px;
      //box-shadow: 1px 1px 3px #dedede;
      //background-color: #00b9c4;
      img {
        width: auto;
        max-width: 225px;
      }
    }
  }

  .wrap {
    min-height: 59vh;
  }

  .back {
    margin: 30px 0px 0px 30px;

    img {
      width: 22px;
    }
  }

  .custom-container {
    max-width: 1800px;
    margin: 0 auto;
    padding: 0px 30px;
  }

  .header {
    margin-top: 25px;
    text-align: center;

    .banner {
      margin-top: 0px;
      margin-bottom: 30px;

      img {
        width: 300px;
      }
    }

    .title {
      font-size: 36px;
      font-weight: 600;
      color: #243651;
      padding: 0px 25px;
      margin-bottom: 15px;
      line-height: 1.3;

      .firstname {
        text-transform: capitalize;
      }
    }

    .sub-title {
      font-size: 22px;
      font-weight: 400;
      color: #243651;
      padding: 0px 25px;
    }
  }

  .left-header {
    margin-top: 65px;
    text-align: left;

    .title {
      font-size: 36px;
      font-weight: 600;
      color: #243651;
      padding: 0px 25px;
      line-height: 1.2;
      margin-bottom: 15px;
    }

    .sub-title {
      font-size: 22px;
      font-weight: 400;
      color: #243651;
      padding: 0px 25px;
    }
  }

  .info {
    margin: 32px;
    margin-bottom: 5px;

    a {
      font-size: 12px;
      font-weight: 600;
      color: #e5856b;
    }

    a:hover {
      text-decoration: none;
      color: #e5856b;
    }

    .ico {
      width: 18px;
      vertical-align: text-bottom;
      margin-right: 3px;
    }
  }

  .info-inside {
    float: right;
    margin: 0px;

    a {
      font-size: 15px;
      font-weight: 600;
      color: #e5856b;
    }

    .ico {
      width: 18px;
      vertical-align: sub;
      margin-right: 2px;
    }
  }

  .info-left {
    margin-top: 30px;
    margin-bottom: 30px;

    a {
      font-size: 12px;
      font-weight: 600;
      color: #e5856b;
    }

    a:hover {
      text-decoration: none;
      color: #e5856b;
    }

    .ico {
      width: 18px;
      vertical-align: text-bottom;
      margin-right: 3px;
    }
  }

  .main {
    margin-top: 70px;

    .form-container {
      max-width: 580px;
      width: 580px;
      position: relative;
    }

    .form-left-container {
      padding: 0px 55px;
    }

    .art {
      position: absolute;
      right: -440px;
      bottom: 56px;
      width: 350px;

      img {
        width: 350px;
      }
    }

    .box-title {
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 30px;
      color: #243651;
    }

    .boxes {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .outofbox {
        width: 100px;
        text-align: center;
        margin-top: 25%;
        font-size: 30px;
        font-weight: 600;
      }

      .box {
        width: 350px;
        text-align: center;
        padding: 50px 25px;
        background-color: #fafafa;
        margin-top: 25px;
        border-radius: 25px;

        .title {
          font-size: 30px;
          font-weight: 600;
          color: #243651;
        }

        .desc {
          font-size: 18px;
          font-weight: 400;
          color: #364255;
          margin-top: 10px;
          height: 30px;
        }

        .ico {
          margin: 50px 0px;
          height: 135px;
          line-height: 135px;
        }

        .md-button {
          .btn {
            color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            width: 75%;
            padding: 10px 10px;
            margin: 0 auto;
            background-color: #e53a63;
            border: 0px;
            border-radius: 25px;
          }
        }
      }

      .multi-box {
        width: 30%;
        text-align: center;
        padding: 55px 40px 50px 40px;
        background-color: #fafafa;
        margin-top: 25px;

        .ico {
          margin: 0px 0px 50px 0px;
          height: 100px;
        }

        .head {
          font-size: 20px;
          font-weight: 300;
          color: #243651;
          margin-bottom: 10px;
        }

        .title {
          font-size: 22px;
          font-weight: 600;
          color: #243651;
        }

        .desc {
          font-size: 14px;
          font-weight: 400;
          color: #364255;
          margin-top: 25px;
          margin-bottom: 30px;
          height: 60px;
        }

        .note {
          font-size: 14px;
          height: 125px;
        }

        .md-button {
          .btn {
            color: #ffffff;
            font-size: 21px;
            font-weight: 600;
            width: 70%;
            padding: 15px;
            margin: 0 auto;
            background-image: linear-gradient(45deg, #f08493, #a80050);
            border: 0px;
            border-radius: 25px;
          }
        }
      }
    }

    .show-hide {
      color: #dc8067;
      float: right;
      font-size: 18px;
      cursor: pointer;

      img {
        vertical-align: initial;
        margin-right: 5px;
      }
    }

    .link-wrap {
      text-align: center;
      margin: 50px;

      a:hover {
        text-decoration: none;
      }

      .link {
        font-size: 20px;
        font-weight: 500;
        color: #678e93;
        border-bottom: 2px solid #678e93;
      }
    }

    .link-wrap-sub {
      text-align: center;
      margin: 50px;

      a:hover {
        text-decoration: none;
      }

      .link {
        font-size: 18px;
        font-weight: 500;
        color: #7b7b7b;
        border-bottom: 2px solid #dcdcdc;
      }
    }

    .alt-link {
      font-size: 16px;
      font-weight: 400;
      color: #8a8b8c;
      text-align: center;

      a {
        color: #dc8067 !important;
      }

      a:hover {
        text-decoration: underline;
        transition: 0.5s;
      }
    }

    .terms_n_c {
      color: #dc8067 !important;
    }

    .terms_n_c:hover {
      text-decoration: underline;
      transition: 0.5s;
      cursor: pointer;
    }

    .alt-link-pass {
      font-size: 18px;
      font-weight: 400;
      color: #8a8b8c;
      text-align: center;
      margin: 30px;

      a {
        color: #dc8067 !important;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .lbl-title {
      font-size: 16px;
      font-weight: 600;
      color: #243651;
    }
  }

  //CheckBox
  .inputGroupcheck {
    background-color: #fafafa;
    display: block;
    margin: 10px 0;
    position: relative;
    border-radius: 25px;

    label {
      padding: 15px 30px;
      width: 100%;
      display: block;
      text-align: left;
      color: #364255;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
      border-radius: 25px;

      &:before {
        width: 100%;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #dfeced;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 32px;
        height: 32px;
        content: '';
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
      color: #364255;
      border-radius: 25px;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }

      &:after {
        background-image: url("img/tick.png");
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
  }

  //CheckBox end

  //Popover
  .popover__title {
    font-size: 21px;
    line-height: 0;
    text-decoration: none;
    color: #f68166 !important;
    text-align: left;
    padding: 0;
    cursor: pointer;
  }

  .popover__wrapper {
    position: relative;
    margin-top: 0px;
    display: inline-block;
  }

  .popover__wrapper a {
    text-decoration: none;
  }

  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -150px;
    transform: translate(280px, -16px);
    background-color: #e37f64;
    padding: 13px 10px 0px 20px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
    border-radius: 8px;
    font-size: 12px;
    color: #fff;
  }

  .popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    left: calc(0% - 7px);
    top: 6px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #e37f64;
    transition-duration: 0.3s;
    transition-property: transform;
  }

  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(280px, -16px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }

  .popover__message {
    text-align: left;
  }

  //Popover end

  .footer {
    /* background-color: #678e93;
    height: 135px;
    margin-top: 100px; */
    background-color: #ff0e0e;
    height: 70px;
    margin-top: 100px;
  }

  .copywrite {
    margin-top: 20px;
    text-align: center;
    font-family: Poppins;
    color: rgba(221, 221, 221, 0.5);
    padding-top: 20px;
    font-size: small;
  }

  //Bootstrap custom
  .form-row > .col, .form-row > [class*=col-] {
    padding-right: 7px;
    padding-left: 7px;
  }

  .form-control {
    height: calc(2.5em + .75rem + 2px);
    background-color: #fafafa;
    border: 1px solid #fafafa;
    border-radius: 25px;
    padding: .375rem 1.3rem;
  }

  .form-control:focus {
    border-color: #9fdee2 !important;
    box-shadow: 0 0 0 0.1rem rgba(155, 225, 228, 0.25) !important;
  }

  .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0.1rem;
  }

  .form-group {
    padding: 0px 2px;
    margin-bottom: 1.5rem;
  }

  label {
    font-size: 16px;
    font-weight: 400;
    color: #8a8b8c;
  }

  select {
    font-family: 'Poppins', sans-serif !important;
    color: #8a8b8c !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("./img/drop-ico.png");
    background-position: right 18px center; /*Positioning*/
    background-repeat: no-repeat;
  }

  input::placeholder {
    color: #cecece !important;
    font-size: 14px;
  }

  .reg-form-wrapper .error_text_danger {
    color: #df5757;
  }

  .error_text_danger {
    color: #df5757;
  }

  //registration form design end

  //React custom
  .react-tel-input input[type="text"], .react-tel-input input[type="tel"] {
    font-size: 15px;
    color: #cecece;
    padding: 26px 0px;
    padding-left: 56px;
  }

  .code-input {
    width: inherit !important;
  }

  .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    width: 100% !important;
    border-radius: 20px;
  }

  .intl-tel-input {
    display: block !important;

  }

  .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    background-color: rgb(250, 250, 250) !important;
  }

  .phone-input {
    visibility: hidden;
  }

  //Media Quary
  @include xxl {
    .header {
      .title {
        font-size: 36px;
        font-weight: 600;
        color: #243651;
        padding: 0px 25px;
        margin-bottom: 15px;
        line-height: 1.3;
      }

      .sub-title {
        font-size: 22px;
        font-weight: 400;
        color: #243651;
        padding: 0px 25px;
      }
    }
    .left-header {
      margin-top: 35px;
      text-align: left;

      .title {
        font-size: 36px;
        font-weight: 600;
        color: #243651;
        padding: 0px 25px;
        line-height: 1.2;
        margin-bottom: 15px;
      }

      .sub-title {
        font-size: 22px;
        font-weight: 400;
        color: #243651;
        padding: 0px 25px;
      }
    }
    .main {
      margin-top: 70px;

      .form-container {
        max-width: 580px !important;
        width: 580px !important;
        position: relative;
      }

      .art {
        position: absolute;
        right: -310px;
        bottom: 56px;
        width: 250px;
        margin-bottom: 90px;

        img {
          width: 250px;
        }
      }
    }
    .list-tnc {
      .desc {
        font-size: 14px;
      }
    }
  }

  @include xl {
    .main {
      margin-top: 70px;

      .boxes {
        .outofbox {
          width: 100%;
          margin-top: 5%;
        }
      }

      .form-container {
        max-width: 580px !important;
        width: 580px !important;
      }

      .art {
        position: relative;
        top: 50px;
        bottom: 0px;
        left: 130px;
        width: 250px;

        img {
          width: 250px;
        }
      }
    }
  }

  @include md {
    nav {
      .wrapper {
        text-align: center;
        padding: 15px 0px;
        box-shadow: 1px 1px 3px #dedede;

        img {
          width: auto;
          max-width: 190px;
        }
      }
    }
    .back {
      margin: 20px 0px 0px 20px;

      img {
        width: 15px;
      }
    }
    .header {
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #243651;
        line-height: 1.2;
        margin-bottom: 18px;
      }

      .sub-title {
        font-size: 18px;
        font-weight: 400;
        color: #243651;
        line-height: 1.4;
        margin-bottom: 25px;
      }
    }
    .left-header {
      margin-top: 0px;
      text-align: left;
      padding: 25px;

      .title {
        font-size: 30px;
        font-weight: 600;
        color: #243651;
        line-height: 1.2;
        margin-bottom: 18px;
      }

      .sub-title {
        font-size: 18px;
        font-weight: 400;
        color: #243651;
        line-height: 1.4;
      }
    }
    .info {
      margin: 32px;
      margin-bottom: 5px;

      a {
        font-size: 14px;
        font-weight: 500;
        color: #e5856b;
      }

      a:hover {
        text-decoration: none;
        color: #e5856b;
      }

      .ico {
        width: 19px;
        vertical-align: text-bottom;
        margin-right: 3px;
      }
    }
    .art {
      position: absolute;
      right: 100px;
      bottom: 30px;
      width: 300px;

      img {
        width: 300px;
      }
    }
    .main {
      margin-top: 0px !important;

      .form-container {
        max-width: 460px !important;
        width: 460px !important;
        position: relative;
      }

      .boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .box {
          width: 340px;
          text-align: center;
          padding: 45px 30px;
          padding-bottom: 70px;
          background-color: #fafafa;
          margin-top: 25px;

          .title {
            font-size: 24px;
            font-weight: 600;
            color: #243651;
          }

          .desc {
            font-size: 13px;
            font-weight: 400;
            color: #364255;
            margin-top: 10px;
          }

          .ico {
            margin: 50px 0px;

            img {
              width: 90px;
            }
          }

          .md-button {
            .btn {
              color: #ffffff;
              font-size: 16px;
              font-weight: 600;
              width: 75%;
              padding: 10px 10px;
              margin: 0 auto;
              background-color: #e53a63;
              border: 0px;
              border-radius: 25px;
            }
          }
        }

        .multi-box {
          width: 100%;
          text-align: center;
          padding: 55px 30px 50px 30px;
          background-color: #fafafa;
          margin-top: 25px;

          ico {
            margin: 0px 0px 50px 0px;
            height: 100px;
          }

          .head {
            font-size: 20px;
            font-weight: 300;
            color: #243651;
          }

          .title {
            font-size: 22px;
            font-weight: 600;
            color: #243651;
          }

          .desc {
            font-size: 14px;
            font-weight: 400;
            color: #364255;
            margin-top: 10px;
          }

          .note {
            font-size: 14px;
          }

          .md-button {
            .btn {
              color: #ffffff;
              font-size: 21px;
              font-weight: 600;
              width: 70%;
              padding: 10px;
              margin: 0 auto;
              background-image: linear-gradient(45deg, #f08493, #a80050);
              border: 0px;
              border-radius: 25px;
            }
          }
        }
      }

      .link-wrap {
        text-align: center;
        margin: 35px 20px;

        a:hover {
          text-decoration: none;
        }

        .link {
          font-size: 16px;
          font-weight: 400;
          color: #678e93;
          border-bottom: 2px solid #678e93;
        }
      }

      .link-wrap-sub {
        text-align: center;
        margin: 35px 20px;

        a:hover {
          text-decoration: none;
        }

        .link {
          font-size: 14px;
          font-weight: 500;
          color: #7b7b7b;
          border-bottom: 2px solid #dcdcdc;
        }
      }

      .lbl-title {
        font-size: 18px;
        font-weight: 400;
        color: #243651;
        line-height: 1.4;
      }
    }
    .footer {
      background-color: #10171f;
      height: 70px;
      margin-top: 100px;
    }
  }

  @include semism() {

    .register_error {
      width: 90%;
    }
    .success_msg {
      width: 90%;
    }

    .main {
      margin-top: 25px;

      .form-container {
        max-width: 460px !important;
        width: 460px !important;
        position: relative;
      }

      .art {
        position: relative;
        top: 34px;
        bottom: 0px;
        left: 90px;
        width: 225px;
        margin-bottom: 60px;

        img {
          width: 225px;
        }
      }

      .alt-link {
        font-size: 14px;
        font-weight: 400;
        color: #8a8b8c;
        text-align: center;

        a {
          color: #dc8067 !important;
        }

        a:hover {
          text-decoration: none;
        }
      }

      .alt-link-pass {
        font-size: 14px;
        font-weight: 400;
        color: #8a8b8c;
        text-align: center;
        margin: 20px;

        a {
          color: #dc8067 !important;
        }

        a:hover {
          text-decoration: none;
        }
      }
    }
    .footer {
      margin-top: 60px;
    }
  }

  @include sm {
    //Animation Transitions
    .slidein {
      position: absolute;
      left: 0%;
      margin-left: 11%;
      animation-duration: 0.5s;
      animation-name: slidein;
    }
    @keyframes slidein {
      from {
        margin-top: 0px;
        width: 78%;
        transition: ease-in-out;
      }
      to {
        margin-top: 15px;
        width: 78%;
      }
    }
    .slideinsuccess {
      width: 78%;

      .close {
        line-height: 0;
      }
    }
    @keyframes slideinsuccess {
      from {
        margin-top: 0px;
        width: 78%;
        transition: ease-in-out;
      }
      to {
        margin-top: 19px;
        width: 78%;
      }
    }
    //React custom
    .react-tel-input input[type="text"], .react-tel-input input[type="tel"] {
      font-size: 14px;
      color: #cecece;
      padding: 20px 0px;
      padding-left: 52px;
    }

    //Buttons
    .lg-button {
      .btn {
        width: 100%;
      }
    }
    .lg-button {
      .btn-custom {
        width: 100%;
      }
    }
    .sm-button {
      .btn {
        width: 100%;
      }
    }
    .thin-button {
      .btn {
        font-size: 16px;
        border-radius: 25px;
        width: 180px;
      }
    }

    .sm-text {
      font-size: 10px !important;
    }
    .register_error {
      width: 78%;
      font-size: 12px;
      opacity: 1;
      padding: 5px 16px;
      margin-top: 15px;
      line-height: 1.8;
    }
    .success_msg {
      width: 78%;
      font-size: 12px;
      opacity: 1;
      padding: 5px 16px;
      margin-top: 15px;
      line-height: 1.8;
    }
    nav {
      .wrapper {
        text-align: center;
        padding: 15px 0px;
        box-shadow: 1px 1px 3px #dedede;

        img {
          width: auto;
          max-width: 155px;
        }
      }

      .login-wrapper {
        img {
          width: auto;
          max-width: 155px;
        }
      }
    }
    .back {
      margin: 20px 0px 0px 20px;

      img {
        width: 15px;
      }
    }
    .header {
      margin-top: 5px;
      text-align: center;
      padding: 10px;

      .banner {
        margin-top: 0px;
        margin-bottom: 20px;

        img {
          width: 200px;
        }
      }

      .title {
        font-size: 26px;
        font-weight: 600;
        color: #243651;
        line-height: 1.2;
        margin-bottom: 18px;
      }

      .sub-title {
        font-size: 16px;
        font-weight: 400;
        color: #243651;
        line-height: 1.4;
        margin-bottom: 15px;
      }
    }
    .left-header {
      margin-top: 0px;
      margin-bottom: 45px;
      text-align: center;
      padding: 0px;
      padding-top: 10px;

      .title {
        font-size: 26px;
        font-weight: 600;
        color: #243651;
        line-height: 1.2;
        margin-bottom: 18px;
      }

      .sub-title {
        font-size: 16px;
        font-weight: 400;
        color: #243651;
        line-height: 1.4;
      }
    }
    .info {
      margin: 32px;
      margin-bottom: 5px;

      a {
        font-size: 14px;
        font-weight: 500;
        color: #e5856b;
      }

      a:hover {
        text-decoration: none;
        color: #e5856b;
      }

      .ico {
        width: 19px;
        vertical-align: text-bottom;
        margin-right: 3px;
      }
    }
    .info-left {
      margin-top: 40px;
      margin-bottom: 60px;
      text-align: center;

      a {
        font-size: 14px;
        font-weight: 600;
        color: #e5856b;
      }

      a:hover {
        text-decoration: none;
        color: #e5856b;
      }

      .ico {
        width: 23px;
        vertical-align: text-bottom;
        margin-right: 3px;
      }
    }
    .main {
      margin-top: 0px;

      .box-title {
        font-size: 22px;
        margin-top: 25px;
        margin-bottom: 15px;
      }

      .boxes {
        .outofbox {
          width: 100%;
          margin-top: 10%;
          font-size: 25px;
        }
      }

      .form-container {
        max-width: 550px !important;
        width: 100% !important;
        position: relative;
        padding: 10px 20px;
      }

      .form-left-container {
        padding: 0px 10px;
      }

      .art {
        position: relative;
        top: 34px;
        bottom: auto;
        left: auto;
        right: auto;
        margin: 0 auto;
        width: 200px;
        margin-bottom: 30px;

        img {
          width: 200px;
        }
      }

      .boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .box {
          width: 340px;
          text-align: center;
          padding: 45px 30px;
          padding-bottom: 70px;
          background-color: #fafafa;
          margin-top: 25px;

          .title {
            font-size: 22px;
            font-weight: 600;
            color: #243651;
          }

          .desc {
            font-size: 13px;
            font-weight: 400;
            color: #364255;
            margin-top: 10px;
          }

          .ico {
            margin: 20px 0px;

            img {
              width: 90px;
            }
          }

          .md-button {
            .btn {
              color: #ffffff;
              font-size: 16px;
              font-weight: 600;
              width: 75%;
              padding: 10px 10px;
              margin: 0 auto;
              background-color: #e53a63;
              border: 0px;
              border-radius: 25px;
            }
          }
        }

        .multi-box {
          width: 100%;
          text-align: center;
          padding: 55px 30px 50px 30px;
          background-color: #fafafa;
          margin-top: 25px;

          .ico {
            margin: 0px 0px 50px 0px;
            height: 100px;
          }

          .head {
            font-size: 20px;
            font-weight: 300;
            color: #243651;
          }

          .title {
            font-size: 22px;
            font-weight: 600;
            color: #243651;
          }

          .desc {
            font-size: 14px;
            font-weight: 400;
            color: #364255;
            margin-top: 10px;
          }

          .note {
            font-size: 14px;
          }

          .md-button {
            .btn {
              color: #ffffff;
              font-size: 18px;
              font-weight: 600;
              width: 90%;
              padding: 10px;
              margin: 0 auto;
              background-image: linear-gradient(45deg, #f08493, #a80050);
              border: 0px;
              border-radius: 25px;
            }
          }
        }
      }

      .link-wrap {
        text-align: center;
        margin: 35px 20px;

        a:hover {
          text-decoration: none;
        }

        .link {
          font-size: 16px;
          font-weight: 400;
          color: #678e93;
          border-bottom: 2px solid #678e93;
        }
      }

      .link-wrap-sub {
        text-align: center;
        margin: 35px 20px;

        a:hover {
          text-decoration: none;
        }

        .link {
          font-size: 14px;
          font-weight: 500;
          color: #7b7b7b;
          border-bottom: 2px solid #dcdcdc;
        }
      }

      .lbl-title {
        font-size: 16px;
        font-weight: 400;
        color: #243651;
        line-height: 1.4;
      }
    }
    .show-hide {
      color: #dc8067;
      float: right;
      font-size: 14px !important;
      cursor: pointer;

      img {
        vertical-align: initial;
        margin-right: 1px !important;
        width: 20px !important;
      }
    }
    .footer {
      background-color: #10171f;
      height: 60px;
      margin-top: 50px;
    }

    //Popover
    .popover__title {
      font-size: 18px;
      line-height: 0;
      text-decoration: none;
      color: #f68166;
      text-align: left;
      padding: 0;
      cursor: pointer;
    }
    .popover__wrapper {
      position: relative;
      margin-top: 0px;
      display: inline-block;
    }
    .popover__wrapper a {
      text-decoration: none;
    }
    .popover__content {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: -390px;
      top: 45px;
      transform: translate(200px, -16px);
      background-color: #e37f64;
      padding: 13px 10px 0px 20px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      width: 250px;
      border-radius: 8px;
      font-size: 12px;
      color: #fff;
    }
    .popover__content:before {
      position: absolute;
      z-index: -1;
      content: "";
      left: calc(65% - 7px);
      top: -15px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #e37f64;
      transition-duration: 0.3s;
      transition-property: transform;
    }
    .popover__wrapper:hover .popover__content {
      z-index: 10;
      opacity: 1;
      visibility: visible;
      transform: translate(221px, -16px);
      transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
      cursor: pointer;
    }
    .popover__message {
      text-align: left;
    }
    //Popover end

    //Bootstrap custom
    .form-row > .col, .form-row > [class*=col-] {
      padding-right: 7px;
      padding-left: 7px;
    }
    .form-control {
      height: calc(1.8em + .75rem + 2px) !important;
      background-color: #fafafa;
      border: 1px solid #fafafa;
    }
    .form-control:focus {
      border-color: #9fdee2;
      box-shadow: 0 0 0 0.1rem rgba(155, 225, 228, 0.25);
    }
    .form-check-input {
      width: 20px;
      height: 20px;
    }
    .form-group {
      padding: 0px 2px;
      margin-bottom: 1.3rem;
    }
    label {
      font-size: 14px;
      font-weight: 400;
      color: #8a8b8c;
    }
    .validation-error {
      line-height: 1.2;
      font-size: 13px;
    }
    select {
      font-family: 'Poppins', sans-serif !important;
      color: #8a8b8c !important;
      font-size: 13px !important;
      background-size: 12px;
    }
    input::placeholder {
      color: #cecece !important;
      font-size: 12px !important;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  //Register Page CSS
  .register {
    .header {
      margin-top: 65px;
    }

    ul li {
      color: #ff8f00;
    }

    @include semism {
      ul li {
        font-size: 12px;
      }
    }
    @include sm {
      .header {
        margin-top: 40px;
      }
      ul li {
        font-size: 10px;
      }
    }
  }

  //Register Page End

  //Verify OTP page CSS
  .verify-otp {
    .header {
      margin-top: 40px;
    }

    .main {
      margin-top: 55px;
    }

    @include sm {
      .header {
        margin-top: 20px;
      }
      .info-inside {
        float: right;
        margin: 0px;

        a {
          font-size: 11px;
          font-weight: 500;
          color: #e5856b;
        }

        .ico {
          width: 12px;
          vertical-align: sub;
          margin-right: 0px;
        }
      }
    }
  }

  //Verify OTP End

  //CitizenShip Page CSS
  .citizenship {
    height: 67vh;

    label {
      font-weight: 600;
      margin-left: 20px;
    }

    .left-header {
      margin-top: 65px;
    }

    .info-left {
      margin-bottom: 35px;

      .popover__content {
        left: -172px;
      }
    }

    @include sm {
      height: auto;
      .left-header {
        .title {
          padding: 0px 10px;
        }

        .sub-title {
          padding: 0px 10px;
        }
      }
      .main {
        .form-container {
          padding: 10px 0px;
        }

        .form-left-container {
          .row {
            margin-left: 0px;
            margin-right: 0px;
          }
        }
      }
      .left-header {
        margin-top: 30px;
      }
      .sm-button {
        position: relative !important;
        top: 0 !important;
        margin-top: 0px !important;
        width: 100% !important;
      }
      .info-left {
        margin-bottom: 35px;

        .popover__content {
          left: -305px;
        }
      }
    }
    @include md {
      .sm-button {
        position: relative !important;
        top: 0 !important;
        margin-top: 0px !important;
        width: 100% !important;
      }
    }
  }

  //CitizenShip Page End

  //Annual Income page CSS
  .annualincome {
    label {
      font-size: 16px;
    }

    .info {
      margin: 32px;
      margin-bottom: 5px;
      margin-top: 50px;
    }

    .art-bottom {
      margin-bottom: -100px;
      text-align: right;
      margin-top: -280px;

      img {
        width: 650px;
      }
    }

    .popover__content {
      left: -175px;
    }

    @include xxl {
      .art-bottom {
        img {
          width: 500px;
        }
      }
    }
    @include xl {
      .art-bottom {
        margin-bottom: -100px;
        text-align: center;
        margin-top: 70px;
      }
    }
    @include sm {
      label {
        font-size: 12px;
      }
      .header {
        margin-top: 0px;
      }
      .art-bottom {
        margin-bottom: -50px;
        text-align: center;
        margin-top: 70px;
      }
      .popover__wrapper:hover .popover__content {
        z-index: 10;
        opacity: 1;
        visibility: visible;
        transform: translate(280px, -16px);
        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
        cursor: pointer;
      }
      .popover__content {
        left: -330px;
      }
    }
  }

  //End Annual Income page CSS

  //Net Assets page CSS
  .netasset {
    label {
      font-size: 16px;
    }

    .info {
      margin: 32px;
      margin-bottom: 5px;
      margin-top: 50px;
    }

    .art-bottom {
      margin-bottom: -100px;
      text-align: right;
      margin-top: -280px;

      img {
        width: 650px;
      }
    }

    .netasset-pop {
      .popover__content {
        left: -145px;
      }
    }

    .popover__content {
      left: -175px;
    }

    @include xxl {
      .art-bottom {
        img {
          width: 500px;
        }
      }
    }
    @include xl {
      .art-bottom {
        margin-bottom: -100px;
        text-align: center;
        margin-top: 70px;
      }
    }
    @include sm {
      label {
        font-size: 12px;
      }
      .header {
        margin-top: 0px;
      }
      .art-bottom {
        margin-bottom: -50px;
        text-align: center;
        margin-top: 70px;
      }
      .popover__wrapper:hover .popover__content {
        z-index: 10;
        opacity: 1;
        visibility: visible;
        transform: translate(280px, -16px);
        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
        cursor: pointer;
      }
      .netasset-pop {
        .popover__content {
          left: -380px;
        }
      }
      .popover__content {
        left: -325px;
      }
    }
  }

  //Net Asset End

  //Investor Goal
  .investor-goal {
    .popover__content {
      left: -70px;
    }

    .art-bottom {
      margin-bottom: -100px;
      text-align: right;
      margin-top: -200px;

      img {
        width: 370px;
      }
    }

    @include xxl {
      .art-bottom {
        img {
          width: 330px;
        }
      }
    }
    @include xl {
      .art-bottom {
        margin-top: -150px;

        img {
          width: 225px;
        }
      }
    }
    @include lg {
      .art-bottom {
        margin-bottom: -100px;
        text-align: right;
        margin-top: 70px;

        img {
          width: 300px;
        }
      }
    }
    @include semism {
      .art-bottom {
        margin-bottom: -60px;
      }
    }
    @include sm {
      .popover__content {
        left: -270px;
      }
      .art-bottom {
        margin-bottom: -50px;

        img {
          width: 270px;
        }
      }
    }
  }

  //End Investor Goal

  //Address page CSS
  .address {
    .popover__content {
      left: -175px;
    }

    .find-country {
      text-align: center;
      font-size: 12px;
      margin: 10px 15px;
      margin-bottom: -5px;
      color: #b7b7b7;
    }

    @include sm {
      .header {
        margin-top: 0px;
      }
      .info {
        margin: 32px;
        margin-bottom: 5px;
        margin-top: 50px;

        a {
          font-size: 14px;
          font-weight: 600;
          color: #e5856b;
        }

        a:hover {
          text-decoration: none;
          color: #e5856b;
        }
      }
      .popover__wrapper:hover .popover__content {
        z-index: 10;
        opacity: 1;
        visibility: visible;
        transform: translate(300px, -16px);
        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
        cursor: pointer;
      }
      .popover__content {
        left: -345px;
      }
    }
  }

  //Address page End

  //Employments page CSS
  .employments {
    .info {
      margin: 32px;
      margin-bottom: 5px;
      margin-top: 50px;
    }

    .popover__content {
      left: -175px;
    }

    @include sm {
      .header {
        margin-top: 0px;
      }
      .popover__wrapper:hover .popover__content {
        z-index: 10;
        opacity: 1;
        visibility: visible;
        transform: translate(300px, -16px);
        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
        cursor: pointer;
      }
      .popover__content {
        left: -345px;
      }
    }
  }

  //Employment End

  //Terms and Condition page CSS
  .tnc {
    .main {
      margin-top: 50px;

      .tnc-alt-link {
        font-size: 16px;
        font-weight: 600;
        color: #364255;
        margin-left: 28px;
        margin-bottom: 12px;
      }
    }

    .list-item {
      margin-bottom: 15px;
      display: grid;
      grid-template-columns: 40px 85%;

      .desc {
        color: #364255;
      }
    }

    .sm-button {
      margin: 0 auto;
    }

    .tnc-agree-check {
      margin-top: 5%;

      .check-box {
        margin-top: 10px;
      }

      .check-one {
        margin-right: 30px;
      }

      .form-check-input {
        margin-left: 0px;
        margin-top: 3px;
      }
    }

    .btn-tnc {
      margin-top: 5%;
    }

    @include md {
      .tnc-img {
        margin-top: 12rem;
      }
      .sm-button {
        position: absolute;
        top: 0;
        margin-top: 50px;
        width: 95%;
      }
      .tnc-footer {
        margin-top: 0px !important;
      }
    }
    @include sm {
      .text-sm {
        font-size: 12px;
      }
      .main {
        .tnc-alt-link {
          font-size: 12px;
          font-weight: 600;
          color: #364255;
          margin-left: 28px;
          margin-bottom: 12px;
        }

        .form-left-container {
          padding: 0px 20px;
        }
      }
      .list-tnc {
        .desc {
          font-size: 12px;
        }
      }
      .tnc-footer {
        margin-top: 0px !important;
      }
      .tnc-agree-check {
        .check-one {
          margin-right: 18px;
        }
      }
    }
  }

  //Terms and Condition End

  //Login page CSS
  .login {
    min-height: 68vh;

    .header {
      margin-top: 30px;
    }

    .alt-link {
      font-weight: 500 !important;
    }

    @include sm {
      .footer {
        margin-top: 40px !important;
      }
      .header {
        margin-top: 10px;
      }
    }
  }

  //Login End

  //Forgot Password
  .forgot-pass {

  }

  //End Forgot password

  //Investor Type page CSS
  .investor-type {
    .container-90pr {
      max-width: 90%;
      width: 90%;
      margin: 0 auto;
      padding: 20px;
    }

    .main {
      margin-top: 30px;
    }

    @include custom-1280 {
      .main {
        .boxes {
          .multi-box {
            text-align: center;
            padding: 55px 40px 50px 40px;
            background-color: #fafafa;
            margin-top: 25px;

            .ico {
              margin: 0px 0px 50px 0px;
              height: 100px;
            }

            .head {
              font-size: 20px;
              font-weight: 300;
              color: #243651;
              margin-bottom: 10px;
            }

            .title {
              font-size: 22px;
              font-weight: 600;
              color: #243651;
            }

            .desc {
              font-size: 14px;
              font-weight: 400;
              color: #364255;
              margin-top: 25px;
              margin-bottom: 30px;
              height: 88px;
            }

            .note {
              font-size: 14px;
              height: 125px;
            }
          }
        }
      }
    }
    @include sm {
      .header {
        margin-top: 0px;
      }
      .popover__content {
        left: -295px;
      }
    }
  }

  //Investor Type end

  //Upload Proof of Residence
  .upload-proofofresidence {
    .main {
      margin-top: 45px;
    }

    .popover__content {
      left: -175px;
    }

    @include sm {
      .popover__content {
        left: -275px;
      }
    }
  }

  //End Upload Proof of Residence

  //Upload NID and Passport
  .upload-nid-passport {
    .main {
      margin-top: 25px;

      .link-wrap {
        margin-bottom: 30px;
      }

      .link-wrap-sub {
        margin-top: 0px;

        .link {
          color: #243651;
          border-color: #243651;
        }
      }
    }

    .header {
      margin-top: 65px;
    }

    .popover__content {
      left: -126px;
    }

    @include sm {
      .header {
        margin-top: 40px;
      }
      .popover__content {
        left: -295px;
      }
    }
  }

  //End Upload NID and Passport

  //Registration Complete
  .registration-complete {
    .wrapper {
      width: 90%;
      margin: 0 auto;
    }

    .left-header {
      margin-top: 5px;

      .title {
        font-size: 32px;
      }

      .sub-title {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    .header {
      margin-top: 65px;
    }

    .main {
      margin-top: 40px;

      p {
        font-size: 22px;
        font-weight: 400;
        color: #243651;
        text-align: center;
      }

      .desc {
        font-size: 22px;
        font-weight: 600;
        color: #243651;
        text-align: center;
        margin-bottom: 30px;
      }
    }

    .contactus {
      font-size: 22px;
      font-weight: 400;
      color: #243651;
      text-align: center;
    }

    .input-append {
      .input-group {
        .input-group-append {
          display: block;

          .alert-input-group-text {
            font-size: 13px;
            padding: 16px 26px;
            line-height: 0;
            color: #fff;
            background-color: $alert;
            border: 1px solid $alert;
            border-radius: 0px 25px 25px 0px;
          }

          .brand-input-group-text {
            font-size: 13px;
            padding: 16px 26px;
            line-height: 0;
            color: #fff;
            background-color: $brand;
            border: 1px solid $brand;
            border-radius: 0px 25px 25px 0px;
          }
        }

        .form-control {
          height: calc(2rem + 2px);
          padding: .375rem .95rem;
          font-size: 13px;
          background-color: #fafafa;
          border: 1px solid #d7d7d7;
          border-radius: 25px 0px 0px 25px;
        }
      }
    }

    .input-append-lg {
      .input-group {
        .input-group-append {
          display: block;

          .alert-input-group-text {
            font-size: 13px;
            font-weight: 600;
            padding: 23px 26px;
            line-height: 0;
            color: #fff;
            background-color: $alert;
            border: 1px solid $alert;
            border-radius: 0px 25px 25px 0px;
          }

          .brand-input-group-text {
            font-size: 13px;
            font-weight: 600;
            padding: 23px 26px;
            line-height: 0;
            color: #fff;
            background-color: $brand;
            border: 1px solid $brand;
            border-radius: 0px 25px 25px 0px;
          }
        }

        .form-control {
          height: calc(3rem + 0px);
          padding: .375rem .95rem;
          font-size: 13px;
          background-color: #fafafa;
          border: 1px solid #d7d7d7;
          border-radius: 25px 0px 0px 25px;
        }
      }
    }

    .appends {
      margin-top: 40px;

      h5 {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 600;
      }

      p {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        color: #c3c3c3;
        margin-bottom: 22px;;
      }

      button {
        i {
          line-height: 0;
          vertical-align: bottom;
          font-size: 18px;
        }
      }

      .share {
        margin-left: 20px;

        img {
          width: 40px;
          margin-right: 8px;
        }

        svg {
          width: 40px;
          margin-right: 8px;
        }
      }
    }

    .meanwhile {
      margin-top: 60px;

      .contactus {
        font-size: 18px;
      }
    }

    @include md {
      .appends {
        h5 {
          margin-bottom: 22px;
          font-size: 14px;
        }

        button {
          i {
            font-size: 14px;
          }
        }

        .share {
          margin-left: 0px;
          margin-top: 20px;

          img {
            width: 30px;
          }
        }

        .input-append-lg {
          .input-group {
            .input-group-append {
              .alert-input-group-text {
                font-size: 11px;
                padding: 15px 26px;

              }

              .brand-input-group-text {
                font-size: 11px;
                padding: 15px 26px;
              }
            }

            .form-control {
              height: calc(2rem + 0px);
              font-size: 11px;
            }
          }
        }
      }
    }

    @include sm {
      .flex-start {
        justify-content: center;
      }
      .mob-100 {
        width: 100% !important;
      }
      .header {
        margin-top: 25px;
      }
      .main {
        img {
          width: 100%;
          margin-top: 30px;
          margin-bottom: 30px;
        }

        .desc {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 30px;
        }
      }

      .left-header {
        margin-bottom: 15px;

        .title {
          font-size: 20px;
        }

        .sub-title {
          font-size: 15px;
          padding: 0px;
          margin-bottom: 10px;
        }
      }

      .meanwhile {
        .contactus {
          font-size: 15px;
        }
      }

      .appends {
        h5 {
          margin-bottom: 12px;
          font-size: 14px;
        }

        button {
          i {
            font-size: 14px;
          }
        }

        .share {
          margin-left: 0px;
          margin-top: 0px;

          img {
            width: 30px;
          }
        }

        .input-append-lg {
          .input-group {
            .input-group-append {
              .alert-input-group-text {

              }

              .brand-input-group-text {
              }
            }

            .form-control {
            }
          }
        }
      }
    }
  }

  //End Registration Complete

}
