//Mixins
@mixin xs {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width: 560px) {
    @content;
  }
}

@mixin md {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (max-width: 1400px) {
    @content;
  }
}

@mixin xxlg {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}

/* Colors
--------------------*/
$purple: #722CFF;
$purple-light: #EFE7FF;
$blue: #4575EE;
$lightOrange: #FFF3E0;
$lightGreen: rgba(48, 178, 100, 0.22);
$orange: #FAA31A;
$deepOrange: #FB6340;
$red: #FD5750;
$eventLight: #fff7e8;
$successGreen: #41ec9d;
$assesmentSuccess: #23d0f0;
$tableLightBorderColor: #E3E3E3;

$bg-c-blue-light: #E7EDFF;
$bg-c-red-light: #FFE8E7;
$bg-c-orange-light: #FFF3E0;
$bg-c-purple-light: #EFE7FF;
$bg-c-dim-red: rgba(216, 20, 20, 0.1);
$bg-c-light-grey: #fdfdfd;
