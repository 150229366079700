@import "mixins&variable";

@charset "UTF-8";

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d7d9df;
  border-radius: 50px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;

  th {
    font-size: 14px;
    padding: 0.9rem;
    padding-left: 20px !important;
    padding-right: 20px !important;

    &:nth-child(1) {
      padding-left: 47px !important;
    }

    &:last-child {
      padding-right: 25px !important;
    }
  }

  td {
    vertical-align: middle !important;
    font-size: 14px;

    &:nth-child(1) {
      padding-left: 45px !important;
    }

    &:last-child {
      padding-right: 21px !important;
    }
  }
}

.table-dark {
  background: #FFFFFF;
  position: relative;

  thead {
    position: sticky;
    right: 0;
    left: 0;
    top: 0;
    width: 100%;

    th {
      color: #141944;
      border-color: #E3E3E3;
      background: #F6F6F6;
    }

    td {
      border-color: red;

    }
  }
}

.card-header {
  padding: 0px;
  margin-top: 10px;
  background-color: white;
  border-bottom: none;
  border: 0px solid black;
}

.back-button {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  color: #333;

  &:hover {
    color: #a4a4a4;
    text-decoration: none;
  }
}

.button-area {
  width: 23vh;
}

.circle-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #EFE7FF;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 22px;
  }

  h6 {
    font-size: 14px;
  }

  h4 {
    font-size: 22px;
  }
}


.search-sortby {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.has-search {
  position: relative;
}

.has-search .form-control {
  padding-left: 2.375rem;
  border-radius: 25px;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  top: 0;
  left: 0;
}

.sort-by {
  position: relative;
  display: inline-block;

  p {
    margin-bottom: 0px;
    margin-right: 8px;
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }

  .sort-by-button {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    color: #475467;
    cursor: pointer;

    p {
      color: #475467;
      font-size: 14px !important;
      font-weight: 400;;
    }
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;
    padding: 0;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    padding-bottom: 15px;

    li {
      padding: 8px 10px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;

      &:hover {
        background-color: #DFDFDF;
        transition: 0.3s;
      }
    }

  }

  &:hover .dropdown-menu {
    display: block;
  }
}


.task-list {
  h6 {
    font-size: 20px;
    color: #141944;
  }
}


.top-box {
  padding: 0 15px;
  justify-content: space-between;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
}


.total-students {
  display: flex;
}

.table-shadow {
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08)
}

.box-border {
  border: 2px solid #CFCFCF;
  border-radius: 12px;

}

.box-border-sm {
  border: 1px solid #E6E6E6;
}

.table-light {
  background: #FFFFFF;
  position: relative;


  thead {
    position: sticky;
    right: 0;
    left: 0;
    top: 0;
    width: 100%;

    th {

      border-color: #E3E3E3;
      background: #e8e8e8;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #141944;
      border-bottom: 1px solid #E3E3E3;

      &:last-child {
        padding-right: 75px !important;
      }
    }
  }

  tbody {
    tr {
      td {
        border-color: $tableLightBorderColor;

        h6 {
          font-weight: 500;
          font-size: 14px;
          color: #4575EE;
        }

        p {
          font-weight: 600;
          font-size: 14px;
          color: #475467;
          margin-bottom: 5px;
        }


        .btn-md {
          background-color: transparent;
          border: 1px solid grey;
          color: gray;
        }
      }

      &:hover {
        background-color: rgb(250, 250, 250);
      }
    }
  }
}

.assignment-submission {
  tbody {
    tr {
      td {
        .submission-status {
          font-size: 10px;
        }

        .submission-status.submitted {
          color: #30B264;
        }

        .submission-status.not-submitted {
          color: #FAA31A;
        }

        .submission-status.missed {
          color: red;
        }


        .fa-circle {

          //font-size: 10px;
          //color: #30B264;
        }

        .fa-clock {
          font-size: 15px;
          color: #30B264;
        }
      }
    }
  }
}


.mcq-submission-list {
  .btn-md {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;

    &:hover {
      border: 1px solid #4f4f4f;
      color: #4f4f4f;
      transition: 0.3s;
    }
  }
}

// Pagination
.page-navigation {
  padding: 0px 282px;
  display: flex;
  justify-content: center;

  .pagination {
    width: 630px;
    justify-content: space-between;

    .page-item {
      display: flex;
      align-items: center;

      .page-link {
        border: none;
        font-size: 14px;
        color: #8d8d8d;
        background-color: transparent;

        span {
          font-size: 20px;
        }
      }

      .page-active {
        font-weight: 600;
        color: #212121;
      }

    }
  }

}


caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

.rbt-token {
  background-color: #808299;
  border-radius: 25px;
  color: #ffffff;
  padding: 5px 20px;
  margin: 3px;
}

.rbt-token-active {
  background-color: #141944;
}

.rbt-input-multi.form-control {
  height: auto !important;
}

label {
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.custom-file-label {
  left: 0px !important;
  right: 15px !important;
  height: calc(1.5em + 0.75rem + 8px) !important;
  border-radius: 25px !important;
  padding: .53rem .75rem !important;
}

.custom-file-label:after {
  height: calc(1.5em + 0.75rem + 6px) !important;
  border-radius: 25px !important;
  padding: .53rem .75rem !important;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
  outline: 0px;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button {
  outline: 0px;
}

.btn-none {
  background: none;
  border: 0px;
  color: rgba(255, 255, 255, 0.5);
  outline: 0 !important;

  &:hover {
    color: #fff;
  }
}

.btn-none-default {
  background: none;
  border: 0px;
  color: #1c285a;
  outline: 0 !important;

  &:hover {
    opacity: 0.8;
  }
}

.btn-group-sm > .btn, .btn-sm {
  padding: 3px;
  font-size: 0.75rem;
  width: 30px;
  height: 30px;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #d6dbe3;
}


/*---Theme Custom
---------------------------------------------------*/

.c-purple {
  color: $purple !important;
}

.c-blue {
  color: $blue !important;
}

.c-red {
  color: $red !important;
}

.c-success-green {
  color: $successGreen !important;
}

.c-assesment-success {
  color: $assesmentSuccess !important;
}

.c-orange {
  color: $orange !important;
}

.c-light-orange {
  color: $lightOrange !important;
}

.c-deep-orange {
  color: $deepOrange !important;
}

.c-grey {
  color: #8a8b8c !important;
}

.middle-center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobile {
  display: none;
  @include xs {
    display: block;
  }
}

.desktop {
  display: block;
  @include xs {
    display: none;
  }
}

.school-bg {
  background-image: url("school_bg.png") !important;
  background-size: 710px;
  background-position: center;
  background-position-y: 108px;
  background-repeat: no-repeat;
  @include xs {
    background-size: 590px;
  }
}

.login-school-bg {
  background-image: url("school_bg.png") !important;
  background-size: 530px;
  background-position-y: 165px;
  background-position-x: center;
  background-repeat: no-repeat;
}

.bg-assignment-dark {
  background-color: #d9eef3 !important;
}

.bg-assignment-light {
  background-color: #F1FAFC !important;
}

.bg-event-dark {
  background-color: $orange !important;
}

.bg-event-light {
  background-color: $eventLight !important;
}

.bg-c-purple {
  background-color: $purple !important;
}

.bg-c-blue-light {
  background-color: $bg-c-blue-light;
}

.bg-c-orange-light {
  background-color: $bg-c-orange-light;
}

.bg-c-red-light {
  background-color: $bg-c-red-light;
}

.bg-c-dim-red {
  background-color: $bg-c-dim-red;
}


.bg-c-purple-light {
  background-color: $bg-c-purple-light;
}

.bg-c-light-grey {
  background-color: $bg-c-light-grey !important;
}

.bg-c-blue {
  background-color: $blue !important;
}

.bg-c-red {
  background-color: $red !important;
}

.bg-c-orange {
  background-color: $orange !important;
}

.bg-c-light-orange {
  background-color: $lightOrange !important;
}

.bg-c-light-green {
  background-color: $lightGreen !important;
}

.bg-c-deep-orange {
  background-color: $deepOrange !important;
}

.border-c-purple {
  border-color: $purple !important;
}

.border-c-blue {
  border-color: $blue !important;
}

.border-c-red {
  border-color: $red !important;
}

.border-c-orange {
  border-color: $orange !important;
}

.border-c-light-orange {
  border-color: $lightOrange !important;
}

.border-c-deep-orange {
  border-color: $deepOrange !important;
}


/* Radius
--------------------*/
.radius-10 {
  border-radius: 10px !important;
}

.radius-15 {
  border-radius: 15px !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.radius-25 {
  border-radius: 25px !important;
}

.radius-50-percent {
  border-radius: 50% !important;
}

/* widths
-----------*/
.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

/* Fonts
--------------------*/
f-30px {
  font-size: 30px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

/* Positions
--------------------*/
.right-0 {
  right: 0;
}

/* Alert boxes
--------------------*/
.alert-box-default {
  background-color: #f1f3f9;
  padding: 23px 20px;
  margin-bottom: 15px;
  border: 1px dashed #d2d8ea;
  border-radius: 6px;
}

/* Buttons
--------------------*/
.btn-light {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  height: auto;
  padding: 10px 12px;
}

.btn-dark {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  border-color: #071036;
  background: #071036;
  height: auto;
  padding: 10px 12px;
  line-height: 1.3;

  i {
    color: #FFFFFF;
  }

  &:hover {
    background: transparent;
    color: #071036;

    i {
      color: #071036;
    }
  }
}

.btn-outline-dark {
  font-size: 14px;
  color: #071036;
  border-color: #071036;
  height: auto;
  padding: 11px 12px;
  line-height: 1.3;

  i {
    color: #071036;
  }

  svg {
    color: #071036;
  }

  &:hover {
    background: #071036;

    i {
      color: #FFFFFF;
    }

    svg {
      color: #FFFFFF;
    }
  }
}

.btn-outline-dark-more {
  width: 100%;
  font-size: 14px;
  color: #071036;
  border: 1px dashed #07103661;
  height: calc(1.5em + 0.75rem + 16px);

  i {
    color: #071036;
  }

  &:hover {
    background: #071036;
    color: #FFFFFF;

    i {
      color: #FFFFFF;
    }
  }
}

.btn-outline-light {
  font-size: 14px;
  color: #FFFFFF;
  border-color: #FFFFFF;
  height: calc(1.5em + 0.75rem + 8px);

  i {
    color: #FFFFFF;
  }

  &:hover {
    i {
      color: #FFFFFF;
    }
  }
}

.btn-circle-blue {
  background-color: $blue;
  color: #ffffff;
  width: 38px;
  height: 38px;
  padding: 0px;
  border: 0px;
  border-radius: 50%;
  transition: 0.3s;

  &:hover {
    background-color: gray;
  }
}

.btn-circle-orange {
  background-color: $orange;
  color: #ffffff;
  width: 30px;
  height: 30px;
  padding: 0px;
  border: 0px;
  border-radius: 50%;
  transition: 0.3s;

  &:hover {
    background-color: gray;
  }
}

.btn-icon-assignment {
  background-color: transparent;
  padding: 0px;
  color: #3593a5;
  display: flex;
  align-items: center;
  border: 0px;

  i {
    font-size: 14px;
    margin-right: 5px;
  }

  p {
    font-size: 14px;
    margin: 0px !important;
    color: #3593a5 !important;
  }
}

.btn-red-lg {
  background-color: #ed161e;
  color: #ffffff;
  padding: 15px 25px;
  border: 0px;
  border-radius: 25px;
  width: 100%;
  transition: 0.3s;

  &:hover {
    background-color: gray;
  }
}

.btn-red-sm {
  background-color: #ed161e;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
  border: 0px;
  border-radius: 25px;
  transition: 0.3s;

  &:hover {
    background-color: gray;
  }
}

.btn-red-sm-bordered {
  background-color: transparent;
  color: #ed161e;
  border: 1px solid $red;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  transition: 0.3s;
  border-radius: 25px;

  a {
    color: #ed161e;
  }

  i {
    margin-right: 10px;
  }

  &:hover {
    background-color: $red;
    color: #FFFFFF;
  }
}

.btn-purple-sm {
  background-color: $purple;
  color: #ffffff;
  font-size: 12px;
  padding: 2px 10px;
  border: 0px;
  border-radius: 5px;
  transition: 0.3s;

  &:hover {
    background-color: gray;
  }
}

.btn-purple-sm-bordered {
  background-color: transparent;
  color: #4b5ead;
  border: 1px solid #4b5ead;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  border-radius: 5px;
  transition: 0.3s;

  a {
    color: #4b5ead;
  }

  i {
    margin-right: 5px;
  }

  &:hover {
    background-color: gray;
  }
}


.btn-red-md {
  background-color: #F7DEDE;
  color: #FE2E2E;
  padding: 5px 10px;
  transition: 0.3s;
  font-size: 13px;
  font-weight: 700;
  border: 0px solid #FE2E2E;
  border-radius: 25px;
  width: 11vh;

  &:hover {
    background-color: #fcebeb;
    color: #FE2E2E;
  }
}

.btn-purple-lg {
  background-color: $purple;
  color: #ffffff;
  padding: 15px 25px;
  border: 0px;
  width: 100%;
  transition: 0.3s;
  border-radius: 25px;

  &:hover {
    background-color: gray;
  }
}

.btn-blue-sm {
  background-color: $blue;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
  border: 0px;
  transition: 0.3s;
  border-radius: 25px;

  &:hover {
    background-color: gray;
  }
}

.btn-blue-sm-bordered {
  background-color: transparent;
  color: $blue;
  border: 1px solid $blue;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  transition: 0.3s;
  border-radius: 25px;

  a {
    color: $blue;
  }

  i {
    margin-right: 5px;
  }

  &:hover {
    background-color: $blue;
    color: #FFFFFF;
  }
}

.btn-blue-lg {
  background-color: $blue;
  color: #ffffff;
  padding: 15px 25px;
  border: 0px;
  width: 100%;
  transition: 0.3s;
  border-radius: 25px;

  &:hover {
    background-color: gray;
  }
}


.btn-orange-sm {
  background-color: $orange;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
  border: 0px;
  transition: 0.3s;
  border-radius: 25px;

  &:hover {
    background-color: gray;
  }
}

.btn-orange-sm-bordered {
  background-color: transparent;
  color: $orange;
  border: 1px solid $orange;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  transition: 0.3s;
  border-radius: 25px;

  a {
    color: $orange;
  }

  i {
    margin-right: 10px;
  }

  &:hover {
    background-color: $orange;
    color: #FFFFFF;
  }
}

.btn-orange-lg {
  background-color: $orange;
  color: #ffffff;
  padding: 15px 25px;
  border: 0px;
  width: 100%;
  transition: 0.3s;
  border-radius: 25px;

  &:hover {
    background-color: gray;
  }
}

.btn-deep-orange-lg {
  background-color: $deepOrange;
  color: #ffffff;
  padding: 15px 25px;
  border: 0px;
  width: 100%;
  transition: 0.3s;
  border-radius: 25px;

  &:hover {
    background-color: gray;
  }
}

.btn-light-orange {
  background-color: #FFF3E0;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;

  &:hover {
    background-color: #fcdbb4;
  }
}

.btn-light-purple {
  background-color: #DCCBFF;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;


  &:hover {
    background-color: #bdade5;
  }
}

.btn-light-blue {
  background-color: #E7EDFF;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;


  &:hover {
    background-color: #b4c7ff;
  }
}

.btn-light-green {
  background-color: #E2FFEF;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;


  &:hover {
    background-color: #a2d5a3;
  }
}

.btn-light-pink {
  background-color: #FFDDFA;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;


  &:hover {
    background-color: #e3aedb;
  }
}

.btn-light-grey {
  background-color: #EFEFEF;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;


  &:hover {
    background-color: #dddddd;
  }
}

.btn-light-cyan {
  background-color: #E0F9FF;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;


  &:hover {
    background-color: #b2edfd;
  }
}

.btn-light-red {
  background-color: #FFDBDD;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;


  &:hover {
    background-color: #ffb6b9;
  }
}

.btn-light-whitesmoke {
  background-color: #F1FFD5;
  color: #000000;
  border: 0px;
  transition: 0.3s;
  padding: 7px 20px;
  gap: 10px;
  height: 33px;
  border-radius: 30px;


  &:hover {
    background-color: #d0eea6;
  }
}


.btn-deep-blue-lg {
  background-color: #FF0505;
  color: #ffffff;
  padding: 10px 25px;
  width: 100%;
  transition: 0.3s;
  border: 2px solid #FF0505;
  border-radius: 50px;
  cursor: pointer;

  &:hover {
    border: 2px solid #1c285a;
    background-color: transparent;
    color: #1c285a;
  }
}

.btn-md {
  padding: 5px 20px;
  border: 0px;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid grey;
  color: gray;

  &:hover {
    color: black;
    background-color: #dedede !important;
  }
}

//Activity Calender

.activity-calender {
  padding: 1px;
  height: 88vh;
  margin-top: 0px;
  border-radius: 20px !important;

  .title {
    width: 100%;
    padding: 20px 40px 25px 40px;

    p {
      font-weight: 500;
      font-size: 18px;
      color: #475467;
      padding-left: 12px;
      margin-bottom: 0px;
    }
  }

  .calender-table {
    border-radius: 20px;
    border: 1px solid #E7E7E7;
    //border-bottom: none;
    margin: 0px 40px;
    height: 88%;
    //border-bottom-left-radius: 0px;
    //border-bottom-right-radius: 0px;


    .calender-header {
      display: flex;
      position: relative;
      align-items: center;
      margin: 20px 0px 18px 0px;

      .indicator-icons {
        position: absolute;
        right: 3%;
        display: flex;
        gap: 20px;
        align-items: center;

        .box-icon {
          display: flex;

          width: 18px;
          height: 19px;
          margin-right: 16px;
          border: none;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          color: #475467;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.activity-calender-body {
  height: inherit;
}

.calender-month {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 18vw;

  .calender-date {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }

  h3 {
    margin-bottom: 0px;
  }
}


/* MUI Custom */
.Component-iconContainer-2 .close {
  opacity: 0.5;
  color: #FFFFFF;
}

.jss2 .close {
  color: #FFFFFF;
}

.MuiPaper-root {
  background-color: #141944 !important;
  color: #FFFFFF !important;
  border-radius: 15px !important;
}

.MuiTableCell-body {
  color: rgba(255, 255, 255, 0.8) !important;
}

.MuiSvgIcon-root {
  color: gainsboro !important;
}

.MuiButton-root {
  color: rgba(255, 255, 255, 0.8) !important;
}

.MUIDataTableSelectCell-headerCell-41 {
  background-color: #141944 !important;
}

.MUIDataTableHeadCell-fixedHeader-51 {
  background-color: #141944 !important;
}

.MuiTypography-colorInherit {
  color: rgba(255, 255, 255, 0.8) !important;
}

/* Headings
--------------------*/
.left-icon-heading-sm {
  display: flex;
  align-items: center;

  i {
    margin-right: 7px;
  }

  p {
    margin: 0;
    font-weight: 700;
    font-size: 17px;
  }
}

/* Inputs
--------------------*/
.custom-file-input {
  height: 10px;
}

.input-default {
  width: 100%;

  &::placeholder {
    color: red;
    font-size: 12px;
  }

  .form-control {
    height: calc(1.5em + 0.75rem + 8px);
    font-size: 14px;
    border-radius: 25px;
    border: 0px solid #A3A9B3;
    background-color: #F0F2F5;
    opacity: 1;

    overflow: auto;
  }

  .block-group {
    .add-assets {
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        font-size: 20px;
        color: #1c285a;
        margin-right: 10px;
      }

      p {
        color: #1c285a;
        margin: 0px;
      }
    }
  }
}

//Grading Breakdown view
.grading-breakdown {
  padding: 0px 0px !important;
  height: 89vh;

  .title {
    padding: 20px 0px 20px 50px;
    border-bottom: 1px solid #C5C5C5;
    margin-bottom: 10px;

    p {
      font-weight: 600;
      font-size: 20px;
      color: #141944;
      margin-bottom: 0px;
    }

    h6 {
      p {
        margin: 0px 0px 0px 18px;
        font-size: 16px;
        font-weight: 500;
        color: #494949;
        background-color: #dbdbdb;
        border-radius: 20px;
        padding: 7px 10px;
      }
    }
  }
}


//Grading New
.grading-new {
  padding: 1px 0px !important;
  height: 89vh;

  .title {
    padding: 30px 0px 25px 65px;
    border-bottom: 1px solid #C5C5C5;
    margin-bottom: 10px;

    p {
      font-weight: 600;
      font-size: 20px;
      color: #141944;
      margin-bottom: 0px;
    }
  }

  .grading-table-edit {
    margin: 50px 50px 0px 50px;

    .grading-table {
      background-color: #F6F6F6;
      margin-top: 20px;

      .row {
        //border-top: 1px solid #E3E3E3;
        border-bottom: 1px solid #E3E3E3;
        margin: 0px;
      }

      .col-4 {
        text-align: center;

        .input-default-white {
          width: 100%;

          &::placeholder {
            color: red;
            font-size: 12px;
          }

          .form-control {
            height: calc(1.5em + 0.75rem + 0px);
            font-size: 16px;
            border-radius: 25px;
            border: 0px solid #e0e3e9;
            opacity: 1;
            background: #F6F6F6;
            overflow: auto;
            text-align: center;
            color: #475467;
            font-weight: 400;
          }
        }

        .form-group {
          margin: 10px 0px;

          p {
            color: #141944;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .text-center {
    button {
      width: 15%;
      text-align: center;
    }
  }

}

//Grading Evaluation
.grading-evaluation {
  padding: 1px 0px !important;
  height: 89vh;

  .title {
    padding: 30px 0px 25px 65px;
    border-bottom: 1px solid #C5C5C5;
    margin-bottom: 10px;

    p {
      font-weight: 600;
      font-size: 20px;
      color: #141944;
      margin-bottom: 0px;
    }
  }

  .grading-table-edit {
    margin: 50px 50px 0px 50px;

    .grading-table {
      background-color: #F6F6F6;
      margin-top: 20px;

      .row {
        //border-top: 1px solid #E3E3E3;
        border-bottom: 1px solid #E3E3E3;
        margin: 0px;
      }

      .col-4 {
        text-align: center;

        .input-default-white {
          width: 100%;

          &::placeholder {
            color: red;
            font-size: 12px;
          }

          .form-control {
            height: calc(1.5em + 0.75rem + 0px);
            font-size: 16px;
            border-radius: 25px;
            border: 0px solid #e0e3e9;
            opacity: 1;
            background: #F6F6F6;
            overflow: auto;
            text-align: center;
            color: #475467;
            font-weight: 400;
          }
        }

        .form-group {
          margin: 10px 0px;

          p {
            color: #141944;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .text-center {
    button {
      width: 15%;
      text-align: center;
    }
  }
}


.input-default-white {
  width: 100%;

  &::placeholder {
    color: red;
    font-size: 12px;
  }

  .form-control {
    height: calc(1.5em + 0.75rem + 8px);
    font-size: 14px;
    border-radius: 25px;
    border: 1px solid #a6a6a6;
    opacity: 1;
    background: #FFFFFF;
    overflow: auto;
  }

  .block-group {
    .add-assets {
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        font-size: 20px;
        color: #1c285a;
        margin-right: 10px;
      }

      p {
        color: #1c285a;
        margin: 0px;
      }
    }
  }

  .css-yk16xz-control {
    height: calc(1.5em + 0.75rem + 8px);
    font-size: 14px;
    border-radius: 25px;
    border: 1px solid #e0e3e9;
    opacity: 1;
    background: #FFFFFF;
    overflow: auto;
  }

  .css-1pahdxg-control {
    height: calc(1.5em + 0.75rem + 8px);
    font-size: 14px;
    border-radius: 25px;
    border: 1px solid #e0e3e9;
    opacity: 1;
    background: #FFFFFF;
    overflow: auto;
  }
}

.body-water-mark {
  margin-top: 4%;
  display: flex;

  i {
    font-size: 6rem;
    color: #1c285a;
    opacity: 0.1;
    margin-right: 10px;
  }

  h5 {
    width: 338px;
    opacity: 0.3;
    line-height: 1.4;
    font-size: 1rem;
    color: #1c285a;
  }
}

.bg-watermark {
  position: absolute;
  top: 100%;
  right: -16%;

  img {
    opacity: 0.035;
    width: 100%;
  }
}

.input-reg {
  width: 100%;

  label {
    margin-bottom: 6px;
    font-weight: 600;
  }

  .form-control {
    height: calc(1.5em + 0.75rem + 6px);
    opacity: 0.8;
    padding: 0.375rem 1.4rem;
    background-color: #FFFFFF;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  .block-group {
    .add-assets {
      display: flex;
      align-items: center;

      i {
        font-size: 20px;
        color: #1c285a;
        margin-right: 10px;
      }

      p {
        color: #1c285a;
        margin: 0px;
      }
    }
  }
}

.input-secondary {
  input {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    width: 100%;
    padding: 2px 8px;
    min-height: 52px;
  }

  input[type=text] {

  }

  input[type=select] {
    min-height: 52px;
    max-height: 52px;
  }

  input[type=radio] {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.list-group {
  margin-bottom: 18px;

  .list-group-item {
    background-color: #fbfbfb;
    border: 1px solid #d2d8f1;
    border-radius: 15px !important;

    &:hover {
      background-color: #f1f3f9;
      cursor: pointer;
    }

    h6 {

    }

    p {
      font-size: 12px;
      color: #bcc3da;
      margin: 0px;
    }

    i {
      font-size: 20px;
    }
  }
}

/* Blocks
--------------------*/
.block-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .block {
    width: calc(100% - 0px);
  }

  .block-10 {
    width: calc(10% - 0px);
  }

  .block-20 {
    width: calc(20% - 0px);
  }

  .block-25 {
    width: calc(25% - 0px);
  }

  .block-30 {
    width: calc(30% - 0px);
  }

  .block-33 {
    width: calc(33.33% - 0px);
  }

  .block-40 {
    width: calc(40% - 0px);
  }

  .block-50 {
    width: calc(50% - 0px);
  }

  .block-60 {
    width: calc(60% - 0px);
  }

  .block-70 {
    width: calc(70% - 0px);
  }

  .block-80 {
    width: calc(80% - 0px);
  }

  .block-90 {
    width: calc(90% - 0px);
  }

  @include sm {
    .block-10 {
      width: 100%;
    }

    .block-20 {
      width: 100%;
    }

    .block-25 {
      width: 100%;
    }

    .block-30 {
      width: 100%;
    }

    .block-33 {
      width: 100%;
    }

    .block-40 {
      width: 100%;
    }

    .block-50 {
      width: 100%;
    }

    .block-60 {
      width: 100%;
    }

    .block-70 {
      width: 100%;
    }

    .block-80 {
      width: 100%;
    }

    .block-90 {
      width: 100%;
    }
  }
}

//Opportunities - Promotions - Upcoming Events
.content-section{
  //margin: 50px 100px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0px !important;
  border: 1px solid #ddd;

  .title {
    width: 100%;
    padding: 26px 50px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;

    p {
      font-weight: 600;
      font-size: 19px;
      color: #475467;
      padding-left: 10px;
      margin-bottom: 0px;
    }
  }
  .content-box{

    height: 75vh;
    overflow-y: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    .content-card {
      margin-top: 10px;
      width: calc(100% - 0px);
      background-color: #f6f6f6;
      border-radius: 12px;
      border: 1px solid #e3e3e3;
      padding: 25px;
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.05);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      margin-bottom: 20px;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      h5 {
        font-size: 20px;
        font-weight: 600;
        color: #475467;
        text-align: center;
        margin-bottom: 15px;
      }

      .details {
        margin-bottom: 20px;

        .detail {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          strong {
            font-size: 16px;
            color: #475467;
            margin-right: 10px;
          }

          .icon {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }

          p {
            margin: 0;
            color: #555;
            font-size: 15px;
            flex-grow: 1;
          }
        }
      }

      .description {
        margin-bottom: 20px;

        h4 {
          font-size: 18px;
          color: #475467;
          font-weight: 600;
        }

        p {
          font-size: 15px;
          color: #666;
          line-height: 1.5;

          a {
            color: #007bff;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .view-more {
        display: inline-block;
        width: auto;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        border: none;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #e65c5c;
          color: white;
        }
      }
    }
  }

}

//Opportunity Page
.opportunity-card {
  max-width: 73vw;
  height: 86vh;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .back-button {
    color: #007bff;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-right: 8px;

    i {
      margin-right: 4px;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin: 0;
  }

  .opportunity-details-table {
    display: table;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin-top: 16px;

    .table-row {
      display: table-row;
      border-bottom: 1px solid #e0e0e0;

      &:last-child {
        border-bottom: none;
      }
    }

    .table-cell {
      display: table-cell;
      padding: 12px;
      vertical-align: middle;

      &.label-cell {
        font-weight: bold;
        color: #333;
        background-color: #f8f9fa;
        width: 40%;
        padding-left: 12px;
      }

      &.value-cell {
        color: #555;
        padding-left: 20px;
      }
    }
  }

  .opportunity-description {
    margin-top: 24px;

    h4 {
      font-size: 18px;
      color: #333;
      margin-bottom: 8px;
    }

    p {
      font-size: 16px;
      color: #666;
      line-height: 1.6;
    }
  }
}


/* Create Live Matches */

.create-live-matches {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px !important;
  display: flex;
  justify-content: start;
  flex-direction: column;
  .create-match-header{
    color: #FF2020;
    p{
      font-weight: 600;
      font-size: 24px;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    label {
      font-weight: bold;
      margin-bottom: 8px;
    }

    input,
    select,
    textarea {
      border: 0px solid #ccc;
      border-radius: 20px;
      padding: 8px 15px;
      background: #F0F0F0;
        &::placeholder {
          color: #969696;
        }
    }
  }

  .form-row {
    display: flex;
    gap: 16px;

    .form-group {
      flex: 1;
    }
  }

  .streamer-item {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .btn {
    border: none;
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 4px;

    &.btn-primary {
      background-color: #007bff;
      color: #fff;
      border-radius: 30px;
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  .btn-red-lg{
    padding: 10px 16px;
    margin-top: 12px;
    margin-right: 10px;
  }
}



/* Side Nav Area
--------------------*/
.sb-nav-fixed {
  #layoutSidenav {
    display: flex;


    #layoutSidenav_nav {
      width: 314px;
      height: 95vh;

      .sb-sidenav {
        position: fixed;
        top: 0;
        bottom: 0;
        transition: left 0.5s ease-in-out;
        z-index: 1;
        margin-top: 80px;

        .profile-menu {
          border: 1px solid #ddd;
          border-radius: 8px;
          padding: 20px;
          max-width: 300px;
          margin-bottom: 20px;
          background-color: #fff;
          align-items: center;
          .top-profile-section {
            padding-bottom: 15px;
            display: flex;
            align-items: flex-end;
            .profile-pic {
              border-radius: 50%;
              width: 90px;
              height: 90px;
              margin-right: 15px;
            }
            .profile-details {
              h6 {
                margin: 0;
                font-size: 1.25em;
                color: #333;
              }
              p {
                margin: 0;
                color: #777;
              }
            }
          }
          .profile-bio {
            p {
              font-size: 0.9em;
              color: #555;
              a {
                color: #007bff;
                text-decoration: none;
              }
            }
          }
          .profile-stats {
            border: 1px solid #FF0000;
            border-radius: 10px;
            padding: 10px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;

            .follower-following{
              text-align: center;
              width: 80%;
              margin-bottom: 10px;

              p{
                margin: 0;
                font-weight: 600;
              }
            }

          .social-media-links {
            .social-icon {
              margin: 0 5px;
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
          }
        }


        .sb-sidenav-menu {
          position: fixed;
          min-width: 300px;
          max-width: 300px;
          //height: 64vh;
          overflow-y: hidden;
          z-index: 2;
          border: 0.7px solid #E3E3E3;
          border-radius: 8px;
          background: #F6F6F6;

          &:hover {
            overflow: overlay;
          }
        }

        .brand {
          padding: 1.75rem 1.8rem 0.75rem;

          h4 {
            font-size: 1.7rem;
            font-weight: 600;
          }

          img {
            width: 180px;
          }
        }

        .nav {
          padding-left: 0px !important;
          justify-content: center;
          padding: 15px 0px;

          .sb-sidenav-menu-heading {
            font-size: 18px;
            font-weight: 700;
            text-transform: none;
            color: #1c285a;
            opacity: 1;
            padding: 1.75rem 1.6rem 1rem;
          }

          .nav-link {
            display: flex;
            padding: 0;
            margin: 0 12px;
            color: #182f46;
            border-radius: 6px;
            cursor: pointer;
            justify-content: center;

            &:hover {
              border-radius: 6px;
            }

            h6 {
              font-size: 16px;
              font-weight: 700;
              color: #071036;
              margin-bottom: 0;
            }

            button {
              margin-top: 0px;
              border-radius: 50px;
              padding-left: 10px;
              display: block;

            }

            a {
              color: black;
            }
          }
        }
      }
    }
  }
}


//Middling Homepage contents
.homepage {

  @media screen and (min-width: 1830px) {
    #layoutSidenav_nav {
      .sb-sidenav {
        position: fixed;
        left: 155px !important;
        border-top-left-radius: 20px;
      }
    }

    #contentArea {
      .block-center {
        width: calc(50% - 2vh) !important;
        margin: 0px 3% 0px 10% !important;
      }
    }

    #contentArea {
      .block-right {
        width: calc(29% - 8px);
        right: 125px !important;
      }
    }
  }

  @media screen and (min-width: 1930px) and (max-width: 2030px) {
    #layoutSidenav_nav {
      .sb-sidenav {
        position: fixed;
        left: 230px !important;
        border-top-left-radius: 20px;
      }
    }

    #contentArea {
      display: flex;
      justify-content: space-between;

      .block-center {
        width: calc(43% - 2vh) !important;
        margin: 0px 3% 0px 14% !important;
      }

      .block-right {
        width: calc(29% - 8px);
        right: 190px !important;
      }
    }
  }

  @media screen and (min-width: 2030px) and (max-width: 3030px) {
    #layoutSidenav_nav {
      .sb-sidenav {
        position: fixed;
        left: 14vw !important;
        border-top-left-radius: 20px;
      }
    }

    #contentArea {
      display: flex;
      justify-content: space-between;

      .block-center {
        width: calc(42% - 2vh) !important;
        margin: 0px 3% 0px 16% !important;
      }

      .block-right {
        width: calc(29% - 8px);
        right: 9vw !important;
      }
    }
  }


  @media screen and (min-width: 3031px) and (max-width: 3700px) {
    #layoutSidenav_nav {
      .sb-sidenav {
        position: fixed;
        left: 19vw !important;
        border-top-left-radius: 20px;
      }
    }

    #contentArea {
      display: flex;
      justify-content: space-between;

      .block-center {
        width: calc(35% - 2vh) !important;
        margin: 0px 3% 0px 21% !important;
      }

      .block-right {
        width: calc(22% - 8px);
        right: 18vw !important;
      }
    }
  }

  @media screen and (min-width: 3701px) and (max-width: 4500px) {
    #layoutSidenav_nav {
      .sb-sidenav {
        position: fixed;
        left: 20vw !important;
        border-top-left-radius: 20px;
      }
    }

    #contentArea {
      display: flex;
      justify-content: space-between;

      .block-center {
        width: calc(32% - 2vh) !important;
        margin: 0px 3% 0px 22% !important;
      }

      .block-right {
        width: calc(22% - 8px);
        right: 21vw !important;
      }
    }
  }

  @media screen and (min-width: 4501px) and (max-width: 4800px) {
    #layoutSidenav_nav {
      .sb-sidenav {
        position: fixed;
        left: 23vw !important;
        border-top-left-radius: 20px;
      }
    }

    #contentArea {
      display: flex;
      justify-content: space-between;

      .block-center {
        width: calc(28% - 2vh) !important;
        margin: 0px 3% 0px 25% !important;
      }

      .block-right {
        width: calc(20% - 8px);
        right: 24vw !important;

        .card-default-transparent #calender .block-group .block-25 .reminder p {
          font-size: 0.5vw;
          font-weight: 500;
          margin: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 4801px) {
    #layoutSidenav_nav {
      .sb-sidenav {
        position: fixed;
        left: 21vw !important;
        border-top-left-radius: 20px;
      }
    }

    #contentArea {
      display: flex;
      justify-content: space-between;

      .block-center {
        width: calc(30% - 2vh) !important;
        margin: 0px 3% 0px 23% !important;
      }

      .block-right {
        width: calc(21% - 8px);
        right: 23vw !important;
      }
    }
  }
}


/* Main Area
--------------------*/
.react-datepicker-popper {
  z-index: 111;
}

.sb-nav-fixed {
  #layoutSidenav {
    background-color: #ffffff;

    #layoutSidenav_content {
      width: 100%;
      min-height: calc(100vh - 0px);

      .content-wrap {
        padding-top: 80px;
        left: 0px;
        right: 0px;
        overflow: visible;
        position: relative;
        margin-left: 0px;

        main {
          h3 {
            font-size: 24px;
            font-weight: 600;
            color: #071036;
          }
        }
      }
    }
  }

  @include xs {
    #layoutSidenav {
      #layoutSidenav_content {
        padding-left: 0px;
        margin-left: 0px;

        .content-wrap {
          padding-top: 120px;
        }
      }
    }
  }
}

/* Grids
--------------------*/

.grid-fixed-1 {
  width: 100%;
  max-height: 40vh;

  img {
    position: relative;
    object-fit: cover;
    width: calc(100% - 0px);
    max-height: 40vh;
    margin-bottom: 4px;
    border-radius: 10px;
  }
}

.single-img-custom {
  height: 229px;
  overflow: auto;
  margin-bottom: 10px;

  .grid-fixed-1 {
    width: 100%;
    max-height: max-content;

    img {
      position: relative;
      object-fit: fill;
      width: auto;
      max-height: max-content;
      margin-bottom: 20px;
      border-radius: 15px;
    }

  }

}

.grid-even-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  img {
    position: relative;
    object-fit: cover;
    width: calc(50% - 2px);
    margin-bottom: 4px;
    border-radius: 5px;
  }
}

.grid-fixed-2 {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 5px;

  &:nth-child(1) {
    border-radius: 5px 0 0 5px;
  }

  &:nth-child(2) {
    border-radius: 0 5px 5px 0;
  }

  &:nth-child(n+3) {
    display: none;
  }

  img {
    object-fit: cover;
    width: 100%;
    margin-bottom: 4px;
  }
}

.grid-fixed-over-4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;

  div {
    img {
      position: relative;
      object-fit: cover;
      width: 100%;
      max-height: 300px;
      min-height: 300px;
      border-radius: 5px;

      &:nth-child(n+5) {
        display: none;
      }
    }

    &:nth-child(1) {
      border-radius: 5px 0px 0px 0px;
    }

    &:nth-child(2) {
      border-radius: 0px 5px 0px 0px;
    }

    &:nth-child(3) {
      border-radius: 0px 5px 5px 0px;
    }

    &:nth-child(4) {
      position: relative;
      border-radius: 0px 5px 5px 0px;
      filter: brightness(0.2);

      .count-overlay {
        position: absolute;
        top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
      }
    }

    &:nth-child(n+5) {
      display: none;
    }
  }

  .count-overlay {
    display: none;
  }
}

.grid-fixed-4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;

  div {
    img {
      position: relative;
      object-fit: cover;
      width: 100%;
      max-height: 300px;
      min-height: 300px;
      border-radius: 5px;

      &:nth-child(n+5) {
        display: none;
      }
    }

    &:nth-child(1) {
      border-radius: 5px 0px 0px 0px;
    }

    &:nth-child(2) {
      border-radius: 0px 5px 0px 0px;
    }

    &:nth-child(3) {
      border-radius: 0px 5px 5px 0px;
    }

    &:nth-child(4) {
      border-radius: 0px 5px 5px 0px;
    }

    &:nth-child(n+5) {
      display: none;
    }
  }
}

.grid-fixed-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;

  div {
    img {
      position: relative;
      object-fit: cover;
      width: 100%;
      max-height: 300px;
      min-height: 300px;
      border-radius: 5px;

      &:nth-child(n+4) {
        display: none;
      }
    }

    &:nth-child(1) {
      border-radius: 5px 0px 0px 0px;
      grid-area: 1 / span 2;
    }

    &:nth-child(2) {
      border-radius: 0px 5px 0px 0px;
    }

    &:nth-child(3) {
      border-radius: 0px 5px 5px 0px;
    }

    &:nth-child(n+4) {
      display: none;
    }
  }
}

//Curser Shows Pointer on Homep age Images
.w-100{
  img{
    cursor: pointer !important;
  }
}

/* Cards
--------------------*/
.card-default {
  padding: 15px 15px;
  margin-bottom: 15px;
  background-color: #FFFFFF;
  border-radius: 20px;
  border-color: none;


  .card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 10px -25px -14px -28px; */
    padding: 10px 0px;

    h4 {
      font-weight: 500;
      font-size: 20px;
      color: #475467;

      svg {
        margin-right: 8px;
      }
    }


    .title {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
        vertical-align: middle;
      }

      h6 {
        margin: 0px;
      }

      p {
        font-weight: 500;
        font-size: 18px;
        color: #475467;
        padding-left: 9px;
        margin-bottom: 0px;
      }
    }

    .nav {
      border-bottom: 0px solid #e0e0e0;
      margin-top: 20px;
      margin-bottom: 12px;
      box-shadow: RGB(255 255 255 / 10%) 0px 2px 0px 0px inset, RGB(50 50 93 / 25%) 0px 10px 31px -20px, RGB(0 0 0 / 30%) 0px 24px 2px -30px;
      padding: 0 14px;

      .nav-item {
        a {
          font-size: 14px;
          font-weight: 400;
          color: #878787;
          padding-left: 0px;
          padding-right: 0px;
          margin-right: 25px;
          padding-bottom: 8px;
        }
      }
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
      //border-bottom: 2px solid #c38c44;
      color: white;
      background-color: #E58F06;
      border-radius: 16px;
      padding: 8px 20px;
      //width: 110px;
      //height: 35px;
    }
  }

  @include xxlg {
    padding: 20px 20px;
    margin-bottom: 20px;
    .card-heading {
      .title {
        img {
          margin-right: 6px;
          width: 16px;
        }

        h6 {
          font-size: 14px;
        }
      }

      button {
        font-size: 10px;
      }
    }
  }

  @include sm {
    width: 100%;
  }
}


.card-default-transparent {
  padding: 0px 15px;
  background-color: transparent;
  border-radius: 6px;

  .card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 10px -25px -14px -28px; */
    padding: 0px 0px 20px 0px;
    border-bottom: 1px solid #E3E3E3;

    .title {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;

      }

      i {
        font-size: 22px;
        margin-right: 10px;
        vertical-align: middle;
        color: #1c285a;
        opacity: 0.4;
      }

      h6 {
        font-weight: 500;
        margin: 0px;
      }
    }

    //button {
    //  width: 46px;
    //  height: 46px;
    //  background: #F0F2F5;
    //  border-radius: 60px;
    //  border: 0;
    //  padding: 3px 7px;
    //  box-shadow: 0px 2px 2px 1px RGB(0 0 0/6%);

    button {
      border: 0;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      //width: 138px;
      height: 39px;
      background: #F0F2F5;
      border-radius: 60px;

      p {
        color: #475467;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 14px;
      }


      svg {
        margin-right: 7px;
      }


      i {
        color: #475467;
        margin-right: 7px;
      }

      &:hover i {
        transform: rotate(90deg);
        transition: 0.3s;

      }

      &:hover {
        background-color: #e3e5ee;
        transition: 0.3s;
      }
    }
  }

  @include xxlg {
    padding: 20px 20px;
    margin-bottom: 20px;
    .card-heading {
      .title {
        img {
          margin-right: 6px;
          width: 16px;
        }

        h6 {
          font-size: 14px;
        }
      }

      button {
        font-size: 10px;
      }
    }
    .circle-icon {
      align-items: center;
      background: #0066ff;
      border-radius: 50%;
      display: flex;
      height: 46px;
      justify-content: center;
      width: 46px;
    }
  }

  @include sm {
    width: 100%;
  }
}

.card-sm {
  padding: 18px 26px;
  margin-bottom: 25px;
  background-color: #FFFFFF;
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  -webkit-box-shadow: 0px 4px 6px 4px RGB(146 153 177 / 4%);
  box-shadow: 0px 4px 6px 4px RGB(146 153 177 / 4%);
}

.card-sm-dark {
  padding: 12px 20px;
  margin-bottom: 25px;
  background-color: #141944;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  -webkit-box-shadow: 0px 4px 6px 4px RGB(146 153 177 / 4%);
  box-shadow: 0px 4px 6px 4px RGB(146 153 177 / 4%);
}

.card-sm-dark-borderd {
  padding: 12px 20px;
  margin-bottom: 25px;
  background-color: #1c285a12;
  border: 1px solid #b0b2c5;
  border-radius: 20px;
  -webkit-box-shadow: 0px 4px 6px 4px RGB(146 153 177 / 4%);
  box-shadow: 0px 4px 6px 4px RGB(146 153 177 / 4%);
}

.card-alt {
  padding: 18px;
  margin-bottom: 30px;
  background-color: #F6F9FC;
  border-radius: 6px;
  //-webkit-box-shadow: 0px 4px 6px 4px rgb(146 153 177 / 4%);
  //box-shadow: 0px 4px 6px 4px rgb(146 153 177 / 4%);
  .card-alt-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gainsboro;
    margin: 0px -18px;
    margin-bottom: 24px;
    padding: 0px 18px;
    padding-bottom: 18px;

    .title {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
        vertical-align: middle;
      }

      h6 {
        margin: 0px;
        font-size: 16px;
        color: #475467;
      }
    }
  }
}

.qns-heading {
  border-radius: 10px;
  padding: 12px 18px;
  background: #1c285a;

  h6 {
    margin: 0rem;
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
    text-transform: capitalize;
  }
}

.ans-card {
  position: relative;
  padding: 15px 15px;
  margin-bottom: 16px;
  border-radius: 20px;
  background-color: #ffffff;
  width: max-content;

  .score {
    background: transparent;
    padding: 15px 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    border-radius: 20px 20px 0px 20px;
    text-align: center;
    float: unset;
    display: grid;
    grid-template-columns: 1fr 1fr;

    p {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0px;

      margin-right: 10px;
    }

    .state {
      display: flex;
      align-items: center;

      h4 {
        font-size: 32px;
        font-weight: 600;
        color: $blue;
        margin-right: 10px;
        margin-bottom: 0px;
      }
    }

    button {

      top: 22px;
      right: 22px;
      height: auto;
      width: auto;
      padding: 8px 11px;
    }
  }
}

/* ImageUploader
--------------------*/
.avatar {
  img {
    object-fit: cover;
    vertical-align: middle;
    width: 44px;
    height: 44px;
    border: 0px solid #f1f3f9;
    border-radius: 50%;
    font-size: 12px;
  }
}

.post-by {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .avatar {
    margin-right: 10px;

    img {
      width: 45px;
      height: 45px;
    }
  }

  .post-by-info {
    h6 {

      font-weight: 600;
      font-weight: 500;
      font-size: 18px;
      color: #141944;
    }

    p {
      font-size: 12px;
      color: #acb3c9;
      margin-bottom: 0px;
    }
  }

  .sort-by {
    position: relative;
    display: inline-block;
    margin-bottom: 25px;
    margin-right: 10px;

    p {
      margin-bottom: 0px;
      margin-right: 8px;
    }

    img {
      width: 15px;
      height: 15px;
    }

    .sort-by-button {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      color: #475467;
      cursor: pointer;
    }

    .dropdown-menu {
      display: none;
      position: absolute;
      top: 34%;
      left: -150px;
      list-style: none;
      padding: 0;
      background-color: #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

      li {
        padding: 8px 10px;
        cursor: pointer;
      }
    }

    &:hover .dropdown-menu {
      display: block;
    }
  }

}

.post-by-sm {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .avatar {
    margin-right: 10px;

    img {
      width: 35px;
      height: 35px;
    }
  }

  .post-by-info {
    h6 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0px;
    }

    p {
      font-size: 14px;
      color: #b3b3b3;
      margin-bottom: 0px;
    }
  }
}

.comment-area {
  display: flex;
  margin-top: 50px;

  .avatar {
    img {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
  }

  .card-alt {
    width: auto;
    min-width: 100px;
    margin-bottom: 0px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;

    h6 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    p {
      font-size: 12px;
      color: #949aa9;
      margin-bottom: 3px;
    }
  }
}

.add-comment-area {
  display: flex;
  align-items: center;

  .avatar {
    img {
      width: 44px;
      height: 44px;
      margin-right: 6px;
      border: 0px solid #f1f3f9;
    }
  }

  .input-default {
    width: 100%;
    position: relative;

    &::placeholder {
      color: red;
    }

    .form-control {
    }

    button {
      position: absolute;
      right: 5px;
      top: 5px;
      bottom: 5px;
      padding: 0px 9px;
    }

    .block-group {
      .add-assets {
        display: flex;
        align-items: center;

        i {
          font-size: 20px;
          color: #1c285a;
          margin-right: 10px;
        }

        p {
          color: #1c285a;
          margin: 0px;
        }
      }
    }
  }
}

/* Top Bar
--------------------*/
#topBar {
  .card-default {
    padding: 9px 40px 9px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    z-index: 2;
    border-radius: 0;
    box-shadow: 0px 1px 8px 1px RGB(28 40 90 / 5%);

    .bar-area {
      .block-group {
        .inline-block {
          &:nth-child(1) {
            display: flex;
            justify-content: start;
            align-items: center;

            .toggle-sidenav {
              margin-right: 15px;

              button {
                &:focus {
                  outline: 0px;
                  box-shadow: none;
                }

                img {
                  width: 24px;
                  vertical-align: middle;
                }
              }
            }

            .logo-icon {
              img {
                width: 90px;
              }
            }

            h5 {
              color: #071036;
              font-weight: 700;
              margin-left: 20px;
              margin-bottom: 0;
            }
          }

          &:nth-child(2) {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .notifications {
              position: relative;
              display: flex;
              justify-content: start;
              align-items: center;
              margin-right: 10px;

              .dropdown {
                i {
                  color: #1c285a;
                  font-size: 20px;
                }

                .dropdown-item {
                  display: flex;

                  i {
                    color: #fcb038;
                    font-size: 12px;
                    margin-right: 10px;
                    margin-top: 6px;

                  }
                }
              }

              img {
                margin: 0px;
                margin-right: 15px;
                width: 26px;
              }

              .notify-dot {
                padding: 0px;
                background-color: red;
                color: #ffffff;
                border-radius: 50%;
                line-height: 1.1;
                height: 19px;
                width: 19px;
                text-align: center;
                position: absolute;
                top: -7px;
                right: 23px;

                span {
                  margin: 0px;
                  padding: 0px;
                  font-size: 11px;
                }
              }

              .dropdown-menu {
                top: 38px !important;
                left: -312px !important;
                padding: 0px;
                width: auto;
                min-width: 400px;
                transform: unset !important;
                right: 0;
                background: #e6ecff;
                border-color: #d0d8f7;
                border-radius: 15px;
                -webkit-box-shadow: 0px 8px 16px 2px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 8px 12px 0px RGB(0 0 0 / 9%);

                .heading {
                  position: relative;
                  padding: 15px 20px;
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                  background-color: #f9f9f9;
                  border-bottom: 1px solid #e8e8e8;

                  h6 {
                    font-size: 14px;
                    font-weight: 500;
                    color: #8d95a2;
                    margin: 0px;
                  }

                  .arrow-top-light {
                    position: absolute;
                    top: -20px;
                    left: 43px;
                  }
                }

                a {
                  padding: 5px 18px;
                  font-size: 13px;
                  border-bottom: 1px solid #ededed;

                  p {
                    font-size: 12px;
                    margin: 0px;

                    a {
                      color: black;
                      padding: 0px;
                    }
                  }

                  .important {
                    font-weight: 400;

                    img {
                      width: 16px;
                      vertical-align: text-bottom;
                      margin-right: 6px;
                    }
                  }

                  .alert-dot {
                    background-color: red;
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    margin-right: 6px;
                    border-radius: 50%;
                  }

                  .brand-dot {
                    background-color: green;
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    margin-right: 6px;
                    border-radius: 50%;
                  }
                }

                .dropdown-divider {
                  margin: 0px;
                }

                button {
                  font-size: 14px;
                  font-weight: 500;
                  color: #304189;
                  padding-top: 0px;
                }

                .scroll-area {
                  margin-bottom: 20px;
                  padding: 20px 10px;
                }
              }
            }

            .has-search {
              width: 20vw;

            }
          }

          &:nth-child(3) {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .notifications {
              position: relative;
              display: flex;
              justify-content: start;
              align-items: center;
              margin-right: 10px;

              img {
                margin: 0px;
                margin-right: 15px;
                width: 26px;
              }

              .dropdown {
                button {
                  display: flex;
                  align-items: center;

                  .icon {
                    font-size: 20px;
                    margin-right: 10px;
                    width: 20px;
                  }
                  p {
                    color: #000000;
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 25px;
                    margin-bottom: 0;
                  }
                }
                .dropdown-item {
                  display: flex;

                  i {
                    color: #fcb038;
                    font-size: 12px;
                    margin-right: 10px;
                    margin-top: 6px;
                  }
                }
              }

              .bell-icon {
                display: inline-block;
                position: relative;

                .notify-dot {
                  padding: 0px;
                  background: #EB3535;
                  border: 0.5px solid #FFFFFF;
                  color: #ffffff;
                  border-radius: 50%;
                  line-height: 0.7;
                  height: 16px;
                  width: 16px;
                  text-align: center;
                  position: absolute;
                  top: -4px;
                  right: 3px;

                  span {
                    position: center;
                    font-size: 11px;
                    font-weight: 600;
                    color: #ffffff;
                  }
                }
              }

              .dropdown-menu {
                top: 25px !important;
                left: -312px !important;
                padding: 0px;
                width: auto;
                min-width: 400px;
                transform: unset !important;
                right: 0;
                background: #FFFFFF;
                border: 0;
                border-radius: 10px;
                -webkit-box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.2);

                .notification-badge {
                  padding: 6px 22px 6px 6px;
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #222222;
                  justify-content: space-between;

                  .btns {
                    display: flex;

                    button {
                      margin-left: 2px;
                      background-color: #E7ECF8;
                      border: none;
                      font-size: 16px;
                      font-weight: 500;
                      padding-right: 6px;
                      color: #000000;

                      span {
                        border-radius: 50%;
                        color: #E34343;
                      }
                    }

                    .unread-messages {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 10px;
                      font-weight: 400;
                      color: #8C8C8C;

                      p {
                        margin-bottom: 0px;
                      }
                    }
                  }


                  .mark-allread {
                    display: flex;
                    align-items: center;

                    p {
                      font-size: 10px;
                      margin-bottom: 0;
                    }

                    .my-svg {
                      width: 10px;
                      height: 6px;
                      margin-right: 4px;
                    }
                  }

                }

                h6 {
                  text-align: left;
                  padding-left: 30px;
                  font-weight: 600;
                  padding-top: 15px;
                  padding-bottom: 15px;
                  border-radius: 10px 10px 0px 0px;
                  background: #FFFFFF;
                  margin-bottom: 0;
                  border-bottom: 2px solid #000000;
                }

                .heading {
                  position: relative;
                  padding: 15px 20px;
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                  background-color: #f9f9f9;
                  border-bottom: 1px solid #e8e8e8;

                  h6 {
                    font-size: 14px;
                    font-weight: 500;
                    color: #8d95a2;
                    margin: 0px;
                  }

                  .arrow-top-light {
                    position: absolute;
                    top: -20px;
                    left: 43px;
                  }
                }

                .dropdown-divider {
                  margin: 0px;
                }

                .all-button-area {
                  padding: 11px 20px 13px 20px;

                  .all-button {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #ededed;
                    padding: 8px 10px;
                    border-radius: 10px;
                    border: 0;

                    &:hover {
                      background: gainsboro;
                    }
                  }
                }

                .scroll-area {
                  max-height: 500px;
                  border-radius: 0;

                  .notification-block {
                    background-color: #EFEFEF;
                    padding: 18px;
                    padding-top: 12px;
                    padding-bottom: 0px;

                    .notify-content {
                      display: flex;
                      align-items: center;
                      border-bottom: 1px solid #cacaca;
                      padding-bottom: 12px;

                      .circle-icon {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background: #E3E3E3;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                          width: 20px;
                          height: 20px;
                          margin-right: 0px !important;
                        }
                      }

                      .desc {
                        margin-left: 10px;

                        h4 {
                          margin: 0;
                          font-size: 14px;
                          margin-bottom: 4px;
                        }

                        p {
                          margin: 0;
                          font-size: 10px;
                          color: #232323;
                        }
                      }

                      .notification-time {
                        p {
                          font-size: 10px;
                        }
                      }

                    }
                  }

                  .read-notification-block {
                    background-color: #FFFFFF;
                    padding: 18px;
                    padding-top: 12px;
                    padding-bottom: 0px;
                    transition: background-color 0.3s ease;

                    &:hover {
                      background-color: #EFEFEF;

                    }

                    .notify-content {
                      display: flex;
                      align-items: center;
                      border-bottom: 1px solid #cacaca;
                      padding-bottom: 12px;

                      .circle-icon {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background: #E3E3E3;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                          width: 20px;
                          height: 20px;
                          margin-right: 0px !important;
                        }
                      }

                      .desc {
                        margin-left: 10px;
                        width: fit-content;
                        opacity: 0.5;

                        h4 {
                          margin: 0;
                          font-size: 14px;
                          margin-bottom: 4px;
                        }

                        p {
                          margin: 0;
                          font-size: 11px;
                          color: #000000;
                          font-weight: 600;
                        }
                      }

                    }
                  }
                }
              }
            }

            .profile-dropdown {
              display: flex;
              align-items: center;

              .avatar {
                margin-right: 3px;

                img {
                  width: 35px;
                  height: 35px;
                }
              }

              .dropdown {
                a {
                  color: #000000;
                  font-size: 13px;
                  font-weight: 600;
                }

                .dropdown-menu {
                  top: 12px !important;
                  right: -1px !important;
                  left: auto !important;
                  padding: 1rem 0;
                  background-color: #ffffff;
                  border: 1px solid RGB(207 211 224/38%);
                  box-shadow: 0px 4px 15px RGB(0 0 0/8%);
                  border-radius: 6px;

                  &:before {
                    content: "";
                    position: absolute;
                    right: 61px;
                    left: 300px;
                    page-break-after: always;
                    top: -12px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 15px 15px 15px;
                    border-color: transparent transparent #eefbfd transparent;
                    z-index: 9999;
                  }

                  .dropdown-item {
                    padding: 0.5rem 1.5rem;
                    font-size: 14px;
                    img{
                      margin-right: 5px;
                      width: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include xxlg {
    .card-default {
      .bar-area {
        .block-group {
          .inline-block {
            &:nth-child(1) {
              h5 {
                font-size: 18px;
              }
            }

            &:nth-child(2) {
              .notifications {
                img {
                  width: 15px;
                }
              }

              .profile-dropdown {
                .avatar {
                  img {
                    width: 32px;
                    height: 32px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.notifications {
  .dropdown-responsive {
    min-height: 60vh;
    max-height: 85vh;
    width: auto;
    min-width: 350px !important;
    overflow-y: scroll;
    overflow-x: hidden;
    //display: flex;

    .sidenav-last-child {
      width: 300px;
      padding-left: 20px;

      .sidenav-border {
        .dropdown-sidenav {
          .d-inline-flex {
            .fw-700 {
              padding-left: 0px !important;
            }
          }
        }

        .dropdown-sidenav {
          span {
            width: 20vw;
          }
        }
      }
    }

  }

  .dropdown-menu {
    h6 {
      border: 0px solid white !important;
    }
  }
}


//Edit Profile
.profile-edit{
  background: linear-gradient(149deg, #ffffff 0%, #FFF0F0 100%);
  margin: -50px;
  .card-default{
    margin: 50px 100px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    padding: 0px;
    width: 70%;
    .card-heading{
      background: linear-gradient(270deg, #FFF5F5 0%, #FFE9E9 45%, rgba(255, 199, 199, 0) 100%);
      height: 8vh;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 0px 20px;
      .btn-red-lg{
        background-color: #ed161e;
        padding: 10px 45px;
        &:hover{
          background-color: #ffb8bb;
        }
      }
    }
    .profile-container {
      padding: 20px;

      .profile-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .profilepic-section{
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .profile-pic {
            position: relative;
            width: 250px;
            height: 250px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 15px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .upload-photo {
            text-align: center;

            input[type="file"] {
              display: none;
            }

            .custom-file-label {
              position: relative !important;
              background-color: #ffb8bb;
              border-radius: 20px;
              padding: 5px 10px;
              cursor: pointer;
              font-size: 14px;
              color: #fff;
              display: inline-block;
              margin-bottom: 10px;

              &:hover {
                background-color: #ed161e;
              }
            }

            .upload-btn {
              background-color: #ed161e;
              color: #fff;
              border: none;
              border-radius: 20px;
              padding: 5px 10px;
              cursor: pointer;

              &:hover {
                background-color: #ffb8bb;
              }

              .fa-upload {
                margin-right: 5px;
              }
            }
          }
        }


        .profile-info {
          flex-grow: 1;
          margin-left: 20px;

          .profile-name {
            h2 {
              margin: 0;
              font-size: 24px;
            }
            p {
              margin: 5px 0 0;
              color: #777;
            }
          }

          .profile-bio {
            margin-top: 10px;
            p {
              margin: 0;
              color: #555;
            }
          }
        }
      }

      .profile-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .detail-item {
          label {
            font-weight: bold;
            margin-bottom: 5px;
            display: block;
          }
          p {
            margin: 0;
            background-color: #f9f9f9;
            padding: 10px;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .btn-red-md{
    background-color: #00ce66;
    color: white;
    &hover{
      background-color: #9dffcd;
    }
  }
}



//Follower Following

.follower-following {

  .card-default {
    margin: 50px 100px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    padding: 0px;
    width: 70%;

    .card-heading {
      background: linear-gradient(270deg, #FFF5F5 0%, #FFE9E9 45%, rgba(255, 199, 199, 0) 100%);
      height: 8vh;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 0px 20px;

      .title {
        p {
          margin: 0;
          font-size: 20px;
          font-weight: bold;
          color: #333;
        }
      }
    }
  }

  .tab-blue {
    padding: 15px;
    .tabs {
      .nav-pills .nav-link {
        color: #555;
        &.active {
          background-color: #ed161e;
          color: #fff;
        }
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .follower-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.09);
    display: flex;
    align-items: center;

    .avatar {
      width: 50px;
      height: 50px;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      margin-left: 15px;
      h5 {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
      }
      p {
        margin: 0;
        font-size: 14px;
        color: #888;
      }
    }
  }
}


/* Live Matches Page */
.live-matches {
  width: 76%;
  overflow: hidden;

  .left-section-heading{
    justify-content: flex-end;
    gap: 20px;
    position: absolute;
    right: 0px;
    .btn-red-lg{
      width: 230px;
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .form-group{
      width: 100%;
    }
  }
  .row {
    display: flex;
    gap: 1px;
    margin-top: 40px;

    .upcoming-matches-section {
      display: flex;
      width: 78vw;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      gap: 1rem;

      .match-card {
        width: 100%;
        max-width: 300px;

        .card {
          border: none;
          box-shadow: none !important;
          border-radius: 12px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;

          .card-body {
            display: flex;
            margin-top: 0px !important;
            padding: 15px 15px 15px 15px;

            .thumbnail-image {
              width: 270px;
              height: 150px;
              overflow: hidden;
              border-radius: 12px 12px 12px 12px;
              border: 1px solid #e6e6e6;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .match-info {
              padding: 5px 0px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .card-title {
                font-size: 1.1rem;
                font-weight: bold;
                color: #333;
                margin-bottom: 0.5rem;
              }

              .card-text {
                color: #777777;
                font-size: 0.9rem;
                display: flex;
                align-items: center;

                &:not(:last-child) {
                  margin-bottom: 0.2rem;
                }
              }
              .details-btn {
                margin-top: 10px;
                padding: 8px 15px;
                font-size: 0.875rem;
                font-weight: 600;
                background-color: #ed161e;
                border: none;
                border-radius: 4px;
                color: white;
                transition: background-color 0.3s ease;

                &:hover {
                  background-color: darken(#ed161e, 10%);
                }
              }
              .location {
                display: flex;
                align-items: center;

                .location-icon {
                  width: 25px;
                  height: 25px;
                  margin-right: 4px;
                }
              }
            }
          }
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .previous-matches-section {
      display: flex;
      width: 100vw;
      overflow-x: auto;
      gap: 1rem;

      .match-card {

        .card {
          border-radius: 12px;
           .card-body {
             display: flex;
             margin-top: 0px !important;
             padding: 0px 30px 0px 15px;
             justify-content: space-between;
             gap: 25px;
                  .thumbnail-image {
                    width: 330px;
                    height: 200px;
                    overflow: hidden;
                    border-left: 1px solid #e6e6e6;
                    border-right: 1px solid #e6e6e6;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                  .match-info {
                    padding: 5px 0px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    min-width: fit-content;

                    .card-title {
                      font-size: 1.1rem;
                      font-weight: bold;
                      color: #333;
                      margin-bottom: 0.5rem;
                    }

                    .card-text {
                      color: #777777;
                      font-size: 0.9rem;
                      display: flex;
                      align-items: center;

                      &:not(:last-child) {
                        margin-bottom: 0.2rem;
                      }
                    }
                    .location {
                      display: flex;
                      align-items: center;

                      .location-icon {
                        width: 25px;
                        height: 25px;
                        margin-right: 4px;
                      }
                    }
                    .details-btn {
                      padding: 8px 2px;
                      font-size: 0.875rem;
                      font-weight: 600;
                      background-color: #ed161e;
                      border: none;
                      border-radius: 50px;
                      color: white;
                      transition: background-color 0.3s ease;
                      display: flex;
                      justify-content: center;
                      gap: 5px;
                      align-items: center;
                      width: 145px;
                      &:hover {
                        background-color: darken(#ed161e, 10%);
                      }
                    }
                  }
                }
              }
            }
            &::-webkit-scrollbar {
              display: none;
            }
      /* Style the scrollbar for webkit browsers (like Chrome and Safari) */
      &::-webkit-scrollbar {
        height: 8px;  // Set the height of the horizontal scrollbar
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;  // Background of the scrollbar track
      }

      &::-webkit-scrollbar-thumb {
        background: #888;  // Color of the scrollbar thumb
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;  // Color of the scrollbar thumb when hovered
      }
          }

          .matches-section {
            padding: 15px;

            .section-header {
              margin-bottom: 20px;

              h2 {
                font-size: 1.5rem;
                font-weight: bold;
                color: #ed161e;
              }
            }

            .match-card {
              margin-bottom: 20px;
              padding-left: 0px;

              .card {
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                    }
                  }
                }
              }

              @media (max-width: 768px) {
                .row {
                  flex-direction: column;

                  .matches-section {
                    width: 100%;
                  }
                }
        }
}

/* Live Stream Watch page*/

.livestream-container {
  padding: 0;
  width: 88%;

  // For screens that are exactly or above 1440px
  @media screen and (min-width: 1440px) and (max-width: 1800px) {
    width: 98%;
  }

  .livestream-row {
    display: flex;
    margin: 0;
    height: 88vh;
    border: 1px solid #cccccc;
    border-radius: 8px;
    overflow: scroll;
    overflow-x: hidden;
  }

  .livestream-sidebar {
    background-color: #ffffff;
    padding: 20px 0px 20px 0px;
    overflow-y: auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    &-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
      text-align: center;
    }

    .livestream-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }

    .livestream-thumbnail-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      width: 100%;
      &:hover {
        background-color: #e9ecef;
      }

      .livestream-thumbnail {
        width: 200px;
        height: 100px;
        overflow: hidden;
        border-radius: 8px;
        flex-shrink: 0;
        border: 1px solid #e0e0e0;
        margin-bottom: 10px;

        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .livestream-info {
        margin-left: 10px;
        .livestream-title {
          font-size: 16px;
          font-weight: 500;
          margin: 0;
        }
        .livestream-location {
          font-size: 14px;
          color: #555;
          margin: 0;
        }
      }
    }
    .match-detail{
      margin: 30px 20px;
      h5{

      }
      p{
        font-size: 16px;
        color: #757575;
        margin-bottom: 0px;
      }
    }
  }

  .livestream-main-player {
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .livestream-main-title {
      font-size: 28px;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
      background: #ffffff;
      padding: 15px;
      border-top-right-radius: 8px;
      border-bottom: 1px solid #c2c2c2;
    }

    .livestream-video-container {
      width: 100%;
      max-width: 720px;
      margin-top: 80px;
      .livestream-video {
        width: 100%;
        height: auto;
        border-radius: 8px;
        border: 1px solid #ccc;
      }

      .livestream-details {
        text-align: center;
        margin-top: 10px;

        .livestream-video-title {
          font-size: 18px;
          font-weight: 600;
        }

        .livestream-video-location {
          font-size: 14px;
          color: #555;
        }
      }
    }
    //.homepage{
    //  overflow: scroll;
    //  overflow-x: hidden;
    //  max-height: 100%;
    //}
  }
}


/* Stream Player Page */

.video-player-container {
  max-width: 950px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ff6d6d;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  height: 78vh;

  .video-overlay {
    position: absolute;
    top: 20px;
    left: 22px;
    z-index: 2;
    background: rgb(255 255 255 / 31%);
    padding: 8px 12px;
    color: #333;
    font-size: 1.5rem;
    font-weight: 600;
    transition: background 0.3s ease;

    &:hover {
      background: rgba(240, 240, 240, 0.8);
    }
    .video-title{
      font-size: 16px;
      font-weight: 600;
    }
  }

  .video-player {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    border: 2px solid #e9ecef;
  }

  .comment-section {
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);

    .comment-title {
      color: #495057;
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .comment-input {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;
      .comment-avatar-input {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .comment-input-field {
        flex: 1;
        padding: 10px;
        border: 1px solid #ced4da;
        border-radius: 8px;
        background: #ffffff;
        color: #495057;
        outline: none;
        transition: border-color 0.3s;

        &:focus {
          border-color: #ffa0a0;
          box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
        }
      }

      .comment-submit {
        padding: 10px 20px;
        background: #ed161e;
        color: #ffffff;
        border: none;
        border-radius: 8px;
        font-weight: bold;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: #b4131b;
        }
      }
    }

    .comments-list {
      .comment {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e9ecef;
        padding: 10px 0;
        .comment-background{
          background-color: #f9f9f9;
          border-radius: 8px;
          padding: 5px;

          &:hover{
            background-color: #f1f1f1;
          }
        .comment-header {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 5px;

          .comment-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }

          .comment-info {
            .comment-name {
              font-weight: bold;
              margin-right: 10px;
            }

            .comment-timestamp {
              color: #6c757d;
              font-size: 12px;
            }
          }
        }

        .comment-text {
          margin-bottom: 10px;
          font-size: 14px;
          color: #212529;
          margin-left: 3px;
        }
      }

        .comment-actions {
          display: flex;
          align-items: center;
          gap: 15px;
          font-size: 14px;
          color: #007bff;
          margin: 5px 0px 0px 6px;

          .like-button {
            background: none;
            border: none;
            color: #007bff;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .like-count {
            color: #6c757d;
          }
        }
      }

      .no-comments {
        text-align: center;
        color: #6c757d;
        font-style: italic;
        margin-top: 10px;
      }
    }
  }
}



 /* Content Area--------------------*/
#contentArea {
  display: flex;

  .card-default {

  }

  .block-center {
    width: calc(64% - 2vh);
    margin: 0px 3% 0px 1%;
    padding-top: 0px;
    background-color: transparent;
    transition: width 0.5s ease-in-out, margin 0.5s ease-in-out;
    .story-section {
      display: flex;
      overflow-x: auto;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 10px;
      margin-bottom: 20px;

      .story {
        margin-right: 10px;
        text-align: center;

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 2px solid #ddd;
        }

        p {
          margin-top: 5px;
          font-size: 0.9em;
          color: #333;
        }
      }
    }
    .add-post-area {

      .top-section {
        border-bottom: 1px solid #E6E6E6;
        padding: 15px;
        align-items: center;


        .form-control {
          height: 44px;
          background: #f6f6f6;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 59px;
          overflow: hidden;
          padding: 8px 0px 0px 10px;

          &:hover {
            overflow: overlay;
          }
        }
      }


      .input-default {
        width: 100%;
        width: 100%;
        padding: 5px 15px 15px 15px;
        background-color: #F6F6F6;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        &::placeholder {
          color: red;
        }

        .block-group {
          .block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 5px;

            .add-assets {
              display: flex;
              align-items: center;
              margin-bottom: 0;

              .box-icon {
                width: 100%;
                margin-right: 30px;
                img{
                  width: 20px;
                  margin-right: 5px;
                }
                p {
                  font-weight: 500;
                  font-size: 14px;
                  color: #6f6f6f;
                  margin: 0px;
                }
                .screen-share{
                  width: 94px;
                }
              }


              i {
                font-size: 20px;
                color: #1c285a;
                margin-right: 10px;
              }


            }

            .submit-area {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              //width: 150px;
              height: 34px;
              color: #1c285a;

              p {
                margin-bottom: 0px;
                margin-right: 10px;
                color: #1c285a;
                font-size: 14px;
              }

              button {
              }
            }
          }
        }
      }
    }

    .comment-area {
      display: flex;
      margin-top: 50px;

      .avatar {
        img {
          margin-right: 15px;
        }
      }

      .card-alt {
        width: 100%;
        h6 {
          font-weight: 600;
          margin-bottom: 15px;
        }

        p {
          font-size: 14px;
        }
      }
      span{
        font-size: 13px;
        color: #a1a1a1;
        margin: 0px 0px 0px 8px;
      }
      .comment-delete-btn{
        font-size: 12px;
        padding: 0px 10px !important;
        p{
          font-weight: 600 !important;
          font-size: 13px !important;
          color: #838383 !important;
          &:hover{
            color: #003256 !important;
            transition: 0.3s;
          }
        }
      }
    }

    .add-comment-area {
      display: flex;

      .input-default {
        width: 100%;
        display: flex;

        &::placeholder {
          color: red;
        }

        .form-control {

        }

        button {
          font-weight: 500;
          font-size: 12px;
        }

        .block-group {
          .add-assets {
            display: flex;
            align-items: center;

            i {
              font-size: 20px;
              color: #1c285a;
              margin-right: 10px;
            }

            p {
              color: #1c285a;
              margin: 0px;
            }
          }
        }
      }
    }

    .view-post-area {
      .post-content {
        h5 {
          font-weight: 600;
          margin-bottom: 15px;
        }

        p {
          margin-bottom: 5px;
          font-weight: 500;
          font-size: 14px;
          color: #484848;
        }

        .thumb-lg {
          width: 100%;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            vertical-align: middle;
            border-radius: 5px;
          }
        }

        .post-by {
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px;
          margin-top: 12px;
          padding-right: 10px;

          .avatar {
            margin-right: 10px;
          }

          .post-by-info {
            h6 {

              font-weight: 500;
              font-size: 18px;
              color: #141944;
            }

            p {
              font-weight: 400;
              font-size: 11px;
              color: #878787;
              margin-bottom: 0px;
            }
          }
          .dropdown {
            cursor: pointer;
           .dropdown-menu{
             border-radius: 8px;
             box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.19);
             border: 0px;
             .dropdown-item {
               padding: 6px 13px;
               &:hover {
                 color: #ff0000;
                 text-decoration: none;
                 background-color: #f8f9fa;
               }
             }
           }

          }
          .img-icon{
            width: 4px;
          }
        }
      }

      .post-status {
        font-size: 12px;
        font-weight: 600;
        color: #323232;
        margin-top: 12px;
        margin-bottom: 10px;
      }

      .like-comment-share {
        display: flex;
        justify-content: space-between;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px 0px;
        border-bottom: 1px solid #E6E6E6;
        border-top: 1px solid #E6E6E6;

        .box {
          display: flex;
          align-items: center;
          margin-right: 0px;
          background: #FFFFFF;
          padding: 4px 3px;
          box-shadow: 0px 3px 3px 0px RGB(146 153 177 / 5%);
          border-radius: 2px;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            background: #e9eff7;
          }
          .liked-lovereact{
            width: 24px;
          }
          .unliked-lovereact{
            width: 24px;
          }
          .comment-icon{
            width: 22px;
          }
          .share-icon{
            width: 18px;

          }

          p {
            font-weight: 400;
            font-size: 16px;
            color: #949aa9;
            margin: 0px;

            b {
              color: black;
            }
          }
        }

        .comment-count {
          display: flex;
          align-items: center;
          background: #FFFFFF;
          padding: 5px 10px;
          justify-content: center;
          box-shadow: 0px 3px 3px 0px RGB(146 153 177 / 5%);
          border-radius: 2px;
          cursor: pointer;
          transition: 0.3s;

          p {
            font-weight: 400;
            font-size: 16px;
            color: #475467;

            b {
              font-weight: 400;
              font-size: 16px;
              color: #475467;
            }
          }

          &:hover {
            background: #e9eff7;
          }

          img {
            width: 28px;
          }
        }
      }

      .post-status {
        cursor: pointer;
      }

      .comment-area {
        display: flex;
        margin-top: 0px;
        background: transparent;
        padding: 8px 10px;
        padding-bottom: 0px;
        border-radius: 15px;

        &:first-child {
          padding-top: 10px;
        }

        .avatar {
          z-index: 1;

          img {
            margin-right: 8px;
            padding: 3px;
            background: #FFFFFF;
          }
        }

        .card-alt {
          display: block;
          width: auto;
          min-width: 100px;
          padding: 7px 16px;
          background-color: #f0f2f5;
          border-radius: 12px;

          h6 {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 5px;
          }

          p {
            font-size: 12px;
            color: #000000;
            margin-bottom: 0px;
          }
        }
      }
    }

    .infinite-scroll-component {
      overflow: visible !important;
    }
  }

  .block-right {
    position: fixed;
    right: 30px;
    padding-top: 0px;
    overflow: scroll;
    transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
    &:hover {
      overflow: scroll;
    }

    .card-default-transparent{
      .rightbar-card {
        background-color: #F6F6F6;
        border-radius: 8px;
        border: 1px solid #E3E3E3;
        padding: 15px 20px 19px 20px;
        margin-bottom: 20px;
        max-width: 350px;
        h5{
          font-size: 18px;
          font-weight: 600;
          color: #475467;
        }
        .details {
          margin-bottom: 15px;
          .detail {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            strong {
              font-size: 14px;
              color: #475467;
            }
            .icon {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }

            p {
              margin: 0;
              color: #555;

            }
          }
        }
        .description {
          margin-bottom: 15px;
          h4 {
            font-size: 16px;
            margin-bottom: 5px;
            color: #475467;
            font-weight: 600;
          }
          p {
            font-size: 0.9em;
            color: #555;
            a {
              color: #007bff;
              text-decoration: none;
            }
          }
        }
        .view-more {
          display: block;
          width: 50%;
          padding: 10px;
          text-align: center;
          cursor: pointer;
        }
      }

    }

  }



  @media only screen and (max-width: 1674px) {
    .block-center {
      width: calc(64% - 3vh);
      margin: 0px 3% 0px 2%;
    }
    .block-right {
      //width: calc(27% - 0px);
    }
  }
  @include xxlg {
    .block-center {
      width: calc(64% - 0px);
      margin: 0px 3% 0px 2%;

      .add-post-area {
        .input-default {
          .form-control {

          }

          .block-group {
            .block {
              .add-assets {
                i {
                }

                p {
                  font-size: 12px;
                }
              }

              .submit-area {
                p {
                  font-size: 12px;
                }

                button {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .view-post-area {
        .post-content {
          h5 {
          }

          p {
            font-size: 12px;
          }

          .thumb-lg {
            img {
            }
          }

          .post-by {
            .avatar {
            }

            .post-by-info {
              h6 {
                font-size: 14px;
              }

              p {
              }
            }
          }
        }

        .post-status {
          font-size: 12px;
          font-weight: 600;
          color: #323232;
          margin-top: 25px;
          margin-bottom: 10px;
        }

        .like-comment-share {
          .box {
            &:hover {
            }

            img {
            }

            p {
              font-size: 12px;

              b {
              }
            }
          }

          .comment-count {
            &:hover {
            }

            img {
              width: 16px;
            }
          }
        }

        .comment-area {
          &:last-child {
          }

          .avatar {
            z-index: 1;

            img {
            }
          }

          .card-alt {
            h6 {
              font-size: 12px;
            }

            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  @include xxl {
    .block-group {
      .block-70 {
      }

      .block-30 {
      }
    }
  }



}

@media (max-width: 1317px) {
  #contentArea {
    width: 96vw;

    .block-center {
      width: calc(67% - 3vh);
      margin: 0px 0% 0px 0%;
    }

    .block-right {
      width: calc(29% - 0px);
      padding-right: 0px;
      right: 25px;
    }

    .block-right {
      .card-default-transparent {
        padding: 10px 20px 20px 5px;

        #calender {
          .block-group {
            .block-25 {
              width: calc(43% - 0px);
              height: 8vw !important;
              margin-bottom: 0px;
              margin-top: 9px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 8px;
              background: #FFFFFF;
              border: 1px solid #F0F0F0;
              box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
              border-radius: 12px;
              margin-right: 12px;
            }
          }
        }

        .sidebar-btn-group {
          button {
            font-weight: 400;
            font-size: 12px;
            margin-right: 10px;
            margin-bottom: 12px;
            height: 32px;
            line-height: 16px;
          }
        }

        .card-heading {
          button {
            p {
              display: none;
              color: #475467;
              font-weight: 500;
              font-size: 14px;
            }
          }

        }
      }
    }
  }
  #topBar {
    .bar-area {
      .block-group {
        flex-wrap: nowrap;

        .inline-block {

          h5 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 255px;
          }

        }

        .inline-block:nth-child(3) {
          .notifications {
            .dropdown button {
              p {

                display: none;
              }
            }
          }
        }

        .inline-block:nth-child(2) {
          width: 18%;
        }

      }
    }
  }
}

@media (max-width: 1317px) {
  #layoutSidenav_nav {
    display: none;
  }
  .display-lg {
    display: block !important;
  }
}


@media only screen and (max-width: 1600px) {
  .card-default-transparent .card-heading button {
    p {
      display: none;
    }
  }
}


@media (max-width: 1569px) {
  .block-25 {
    width: calc(43% - 0px) !important;
    height: 6.5vw !important;

    .reminder {
      p {
        font-size: 12px !important;
        font-weight: 500;
        margin: 0px;
      }
    }

    .date {
      h4 {
        font-size: 12px !important;
      }
    }
  }
}

@media (max-width: 1054px) {

  .block-25 {
    width: calc(43% - 0px) !important;
    height: 6.5vw !important;

    .reminder {
      p {
        font-size: 10px !important;
        font-weight: 500;
        margin: 0px;
      }
    }

    .date {
      h4 {
        font-size: 11px !important;
      }
    }
  }
}

@media (max-width: 948px) {

  .block-25 {
    width: calc(43% - 0px) !important;
    height: 6.5vw !important;

    .reminder {
      p {
        font-size: 8px !important;
        font-weight: 500;
        margin: 0px;
      }
    }

    .date {
      h4 {
        font-size: 10px !important;
      }
    }
  }
  .block-right {
    .card-default-transparent {
      .sidebar-btn-group {
        button {
          font-size: 10px !important;
        }
      }
    }
  }
}

//PostDetailPage
#contentArea {
  .post-details {
    position: relative;
    width: calc(100% - 2vh);
    height: 87vh;

    .card-default {
      height: 100%;

      .view-post-area {
        height: 100%;
        margin: 10px;

        .post-content {
          height: 84vh;

          .media-section-1 {
            position: relative;
            width: 100%;

            .media-section {
              position: absolute;
              //top: 700;
              height: 97%;
              display: flex;
              //justify-content: center;
              align-items: center;
              flex-direction: column;
              overflow-y: auto;
              overflow-x: hidden;
              margin-bottom: 10px;
              gap: 15px;
            }
            .media-section .post-detail-image {
              .image-container img {
                max-width: 100%;
                height: 75vh;
                display: block;
                margin-bottom: 10px;
                border-radius: 8px;
              }
              .video-container > div {
                width: 100% !important;
                height: 79vh !important;
              }
            }
          }

          .post-content-section {
            position: absolute;
            right: 0;
            bottom: 15px;
            /* width: 100%; */
            height: 83vh;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-left: 10px;
            border-left: 1px solid #dadada;
            .post-by {
              margin-bottom: 18px;
            }
            .post-status {
              font-weight: 500;
              color: #475467;
              margin: 20px 0px 10px 0px;
            }
            .like-comment-share {
              border-top: 1px solid #E6E6E6;
              padding: 0px;
            }
            .commentbox-area {
              flex: 1;
            }
          }
        }
      }
    }
  }
}

//Morethan4Attachments
.attachment-div {
  position: relative; // Make it a positioning context
  filter: none !important;
  .count-badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    color: #fff;
    padding: 3px 5px;
    border-radius: 50%;
    font-size: 3.5vw;
  }
  img{
    filter: brightness(0.5) !important;
  }
}


//ModalPopup
.modal-resend-email {
  .modal-header {
    margin-top: 10px !important;
    padding: 0px 9px 0px 0px;
    width: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: flex-end !important;

    .btn-close {
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M18 6L6 18M6 6l12 12' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border: 0px;
      border-radius: 3px;
    }

    .btn-close:hover {
      background-color: rgba(0, 0, 0, 0.22);
    }
  }
}

.link-user-modal {
  padding: 0px 0px 0px 0px;
  width: 380px;
  position: relative;
  margin: 260px auto;
  border: none;

  .modal-content {
    border: 0px solid black !important;

    .modal-header {
      margin-top: 10px !important;
      padding: 0px 9px 0px 0px;
      width: 100%;
      display: flex;
      flex-direction: row !important;
      justify-content: flex-end !important;

      .btn-close {
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M18 6L6 18M6 6l12 12' /%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border: 0px;
        border-radius: 3px;
      }

      .btn-close:hover {
        background-color: rgba(0, 0, 0, 0.22);
      }
    }

    .modal-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 30px;
      margin-bottom: 20px;

      h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      #application {
        //width: 450px;
        .like-log {
          width: 210px;
          margin-bottom: 10px;

          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

//CreateAssignment

.left-col {
  width: 35%;
  border-right: 0.7px solid #C5C5C5;
  padding: 40px 60px 0px 41px;
  position: absolute;
  height: 87%;
}

.right-col {
  width: 65%;
  //position: inherit;
  //right: 0;
  //top: 138px;
  padding: 0px 46px;
  margin-left: auto;

}

.create-assignment {
  height: 91vh;
  padding: 7px 0px 0px 0px;
  position: relative;
  overflow: scroll;
  margin-bottom: 0px;

  .assignment-heading {
    margin: 15px 0px 0px 0px;
    padding-bottom: 25px;
    border-bottom: 0.7px solid #C5C5C5;

    p {
      margin-bottom: 0px;
      font-weight: 600;
      font-size: 20px;
      color: #141944;
    }

    .assignment-header {
      padding: 7px 36px 0px 36px;

      button {
        border-radius: 6px;

        svg {
          margin-right: 10px;
        }

        span {
          font-weight: 400;
          font-size: 18px;
          color: #4575EE;
        }

        &:hover {
          background-color: #d5e2fc;
        }
      }
    }
  }

  .card-body {
    padding: 0px;

    .left-col {
      .form-group {
        label {
          font-weight: 600;
          font-size: 16px;
          color: #141944;
        }

        .css-13cymwt-control {
          padding: 0px 15px;
          height: 34px !important;
          background: #F5F5F5;
          border: 1px solid #D9D9D9;
          border-radius: 5px;
        }
      }
    }
  }
}

.mcq-creation {
  .left-col {
    padding: 0px 74px 0px 25px;
    border-right: 0.7px solid #ffffff;
    position: absolute;
    top: 315px;

    .mcq-left-col {
      .form-group {

        .form-control {
          padding: 10px 15px;
          height: 34px;
          background: #F5F5F5;
          border: 1px solid #D9D9D9;
          border-radius: 5px;
        }
      }
    }
  }

  .right-col {
    .card-body {
      padding: 15px;
      width: 100%;
      margin: 15px 0px;
      //height: 562px;
      border: 1px solid #E3E3E3;
      border-radius: 8px;

      .form-row {
        .col-12 {
          .form-group {
            .question-heading {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;

              label {
                font-weight: 600;
                font-size: 16px;
                color: #141944;
              }

              button {
                padding: none;
                font-weight: 400;
                font-size: 12px;
                padding-right: 5px;

                i {
                  margin-right: 5px;
                }
              }
            }

            .rdw-storybook-root {
              .rdw-storybook-wrapper {
                position: relative;

                .rdw-editor-toolbar {
                  position: absolute;
                  top: 0px;
                  width: 97%;
                  height: 44px;
                  overflow: hidden;
                  margin-left: 11px;
                  z-index: 2;
                  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
                }

                .rdw-storybook-editor {
                  border: 0px solid #F1F1F1;
                  padding: 42px 15px 0px 10px;
                  width: 100%;
                  height: 200px;
                  background: #F3F3F3;
                  border-radius: 8px;
                  overflow: hidden;

                  .DraftEditor-root {
                    margin-top: 10px;
                    overflow: scroll;
                  }

                  .DraftEditor-root::-webkit-scrollbar {
                    width: 0.5em;
                  }

                  .DraftEditor-root::-webkit-scrollbar-track {
                    background-color: transparent;
                  }

                  .DraftEditor-root::-webkit-scrollbar-thumb {
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }

      .mcq-answers {
        .form-row {
          .bg-c-red-light {
            padding: 0px 1px 1px 1px;
            width: 22px;
            background: rgba(216, 20, 20, 0.1);
            border-radius: 4px;
            border: none;
          }

          p {
            font-weight: 500;
            font-size: 15px;
            color: #475467;
          }

          .rdw-storybook-root {
            .rdw-storybook-wrapper {
              position: relative;

              .rdw-editor-toolbar {
                position: absolute;
                top: 0px;
                width: 92%;
                height: 44px;
                overflow: hidden;
                margin-left: 11px;
                z-index: 2;
                box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
              }

              .rdw-storybook-editor-small {
                border: 0px solid #F1F1F1;
                padding: 42px 15px 0px 10px;
                width: 24vw;
                height: 130px;
                background: #F3F3F3;
                border-radius: 8px;
                overflow: hidden;

                .DraftEditor-root {
                  margin-top: 10px;
                  overflow: scroll;
                }

                .DraftEditor-root::-webkit-scrollbar {
                  width: 0.5em;
                }

                .DraftEditor-root::-webkit-scrollbar-track {
                  background-color: transparent;
                }

                .DraftEditor-root::-webkit-scrollbar-thumb {
                  background-color: transparent;
                }
              }

              //.rdw-storybook-editor-small{
              //  border: 1px solid #F1F1F1;
              //  padding: 5px;
              //  border-radius: 2px;
              //  height: 100px;
              //  background: white;
              //  width: 20vw;
              //}
            }
          }
        }
      }

      .form-group {
        .bg-c-blue-light {
          border-radius: 6px;

          font-weight: 400;
          font-size: 14px;
          color: #4575EE;
          gap: 10px;
          display: flex;
          align-items: center;

          &:hover {
            background-color: #dce5ff;
          }
        }
      }
    }

    .question-description {
      .rdw-storybook-root {
        .rdw-storybook-wrapper {
          .rdw-editor-toolbar {
            display: none;
            //width: 29%;
            //height: 68px;
            //overflow: hidden;
          }

          .rdw-storybook-editor {
            border: 0px solid #F1F1F1;
            padding: 0px 15px 0px 10px;
            width: 100%;
            height: 106px;
            background: #F3F3F3;
            border-radius: 8px;
          }
        }
      }
    }

    .form-row {
      .form-group {
        .btn-purple-md {
          font-weight: 600;
          font-size: 16px;
          border-radius: 50px;
          padding: 6px 26px;
          margin-right: 10px;
        }
      }

      .input-group {
        width: 12%;
        margin-left: 5px;
      }

      .col-6 {
        label {
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

.Toastify__progress-bar-theme--light {
  //display: none;
  background: transparent;
  animation-duration: 2000ms !important;
}

.question-generator {
  width: 35%;
  padding: 40px 60px 0px 41px;
  position: absolute;
  top: 611px;
  z-index: 1;

  button {
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 13px;

    i {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;

      }

      span {
        font-weight: 600;
        font-size: 0.8vw;
        color: #4575EE;
        font-style: normal;
      }
    }

    i {
      color: #4575EE;
    }

    &:hover {
      background-color: #d5e2fc;
    }
  }
}

.question-set {
  width: 35%;
  position: absolute;
  z-index: 1;
  top: 650px;
  padding: 10px 60px 0px 41px;

  button {
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 13px;

    i {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;

      }

      span {
        font-weight: 600;
        font-size: 0.8vw;
        color: #4575EE;
        font-style: normal;
      }
    }

    i {
      color: #4575EE;
    }

    &:hover {
      background-color: #d5e2fc;
    }
  }

  label {
    font-weight: 600;
    font-size: 16px;
    color: #141944;
  }

  .input-group {
    .form-control {
      padding: 10px 15px;
      height: 34px;
      background: #F5F5F5;
      border: 1px solid #D9D9D9;
      border-radius: 5px;
    }
  }

  .form-row {
    margin-top: 10px;
    gap: 20px;

    .form-group {
      .btn-generate {
        font-weight: 600;
        font-size: 16px;
        border-radius: 50px;
        padding: none;

        &:hover {
          background-color: #ffffff;
        }
      }

      .btn-cancel {
        background-color: transparent;
        border: 1px solid #475467;
        border-radius: 50px;
        font-weight: 500;
        font-size: 16px;
        color: #475467;

        &:hover {
          background-color: #e8e8e8;
        }
      }
    }
  }
}

.btn-cancel {
  background-color: transparent;
  border: 1px solid #475467;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  color: #475467;

  &:hover {
    background-color: #e8e8e8;
  }
}


.regular-type-question {
  .regular-left-col {
    padding: 0px 57px 0px 45px;

    position: absolute;
    top: 315px;

    .form-row {
      display: inherit;

      .form-control {
        padding: 0px 15px;
        height: 38px !important;
        background: #F5F5F5;
        border: 1px solid #D9D9D9;
        border-radius: 5px;
      }
    }
  }

  .right-col {
    padding: 46px 46px 0px 46px;

    .task-writing-box {
      border: 1px solid #E3E3E3;
      border-radius: 8px;
      padding: 15px;
      margin-top: 15px;
      padding-bottom: 0px;

      .form-row {
        .col-12 {
          .form-group {
            label {
              font-weight: 600;
              font-size: 16px;
              color: #141944;
            }
          }
        }

        .col-4 {
          max-width: 15.333333%;

          .form-row {
            .col {
              .form-group {
                .input-group {
                  width: 116px;
                  height: 48px;

                  .form-control {
                    background: #F5F5F5;
                    border: 1px solid #D9D9D9;
                    border-radius: 5px;
                    height: 50px;
                  }
                }
              }
            }
          }
        }

        .col-8 {
          .form-group {
            margin-bottom: 0px;

            .dropzone {
              height: 48px;
              border: none;

              .bg-c-light-orange {
                display: flex;
                justify-content: center;
                border: 0.5px dashed #E68F06;
                border-radius: 7px;
                align-items: center;
                color: #E68F06;

                &:hover {
                  background-color: #fffbf0 !important;
                }

                p {
                  margin-bottom: 0px;
                  font-weight: 400;
                  font-size: 12px;

                  svg {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }

        .form-group {
          .input-group {
            .form-control {
              background: #F5F5F5;
              border: 0px solid #F1F1F1;

              &::placeholder {
                color: #a2a2a2;
              }
            }
          }

          .form-group {
            .bg-c-red-light {
              display: flex;
              border-radius: 6px;
              gap: 10px;
              margin: 0px !important;
              align-items: center;

              &:hover {
                background-color: #ffd8d6;
              }

              p {
                font-weight: 500;
                font-size: 14px;
                color: #D81414;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }

    //.form-row {
    .form-group {
      .btn-purple-md {
        padding: 8px 10px !important;
      }

      .bg-c-blue-light {
        border-radius: 6px;
        font-weight: 400;
        font-size: 14px;
        color: #4575EE;
        gap: 10px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: #d9e5ff;
        }
      }
    }

    //}
  }
}

.takentype-question-left {
  padding: 0px 57px 0px 45px;

  position: absolute;
  top: 315px;

  .form-row {
    display: inherit;

    .form-control {
      padding: 0px 15px;
      height: 38px !important;
      background: #F5F5F5;
      border: 1px solid #D9D9D9;
      border-radius: 5px;
    }
  }
}

.takentype-question-right {
  padding: 46px 46px 0px 46px;

  .form-row {
    .form-group {
      .dropzone {
        height: 48px;
        border: none;

        .bg-c-light-orange {
          display: flex;
          justify-content: center;
          border: 0.5px dashed #E68F06;
          border-radius: 7px;
          align-items: center;
          color: #E68F06;

          &:hover {
            background-color: #fffbf0 !important;
          }

          p {
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 12px;

            svg {
              margin-right: 8px;
            }
          }
        }
      }
    }

    .col-12 {
      .form-group {
        .input-group {
          .form-control {
            background: #F5F5F5;
            border: 1px solid #D9D9D9;
            border-radius: 5px;
          }
        }

        .form-group {

          .form-control {
            background: #F5F5F5;
            border: 1px solid #D9D9D9;
            border-radius: 5px;
          }

        }
      }
    }

    .form-row {
      .form-group {
        button {
          font-weight: 600;
          font-size: 16px;
          border-radius: 50px;
          padding: 6px 26px;
          margin-right: 10px;
        }
      }
    }
  }
}


//McqExam
.mcq-exam {
  padding-right: 15px;
  padding-left: 15px;

  .title-header {
    border-bottom: 0px solid #C5C5C5;
    padding: 0px 0px 10px 0px;
    display: flex;
    justify-content: space-between;

    label {
      font-weight: 400;
      font-size: 14px;
      color: #878787;
      margin-bottom: 0px !important;
    }

    .assignment-name {
      p {
        span {

          font-size: 16px !important;
          color: #141944 !important;
        }
      }
    }

    .bg-c-blue-light {
      padding: 8px 37px;
      border-radius: 8px;

      p {
        font-weight: 400;
        font-size: 16px;
        color: #4575EE;
      }

      span {
        font-weight: 700;
        font-size: 16px;
        color: #4575EE;
      }

      &:hover {
        background-color: #E7EDFF;
      }
    }

    .bg-c-light-orange {
      padding: 8px 37px;
      border-radius: 8px;

      p {
        font-weight: 400;
        font-size: 16px;
        color: #E58F06;
      }

      span {
        font-weight: 700;
        font-size: 16px;
        color: #E58F06;
      }

      &:hover {
        background-color: #FFF3E0 !important;
      }
    }
  }

  .assignment-questions {
    border: 1px solid #D9D9D9;
    border-radius: 10px;

    .col-6 {
      .MuiSvgIcon-root {
        color: #0977ff !important;
      }
    }
  }

  .form-group {
    .btn-purple-md {
      padding: none;
      transition: 0.3s;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #1c285a;
      border-radius: 64px;
      width: 6vw !important;
    }

    .btn-cancel {
      width: 6vw !important;
      height: 35px;
      padding: 0px;
    }

    .savefor-later {
      &:hover {
        color: #9b9b9b;
      }

    }
  }
}

//Assignment Submit Regular
.assignment-submit-regular {
  padding-right: 15px;
  padding-left: 15px;

  .title-header {
    border-bottom: 0px solid #C5C5C5;
    padding: 0px 0px 10px 0px;
    display: flex;
    justify-content: space-between;

    label {
      font-weight: 400;
      font-size: 14px;
      color: #878787;
      margin-bottom: 0px !important;
    }

    .assignment-name {
      p {
        span {

          font-size: 16px !important;
          color: #141944 !important;
        }
      }
    }

    .bg-c-blue-light {
      padding: 8px 37px;
      border-radius: 8px;

      p {
        font-weight: 400;
        font-size: 16px;
        color: #4575EE;
      }

      span {
        font-weight: 700;
        font-size: 16px;
        color: #4575EE;
      }

      &:hover {
        background-color: #E7EDFF;
      }
    }

    .bg-c-light-orange {
      padding: 8px 37px;
      border-radius: 8px;

      p {
        font-weight: 400;
        font-size: 16px;
        color: #E58F06;
      }

      span {
        font-weight: 700;
        font-size: 16px;
        color: #E58F06;
      }

      &:hover {
        background-color: #FFF3E0 !important;
      }
    }
  }

  .assignment-questions {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 10px 10px !important;
    margin-bottom: 12px;

    .col-6 {
      .MuiSvgIcon-root {
        color: #0977ff !important;
      }
    }

    .savefor-later {
      padding: 0px;
      margin-right: 60px;
      font-weight: 500;

      &:hover {
        color: #9b9b9b;
      }

      &:focus {
        outline: none;
      }
    }

    .marks {
      color: #0977ff;
      font-weight: 500;
      margin-top: 2px;
    }

    .dropzone {
      display: flex;
      text-align: center;
      align-content: center !important;
      border: 1.5px dashed #bdbdbd;
      background-color: #fafafa !important;
      color: #bdbdbd;
      width: 100%;
      height: 100px;
      margin-bottom: 0px;
      cursor: pointer;

      .bg-c-light-orange {
        padding-top: 36px;
      }
    }
  }

  .form-group {
    .btn-purple-md {
      padding: none;
      transition: 0.3s;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid #1c285a;
      border-radius: 64px;
      width: 6vw !important;
    }

    .btn-cancel {
      width: 6vw !important;
      height: 35px;
      padding: 0px;
    }


  }
}

//RoomSetup
.room-setup {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 5px;
  padding-bottom: 0px;
  //height: 89vh;
  //overflow: hidden;

  .title-header {
    display: block;
    padding: 0px 42px 2px 44px;

    .col-3 {
      flex: 0 0 22.5%;
      max-width: 22.5%;
    }

    .col-12 {
      button {
        font-size: 13px;
        padding: 0px;
        margin: 0px;
      }
    }

    .search-btn {
      flex: 0 0 10% !important;
      max-width: 10% !important;
      display: flex;
      align-items: center;
      padding: 15px 0px 0px 10px;

      .btn-purple-md {
        height: 40px;
      }
    }

    .bg-c-blue-light {
      padding: 10px 20px;
      border-radius: 90px;
      font-weight: 500;
      font-size: 16px;
      color: #4575EE;
      display: flex;
      align-items: center;
      margin-left: 10px;

      svg {
        margin-right: 8px;
      }
    }
  }

  .table-light {
    height: 63.5vh;
    margin-bottom: 0px;

    .table {
      tbody {
        tr {
          td {
            padding-left: 20px;
          }
        }
      }
    }
  }

  .page-navigation {
    padding: 10px 27vw 10px 27vw;
    display: flex;
    justify-content: center;
    border-top: 1px solid #E3E3E3;
    background: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .paginationBttns {
      padding-bottom: 0px;
      margin-bottom: 0px;
    }

  }
}

//CreateRoom
.create-room {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 5px;
  //height: 89vh;
  .title-header {
    display: block;
    padding: 14px 42px 14px 38px;
    border-bottom: 1px solid #C5C5C5;
  }

  .room-body {
    padding: 20px 14px;

    .student-search {
      //height: 67vh;
      background: #F3F3F3;
      border-radius: 12px;
      padding-bottom: 45px;

      .student-search-box {
        height: 166px;
        background: #F7F7F7;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        padding: 10px 0px 0px 25px;

        .row {
          .col-3 {
            flex: 0 0 22%;
            max-width: 22%;
            display: flex;
          }

          .col-2 {
            flex: 0 0 11.666667%;
            max-width: 12.666667%;
            padding: 0px;
            display: flex;
            align-items: center;

            button {
              padding: 10px 3px;
              border: 1px solid #1c285a;
              border-radius: 25px;
              font-weight: 500;
            }
          }
        }

        p {
          font-weight: 600;
          font-size: 18px;
          color: #141944;
          padding: 0px 0px 12px 20px;
          margin: 0px;
        }
      }

      .student-name-table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .student-table {
          width: 36vw;
          background: #FFFFFF;
          box-shadow: 0px 6px 94px rgba(0, 0, 0, 0.08);
          border-radius: 10px;
          margin-top: 5vh;

          p {
            font-weight: 500;
            font-size: 16px;
            color: #475467;
            padding: 15px 0px 0px 27px;
            margin: 0px;
          }

          .table-light {
            height: 35vh;

            thead {
              th {
                border-color: #ffffff;
                background: #ffffff;
                padding: 15px 9px 0px 27px !important;
              }
            }

            tbody {
              td {
                border-color: #ffffff;
                background: #ffffff;
                padding: 15px 9px 0px 27px !important;
              }
            }
          }
        }
      }

      .no-student-found {
        text-align: center;
        margin-top: 22vh;
        font-weight: 400;
        font-size: 18px;
        color: #475467;
      }
    }

    .row {
      .top-row {
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;

        .col-4 {
          .form-group {
            margin-bottom: 0px;
            padding-top: 7px;
          }
        }
      }
    }

    .card-default {
      background-color: #F3F3F3;
      padding: 20px 5px;

      p {
        font-weight: 600;
        font-size: 18px;
        color: #141944;
        margin: 0px 0px 12px 35px;
      }

      .form-group {
        margin: 4px 0px 6px 15px;
      }
    }
  }
  .rbt-input-multi{
    .rbt-input-main {
      margin: 10px 0 10px;
    }
  }
  .input-default-white{
    .form-control{
      border-radius: 5px;
    }
  }
}


//MUIcssOverwriting
.MuiPaper-root {
  background-color: #ffffff !important;
  color: #000000 !important;
  border-radius: 14px !important;
  margin: 20px 0px;
}

.tss-1akey0g-MUIDataTableHeadCell-data {
  color: black !important;
}

.MuiSvgIcon-root {
  color: #878787 !important;
}

.tss-mwfche-MUIDataTableBodyRow-root {
  &:hover {
    background-color: #fff7ea !important;
  }
}

.tss-tlx3x1-MUIDataTableToolbar-root {
  border: 1px solid #dfdfdf;
  border-radius: 15px 15px 0px 0px;
}

.MuiTableCell-root {
  border-color: #E3E3E3 !important;
}

.MuiToolbar-root {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 0px !important;
  }
}


//RoutineList
.routine-list {
  padding-right: 0px;
  padding-left: 0px;
  height: 89vh;

  .title-header {
    padding: 15px 42px 30px 44px;
    border-bottom: 0px solid #C5C5C5;

    .btn-purple-md {
      width: auto !important;
      font-weight: 500;
      font-size: 16px;
      height: 46px;
      display: flex;
      align-items: center;


      svg {
        margin-right: 8px;

        path {
          fill: #ffffff;
          transition: fill 0.3s ease;
        }
      }

      &:hover {
        svg path {
          fill: #1c285a !important;
        }
      }
    }
  }

  .table-light {
    tbody {
      .no-routine {
        margin-top: 14vh;

        p {
          font-weight: 400;
          font-size: 16px;
          color: #475467;
          margin-top: 15px;
        }
      }

      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }
}

//RoutineEdit
.routine-edit {
  padding-right: 0px;
  padding-left: 0px;
  height: 89vh;

  .card-body {
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #141944;
      margin-left: 1vw;
      margin-bottom: 1vh;
    }

    .routine-calendar-box {
      flex-wrap: wrap;
      max-width: 79vw;
      margin: 0px auto;
      margin-left: 1vw;

      .routine-calendar {
        position: relative;
        padding: 7px 14px 36px 14px;
        width: 10vw;
        height: 240px;
        background: #F1F4FF;
        border: 2px solid rgba(69, 117, 238, 0.94);
        border-radius: 16px;
        margin-right: 1vw;

        .form-group {
          display: flex;
          justify-content: center;

          .form-control {
            padding: 2px 8px;
            width: 94px;
            height: 24px;
            background: #FFFFFF;
            border: 0.5px solid #475467;
            border-radius: 40px;
            font-size: 12px;
          }

          .btn-danger {
            width: 80%;
            font-size: 14px;
            height: 35px;
            margin-top: 20px;
            border-radius: 25px;

          }
        }

        .input-group {
          display: flex;
          justify-content: flex-end;

          .form-control {
            padding: 2px 8px;
            width: 94px;
            height: 24px;
            background: #FFFFFF;
            border: 0.5px solid #475467;
            border-radius: 40px;
            font-size: 12px;
            flex: 0 0 auto;
          }
        }

        label {
          color: #475467;
          font-weight: 400;
          display: flex;
          justify-content: flex-start;
          font-size: 15px;
        }

        svg {
          position: absolute;
          top: -9px;
          right: -9px;
        }

        .flex-column {
          label {
            font-weight: 600;
          }

          .form-group {
            margin-bottom: 0px;

            .form-control {
              width: 105px;
            }
          }
        }
      }
    }

    .form-row {
      margin: 20px;

      .btn-purple-md {
        padding: 6px 24px;
        width: auto;
        border-radius: 50px;
      }

      .btn-cancel {
        padding: 6px 24px;
        transition: 0.3s;
        font-size: 13px;
        font-weight: 700;
        width: auto;
        border-radius: 50px;
      }
    }
  }
}

//RoutineCreate
.routine-create {
  padding-right: 0px;
  padding-left: 0px;
  height: 89vh;

  .card-body {
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #141944;
      margin-left: 1vw;
      margin-bottom: 1vh;
    }

    .routine-calendar-box {
      flex-wrap: wrap;
      max-width: 79vw;
      margin: 0px auto;
      margin-left: 1vw;

      .routine-calendar {
        position: relative;
        padding: 7px 14px 36px 14px;
        width: 258px;
        height: 280px;
        background: #F1F4FF;
        border: 2px solid rgba(69, 117, 238, 0.94);
        border-radius: 16px;
        margin-right: 1vw;

        .form-group {
          display: flex;
          justify-content: center;

          .form-control {
            padding: 2px 8px;
            width: 94px;
            height: 24px;
            background: #FFFFFF;
            border: 0.5px solid #475467;
            border-radius: 40px;
            font-size: 12px;
          }

          .btn-danger {
            width: 80%;
            font-size: 14px;
            height: 35px;
            margin-top: -6px;
            border-radius: 25px;

          }
        }

        .input-group {
          display: flex;
          justify-content: flex-end;

          .form-control {
            padding: 2px 8px;
            width: 94px;
            height: 24px;
            background: #FFFFFF;
            border: 0.5px solid #475467;
            border-radius: 40px;
            font-size: 12px;
            flex: 0 0 auto;
          }
        }

        label {
          color: #475467;
          font-weight: 400;
          display: flex;
          justify-content: flex-start;
          font-size: 15px;
        }

        svg {
          position: absolute;
          top: -9px;
          right: -9px;
        }

        .flex-column {
          label {
            font-weight: 600;
          }

          .form-group {
            .form-control {
              width: 105px;
            }
          }
        }
      }
    }

    .form-row {
      margin: 20px;

      .btn-purple-md {
        padding: 6px 24px;
        width: auto;
        border-radius: 50px;
      }

      .btn-cancel {
        padding: 6px 24px;
        transition: 0.3s;
        font-size: 13px;
        font-weight: 700;
        width: auto;
        border-radius: 50px;
      }
    }
  }
}

//PostManagement
.post-management {
  padding-right: 0px;
  padding-left: 0px;
  height: 89vh;

  .title-header {
    padding: 15px 80px 30px 44px;
    border-bottom: 0px solid #C5C5C5;

    .sort-by {
      .dropdown-menu {
        top: 68%;
      }
    }
  }

  .post-management-tabs{
    padding: 0px 0px 0px 44px;
    .form-check-input {
    display: none;
    }
    .form-check-inline{
      margin-right: 0px;

      .form-check-label {
        display: inline-block;
        padding: 8px 16px;
        //background-color: #007bff;
        //color: #f6f6f6;
        border-bottom: none;
        cursor: pointer;
        border-radius: 4px 4px 0px 0px;
        transition: background-color 1s, color 1s;
        &:hover {
          background-color: #f6f6f6;
          color: #1c1c1c;

        }
      }
      .form-check-input:checked + .form-check-label {
        background-color: #e8e8e8;
        color: #1c1c1c;
        font-weight: bold;
        font-size: 15px;
      }
    }
  }

  .table-light {
    tbody {
      tr {
        td {
          padding-left: 20px;
          .bg-c-light-yellow{
            background-color: #faf181 !important;
            &:hover{
              background-color: #ffed18 !important;
            }
          }
        }
      }
    }
  }
  .page-navigation {
    padding: 10px 28vw;
  }
  .modal-content {
    .modal-body {
      padding: 20px 0px 10px 0px;
      border-bottom: 1px solid #e1e1e1;
      width: 100%;
      justify-content: center;
      display: flex;
      p{
        font-size: 16px;
        font-weight: 500;
      }
      .align-items-baseline {
        margin-top: 15px;

        label {
          display: flex;
          cursor: pointer;
          font-weight: 500;
          position: relative;
          overflow: hidden;
          margin-bottom: 0.375em;

          input {
            visibility: hidden;

            &:checked + span {
              background-color: #e6e6ff;
              &:before {
                box-shadow: inset 0 0 0 0.4375em #00005c;
              }
            }
          }

          span {
            display: flex;
            align-items: center;
            padding: 0.375em 0.75em 0.375em 0.375em;
            border-radius: 99em;
            transition: 0.25s ease;

            &:hover {
              background-color: #eaeaea;
            }

            &:before {
              display: flex;
              flex-shrink: 0;
              content: "";
              background-color: #fff;
              width: 1.5em;
              height: 1.5em;
              border-radius: 50%;
              margin-right: 0.375em;
              transition: 0.25s ease;
              box-shadow: inset 0 0 0 0.125em #00005c;
            }
          }
        }
      }

      .circle-icon {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100px;
        height: 100px;
        min-width: 0px;
        background-color: #fff5bc;
        margin-bottom: 15px;

        img {
          width: 70px;
          height: 70px;
        }
      }
    }
    .modal-footer{
      margin: 20px 0px !important;
      padding-bottom: 0px !important;

      .confirm-button{
        color: white;
        background: #22c04b;
      }
    }
  }
}


//PostManagementDetails





.global-repository{
  .modal-dialog{
    .modal-content{
      .modal-header{
        .image-icon-hidepost{
          height: 80px;
          width: 80px;
        }
        .modal-body{
          padding: 20px 0px 5px 0px !important;
        }
      }
      .modal-footer{
        .delete-button{
          button{
            color: white !important;
            background: #D81414 !important;
            border: none !important;
            &:hover {
              background: #ec4949 !important;
              transition: 0.3s;
            }
          }
        }
        .confirm-button{
          button{
            color: white !important;
            background: #21d814 !important;
            border: none !important;
            &:hover {
              background: #5de353 !important;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
}
//AttendanceStatistics

.attendance-statistics {
  padding-right: 0px;
  padding-left: 0px;

  .title {
    border-bottom: 0.7px solid #C5C5C5;
    padding: 15px 42px 30px 44px;

    p {
      font-weight: 600;
      font-size: 20px;
      color: #141944;
      margin-bottom: 0px;
    }
  }

  .attendance-dropdown-area {
    padding: 20px 44px;

    .row {
      .col-2 {
        flex: 0 0 19.5%;
        max-width: 19.5%;

        .form-group {
          label {
            margin-left: 18px;
            font-weight: 500;
            font-size: 14px;
            color: #475467;
          }

          .form-group {
            .form-control {
              height: calc(1.5em + 0.75rem + 15px);
              border: 1px solid #878787;
              border-radius: 60px;
            }
          }

          .input-group {
            .form-control {
              height: calc(1.5em + 0.75rem + 15px);
              border: 1px solid #878787;
              border-radius: 60px;
            }
          }
        }
      }

      .attendance-check-box {
        height: 27%;
        gap: 20px;
        margin-top: 24px;

      }

      .col-4 {
        flex: 0 0 39%;
        max-width: 39%;

        .form-group {
          button {
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            gap: 7px;
            font-weight: 500;
            font-size: 16px;

            .fa-search {
              font-weight: 200px;

              &:hover {
                transition: 0.3s;
              }
            }
          }
        }
      }
    }
  }

  .result-area {
    h5 {
      padding-left: 40px;
      padding-top: 20px;
      border-top: 0.7px solid #C5C5C5;

    }

    .body-water-mark {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 134px;
        height: 137px;
      }

      h5 {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #878787;
        width: 15%;
        opacity: 1;
        border-top: 0px solid white;
      }
    }

    .card-body {
      padding: 0px 0px;

      .table-light {
        tr {
          th {
            color: #141944;
          }

          td {
            padding-left: 22px;
            font-weight: 400;
            font-size: 14px;
            color: #475467;

            .bg-c-blue-light {
              &:hover {
                background-color: #dfe5ff;
              }
            }
          }
        }
      }
    }
  }
  .modal-dialog {
    max-width: 500px !important;
    margin: 0px auto;
    .modal-content {
      align-items: flex-end;
      display: flex;
      .modal-body {
        font-size: 15px;
        font-weight: 300;
        color: #364255;
        padding: 20px 20px;
        width: 100% !important;
        .input-default{
          margin: 15px 15px;
          width: auto;
          .form-control {
            height: 20vh;
            border-radius: 10px;
          }
          .message-modal-buttons{
            display: flex;
            justify-content: end;
            .btn-success{
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 20px;
              padding: 5px 15px 5px 10px;
              .fa-send{
                font-size: 12px;
              }
            }
            .btn-cancel{
              &:hover{
                background-color: #ffe9e9;
                color: #d31414;
                border-color: #d31414;
              }
            }
          }
        }
      }
    }
  }
}


//ResultStatistics
.result-statistics {
  padding-right: 0px;
  padding-left: 0px;

  .title {
    border-bottom: 0.7px solid #C5C5C5;
    padding: 15px 42px 30px 44px;

    p {
      font-weight: 600;
      font-size: 20px;
      color: #141944;
      margin-bottom: 0px;
    }
  }

  .result-dropdown-area {
    padding: 20px 44px;

    .row {
      .col-2 {
        flex: 0 0 19.5%;
        max-width: 19.5%;

        .form-group {
          label {
            margin-left: 18px;
            font-weight: 500;
            font-size: 14px;
            color: #475467;
          }

          .form-group {
            .form-control {
              height: calc(1.5em + 0.75rem + 15px);
              border: 1px solid #878787;
              border-radius: 60px;
            }
          }

          .input-group {
            .form-control {
              height: calc(1.5em + 0.75rem + 15px);
              border: 1px solid #878787;
              border-radius: 60px;
            }
          }
        }
      }

      .result-check-box {
        height: 27%;
        gap: 20px;
        margin-top: 24px;

      }

      .col-4 {
        flex: 0 0 39%;
        max-width: 39%;

        .form-group {
          button {
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            gap: 7px;
            font-weight: 500;
            font-size: 16px;

            .fa-search {
              font-weight: 200px;

              &:hover {
                transition: 0.3s;
              }
            }
          }
        }
      }
    }
  }

  .result-area {
    h5 {
      padding-left: 40px;
      padding-top: 20px;
      border-top: 0.7px solid #C5C5C5;

    }

    .body-water-mark {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 134px;
        height: 137px;
      }

      h5 {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #878787;
        width: 15%;
        opacity: 1;
      }
    }

    .table-light {
      tr {
        th {
          color: #141944;
        }

        td {
          padding-left: 22px;
          font-weight: 400;
          font-size: 14px;
          color: #475467;

          .bg-c-blue-light {
            &:hover {
              background-color: #dfe5ff;
            }
          }
        }
      }
    }
  }
  .modal-dialog {
    max-width: 500px !important;
    margin: 0px auto;
    .modal-content {
      align-items: flex-end;
      display: flex;
      .modal-body {
        font-size: 15px;
        font-weight: 300;
        color: #364255;
        padding: 20px 20px;
        width: 100% !important;
        .input-default{
          margin: 15px 15px;
          width: auto;
          .form-control {
            height: 20vh;
            border-radius: 10px;
          }
          .message-modal-buttons{
            display: flex;
            justify-content: end;
            .btn-success{
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 20px;
              padding: 5px 15px 5px 10px;
              .fa-send{
                font-size: 12px;
              }
            }
            .btn-cancel{
              &:hover{
                background-color: #ffe9e9;
                color: #d31414;
                border-color: #d31414;
              }
            }
          }
        }
      }
    }
  }
}


//AssignmentTaskSubmitViewPage

.card-default {
  h6 {
    font-weight: 400;
    font-size: 16px;
    color: #878787;
    margin: 0px 0px;
  }

  .submission-search {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .submission {
      h4 {
        font-weight: 600;
        font-size: 20px;
        color: #141944;
      }

      h5 {
        font-weight: 500;
        font-size: 18px;
        color: #475467;
        margin-top: 15px;
        margin-bottom: 0px;
      }
    }

    .search-sort {
      width: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;

      .form-group {
        margin-bottom: 0px;
      }

      .sort-by {
        position: relative;
        display: inline-block;

        p {
          margin-bottom: 0px;
          margin-right: 8px;
        }

        svg {
          transition: transform 0.3s ease;
        }

        &:hover {
          svg {
            transform: rotate(180deg);
          }
        }

        .sort-by-button {
          display: flex;
          align-items: center;
          padding: 0;
          margin: 0;
          background: none;
          border: none;
          color: #475467;
          cursor: pointer;
        }

        .dropdown-menu {
          display: none;
          position: absolute;
          top: 100%;
          left: -80px;
          list-style: none;
          padding: 0;
          background-color: #fff;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

          li {
            padding: 8px 10px;
            cursor: pointer;
          }
        }

        &:hover .dropdown-menu {
          display: block;
        }
      }
    }
  }
}

//AssignmentSubmittedView

.right-view {
  .sort-by {
    position: relative;
    display: inline-block;

    p {
      margin-bottom: 0px;
      margin-right: 8px;
    }

    svg {
      transition: transform 0.3s ease;
    }

    &:hover {
      svg {
        transform: rotate(180deg);
      }
    }

    .sort-by-button {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      color: #475467;
      cursor: pointer;
    }

    .dropdown-menu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      list-style: none;
      padding: 0;
      background-color: #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

      li {
        padding: 8px 10px;
        cursor: pointer;
      }
    }

    &:hover .dropdown-menu {
      display: block;
    }
  }
}


#reg_layout {
  display: flex;
  width: 50%;

  #reg_content {
    background: linear-gradient(324deg, #ffffff 0%, #FFF0F0 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh - 56px);

    #registrations {
      margin-top: 5%;
      background-image: none;

      .container {

        h4 {
          font-size: 32px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 70px;
        }

        .card-default {
          padding: 45px 45px;
          padding-top: 50px;
          margin: 0 auto;
          width: 73%;
          box-shadow: none !important;
          background-color: #ffffff00;


          .input-reg {

            .block-group {
              margin-bottom: 20px;

              .block-50 {
                width: calc(100% - 0px);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .box-btn {
                  width: 100%;
                  height: 50px;
                  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                  border-radius: 10px;

                  p {
                    text-transform: none;
                    font-weight: 500;
                    font-size: 16px;
                  }
                }

                .back-button {
                  font-weight: 400;
                  font-size: 14px;
                  color: #475467;
                  margin-top: 30px;

                  &:hover {

                    color: #9ea3b0;
                  }

                }
              }

              .block {
                width: calc(100% - 0px);

                .password-box {
                  position: relative;
                }

                .password-icon {
                  position: absolute;
                  top: 50%;
                  right: 15px;
                  transform: translateY(-50%);
                }

              }

              .forget-pass-btn {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .remember-me {
                  display: flex;
                  gap: 8px;
                  color: #878787;
                  align-items: center;

                  label {
                    font-weight: 400;
                    font-size: 14px;
                    margin-bottom: 0px;
                  }
                }

                .forgot-password {
                  width: unset;
                  color: #4575EE;
                }
              }
            }

            img {
              font-size: 55px;
              color: #141944;
              margin-top: 20px;
            }

            h5 {
              font-size: 24px;
              font-weight: 600;
            }

            button {
              margin-top: 10px;
              margin-bottom: 5px;

              p {
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 600;
                margin: 0px;
              }
            }


          }
        }


        @include lg {
          h4 {
          }
          .card-default {
            width: 100%;

            .input-reg {
              .block-group {
                .block-50 {
                }
              }

              button {
                p {
                }
              }
            }
          }
          .footer-instruction {
            p {
            }
          }
        }
        @include xs {
          h4 {
            font-size: 24px;
            margin-top: 40px;
            margin-bottom: 35px;
          }
          .card-default {
            padding: 20px 25px;
            padding-top: 30px;

            .input-reg {
              label {
                margin-bottom: 10px;
              }

              .block-group {
                .block-50 {
                  width: 100%;
                }
              }

              button {
                margin-top: 10px;

                p {
                }
              }
            }
          }
          .footer-instruction {
            p {
            }
          }
        }
      }

      #login {
        .container {

          .brand {
            text-align: center;
            margin: 0px 0px 0px 0px;

            h2 {
              font-weight: 600;
              font-size: 40px;
              display: flex;
              justify-content: center;
              color: #1d1d1d;
              margin: 18px;
            }

            h3 {
              margin-top: 20px;
              font-weight: 600;
              font-size: 26px;
              color: #2d2d2d;
            }

            p {
              margin-top: 10px;
              font-weight: 500;
              font-size: 20px;
              color: #878787;
            }

            img {
              width: 150px;
            }
          }

          h4 {
            padding-top: 20px;
          }

          .card-default {
            width: 50%;
            padding: 0px 38px 0px 38px;
            border-radius: 20px;

            img {
              width: 210px;
              margin-bottom: 35px;
            }

            h5 {
              margin-bottom: 35px;
            }
          }


          .footer-instruction {
            margin-top: 0px;
            margin-bottom: 10px;
            text-align: center;
            width: 100%;

            .googleloginbutton{
              background-color:#f0f0f0;
              height: 45px;
              width: 310px;
              border-radius:5px;
              border: 0px;
              padding:11px;
              display:flex;
              justify-content:center;
              align-items:center;
              gap:10px;
            }
            .facebookloginbutton{
              background-color: rgb(22 119 241 / 28%);
              height: 45px;
              width: 310px;
              border-radius:5px;
              border: 0px;
              padding:11px;
              display:flex;
              justify-content:center;
              align-items:center;
              gap:10px;
            }


            p {
              font-size: 16px;
              color: #475467;
              line-height: 2.5;

              strong {
                color: #141944;
              }
            }

            .btn-group {
              display: flex;
              justify-content: space-between;
              width: 40%;
              margin: 0 auto;
              margin-top: 20px;

              a {
                height: 20px;
                text-decoration: none;

                p {
                  font-weight: 400;
                  font-size: 16px;
                  color: #475467;
                }
              }

              .circle-icon {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 70px;
                height: 70px;
                min-width: 0px;


              }

              .bg-c-blue-light {

                &:hover {
                  background-color: #cce0ff;
                }
              }

              .bg-c-light-orange {

                &:hover {
                  background-color: #ffeec1 !important;
                }
              }

              .bg-c-purple-light {
                &:hover {
                  background-color: #e3d4ff;
                }
              }
            }
          }
        }

        @include lg {
          .container {
            .card-default {
              width: 80%;

              .block-group {
                .block-50 {
                  width: 100%;
                }
              }
            }
          }
        }
        @include xs {
          .container {
            .brand {
              margin-bottom: 20px;
            }

            .card-default {
              width: 100%;
              padding: 30px 20px 20px 20px;

              img {
                width: 200px;
              }

              .block-group {
                .block-50 {
                  width: 100%;
                }
              }
            }

            .footer-instruction {
              .btn-group {
                width: 68% !important;

                button {
                  min-width: 60px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1656px) {
  .login-page {
    #reg_layout {
      #reg_content {
        #registrations {
          #login {
            .container {
              .card-default {
                width: 70%;
                padding-bottom: 0px;
              }

              .brand {
                margin: 40px 0px 0px 0px;

                h2 {
                  margin-bottom: 35px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1656px) {
  .student-registration {
    #reg_layout {
      #reg_content {
        #registrations {
          #studentReg {
            .container {
              .card-default {
                width: 70%;
                padding-bottom: 0px;
              }

              .brand {
                margin: 40px 0px 0px 0px;

                h2 {
                  margin-bottom: 35px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1374px) {
  .login-page {
    #reg_layout {
      #reg_content {
        #registrations {
          #login {
            .container {
              .card-default {
                width: 70%;
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 951px) {
  .login-page {
    #reg_layout {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      #reg_content {
        #registrations {
          #login {
            .container {
              max-width: 540px;

              .card-default {
                width: 80%;
                padding: 0px 0px 0px 0px;
              }

              .footer-instruction {
                .btn-group {
                  display: flex;
                  justify-content: space-between;
                  width: 53%;
                  margin: 0 auto;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }

    .login-image {
      display: none;
    }
  }
}


@media only screen and (max-width: 951px) {
  .student-registration {
    #reg_layout {
      display: flex;
      width: 100% !important;
      justify-content: center;
      align-items: center;

      #reg_content {
        #registrations {
          #login {
            .container {
              max-width: 540px;

              .card-default {
                width: 80%;
                padding: 0px 0px 0px 0px;
              }

              .footer-instruction {
                .btn-group {
                  display: flex;
                  justify-content: space-between;
                  width: 53%;
                  margin: 0 auto;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }

    .login-image {
      display: none;
    }
  }
}

@media only screen and (max-width: 951px) {
  .employee-registration {
    #reg_layout {
      display: flex;
      width: 100% !important;
      justify-content: center;
      align-items: center;

      #reg_content {
        #registrations {
          #login {
            .container {
              max-width: 540px;

              .card-default {
                width: 80%;
                padding: 0px 0px 0px 0px;
              }

              .footer-instruction {
                .btn-group {
                  display: flex;
                  justify-content: space-between;
                  width: 53%;
                  margin: 0 auto;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }

    .login-image {
      display: none;
    }
  }
}

@media only screen and (max-width: 951px) {
  .parents-registration {
    #reg_layout {
      display: flex;
      width: 100% !important;
      justify-content: center;
      align-items: center;

      #reg_content {
        #registrations {
          #login {
            .container {
              max-width: 540px;

              .card-default {
                width: 80%;
                padding: 0px 0px 0px 0px;
              }

              .footer-instruction {
                .btn-group {
                  display: flex;
                  justify-content: space-between;
                  width: 53%;
                  margin: 0 auto;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }

    .login-image {
      display: none;
    }
  }
}



@media only screen and (max-width: 951px) {
  .reset-password {
    #reg_layout {
      display: flex;
      width: 100% !important;
      justify-content: center;
      align-items: center;

      #reg_content {
        #registrations {
          #login {
            .container {
              max-width: 540px;

              .card-default {
                width: 80% !important;
                padding: 0px 0px 0px 0px;
              }

              .footer-instruction {
                .btn-group {
                  display: flex;
                  justify-content: space-between;
                  width: 53%;
                  margin: 0 auto;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }

    .login-image {
      display: none;
    }
  }
}

@media only screen and (max-width: 951px) {
  .verification-page {
    #reg_layout {
      display: flex;
      width: 100% !important;
      justify-content: center;
      align-items: center;

      #reg_content {
        #registrations {
          #login {
            .container {
              max-width: 540px;

              .card-default {
                width: 80% !important;
                padding: 0px 0px 0px 0px;
              }

              .footer-instruction {
                .btn-group {
                  display: flex;
                  justify-content: space-between;
                  width: 53%;
                  margin: 0 auto;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }

    .login-image {
      display: none;
    }
  }
}



.wrong_credentials {
  color: #d52667;
  text-align: center;
  font-size: 20px;
}

.reset-password {
  #reg_layout {
    display: flex;
    width: 50%;

    #reg_content {
      background-color: #ffffff;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 0;
      flex-grow: 1;
      min-height: calc(100vh - 56px);

      .container {
        .brand {
          text-align: center;
          margin: 75px 0px 0px 0px;

          h3 {
            margin-top: 100px;
            font-weight: 600;
            font-size: 26px;
            color: #141944;
          }

          p {
            margin-top: 15px;
            font-weight: 400;
            font-size: 14px;
            color: #878787;
          }
        }

        .card-default {
          padding: 20px 45px;
          margin: 0 auto;
          width: 73%;
          box-shadow: none !important;

          .form-row {
            width: calc(100% - 0px);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .col-3 {
              width: 67%;
              max-width: none;
              padding-right: 0px;

              .form-control {
                height: calc(1.5em + 0.75rem + 6px);
                opacity: 0.8;
                padding: 0.375rem 1.4rem;
                background-color: #FFFFFF;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 8px;
              }

              .box-btn {
                //width: 389px;
                height: 50px;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 10px;

                p {
                  text-transform: none;
                  font-weight: 500;
                  font-size: 16px;
                }
              }

            }


          }
        }
      }
    }
  }


  .login-image {
    .gradient-background {
      background: linear-gradient(0deg, #FACE89 0%, #FFFBF4 100%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      .image-container {
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 60vh;
        justify-content: center;

        .person {
          position: absolute;
          top: 2%;
          left: 5%;
          transform-origin: center bottom;
        }

        .paperplane {
          position: absolute;
          top: 25%;
          left: 5%;
        }

        .bulb {
          position: absolute;
          top: 5%;
          left: 22%;
        }

        .cloud-sun {
          position: absolute;
          top: 9%;
          left: 7%;
        }

        .bottom_cloud {
          position: absolute;
          top: 54%;
          right: -58%;
          width: 217%;
        }

        @keyframes hover {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
        }

        .person {
          animation: hover 4s infinite ease-in-out;
        }

        .paperplane {
          animation: hover 3s infinite ease-in-out alternate;
        }
      }

      p {
        width: 442px;
        height: 138px;
        font-weight: 600;
        font-size: 34px;
        line-height: 46px;
        text-align: center;
        color: #141944;

        span {
          color: #FFA70C;
        }
      }
    }

  }
}


.student-registration {
  #reg_layout {
    display: flex;
    width: 50%;

    #reg_content {
      background-color: #ffffff;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 0;
      flex-grow: 1;
      min-height: calc(100vh - 56px);

      #registrations {
        #studentReg {
          .container {
            .brand {
              text-align: center;
              margin: 75px 0px 0px 0px;

              h3 {
                margin-top: 20px;
                font-weight: 600;
                font-size: 24px;
                color: #000000;
              }
            }

            .card-default {
              width: 100%;
              padding-top: 20px;
              padding-bottom: 0px;

              .input-reg {
                .block-group {
                  display: flex;
                  flex-wrap: nowrap;
                  gap: 25px;

                  .block-50 {
                    width: calc(100% - 0px);
                    display: flex;
                    justify-content: start;
                    align-items: flex-start;
                    flex-direction: column;

                    label {
                      font-weight: 400;
                      font-size: 14px;
                      color: #141944;
                    }

                    .error_val {
                      font-weight: 400;
                      font-size: 14px;
                      color: #ff0000;

                    }

                    .box-btn {
                      width: inherit;
                    }

                    .form-group {
                      width: inherit;
                      margin-bottom: 0px;
                    }
                  }

                  .password-box {
                    position: relative;
                  }

                  .password-icon {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(100%);
                  }
                }
              }
            }

            .footer-instruction {
              margin-top: 10px;
              text-align: center;

              p {
                font-weight: 500;
                font-size: 14px;
                text-align: center;
                color: #141944;

                a {
                  font-weight: 400;
                  font-size: 14px;
                  color: #475467;
                }
              }

              .back-button {
                i {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.verification-page {
  #reg_layout {
    display: flex;
    width: 50%;

    #reg_content {
      display: flex;
      //align-items: center;
      //justify-content: center;
      #registrations {
        #login {
          .container {
            .brand {
              text-align: center;
              margin: 105px 0px 0px 0px;

            }

            .card-default {
              width: 100%;
              padding-top: 20%;
              padding-bottom: 0px;

              .input-reg {
                .block-group {
                  display: flex;
                  flex-wrap: nowrap;
                  gap: 25px;

                  .block {
                    margin-top: 10px !important;

                    .text-center {
                      img {
                        width: 70px;
                        height: 70px;
                        margin-bottom: 8px;
                      }

                      p {
                        font-weight: 600;
                        font-size: 18px;
                        color: #141944;
                      }
                    }

                    .sent-verificaiton-link {
                      p {
                        font-weight: 400;
                        font-size: 14px;
                        color: #878787;
                        margin-bottom: 0px;
                      }

                      span {
                        font-weight: 500;
                        font-size: 16px;
                        color: #475467;
                      }
                    }
                  }
                }
              }
            }

            .footer-instruction {
              margin-top: 10px;
              text-align: center;

              p {
                font-weight: 500;
                font-size: 14px;
                text-align: center;
                color: #141944;

                a {
                  font-weight: 400;
                  font-size: 14px;
                  color: #475467;
                }
              }

              .back-button {
                i {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .login-image {
    .gradient-background {
      .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        ///* justify-content: center; */
        height: 100vh;

        p {
          margin-top: 85px;
        }
      }
    }

  }
}

.brand h2 {
  font-weight: 600;
  font-size: 24px;
  display: flex;
  justify-content: center;
  color: #141944;
  margin-bottom: 50px;
}

.employee-registration {
  #reg_layout {
    display: flex;
    width: 50%;

    #reg_content {
      background-color: #ffffff;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 0;
      flex-grow: 1;
      min-height: calc(100vh - 56px);

      #registrations {
        #employeeReg {
          .container {
            .brand {
              text-align: center;
              margin: 75px 0px 0px 0px;

              h3 {
                margin-top: 20px;
                font-weight: 600;
                font-size: 24px;
                color: #000000;
              }
            }

            .card-default {
              width: 100%;
              padding-top: 20px;
              padding-bottom: 0px;

              .input-reg {
                .block-group {
                  display: flex;
                  flex-wrap: nowrap;
                  gap: 25px;

                  .block-50 {
                    width: calc(100% - 0px);
                    display: flex;
                    justify-content: start;
                    align-items: flex-start;
                    flex-direction: column;

                    label {
                      font-weight: 400;
                      font-size: 14px;
                      color: #141944;
                    }

                    .error_val {
                      font-weight: 400;
                      font-size: 14px;
                      color: #ff0000;

                    }

                    .box-btn {
                      width: inherit;
                    }

                    .form-group {
                      width: inherit;
                      margin-bottom: 0px;

                      .form-control {
                        padding: 5px;
                      }
                    }
                  }

                  .password-box {
                    position: relative;
                  }


                  .password-icon {
                    position: absolute;
                    top: 60%;
                    right: 15px;
                    transform: translateY(20%);
                    fill: #878787;
                    cursor: pointer;
                    transition: fill 0.2s ease;
                  }
                }
              }
            }

            .footer-instruction {
              margin-top: 10px;
              text-align: center;

              p {
                font-weight: 500;
                font-size: 14px;
                text-align: center;
                color: #141944;

                a {
                  font-weight: 400;
                  font-size: 14px;
                  color: #475467;
                }
              }

              .back-button {
                i {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}


.parents-registration {
  #reg_layout {
    display: flex;
    width: 50%;

    #reg_content {
      background-color: #ffffff;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 0;
      flex-grow: 1;
      min-height: calc(100vh - 56px);

      #registrations {
        #parentsReg {
          .container {
            .brand {
              text-align: center;
              margin: 75px 0px 0px 0px;

              h3 {
                margin-top: 20px;
                font-weight: 600;
                font-size: 24px;
                color: #000000;
              }
            }

            .card-default {
              width: 100%;
              padding-top: 20px;
              padding-bottom: 0px;

              .input-reg {
                .block-group {
                  display: flex;
                  flex-wrap: nowrap;
                  gap: 25px;

                  .block-50 {
                    width: calc(100% - 0px);
                    display: flex;
                    justify-content: start;
                    align-items: flex-start;
                    flex-direction: column;

                    label {
                      font-weight: 400;
                      font-size: 14px;
                      color: #141944;
                    }

                    .error_val {
                      font-weight: 400;
                      font-size: 14px;
                      color: #ff0000;

                    }

                    .box-btn {
                      width: inherit;
                    }

                    .form-group {
                      width: inherit;
                      margin-bottom: 0px;

                      .form-control {
                        padding: 5px;
                      }
                    }
                  }

                  .password-box {
                    position: relative;
                  }

                  .password-icon {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(115%);
                  }
                }
                .child-uniqueid{
                  label{
                      font-weight: 400;
                  }

                  .btn-deep-blue-lg{
                    padding: 5px 0px;
                    width: 25% !important;
                    transition: 0.3s;
                    border: 2px solid #FF0505;
                    border-radius: 30px;
                    font-size: 14px;
                }
                  button{
                    margin-top: 0px;
                    margin-bottom: 0px;
                  }
                }
              }
            }

            .footer-instruction {
              margin-top: 10px;
              text-align: center;

              p {
                font-weight: 500;
                font-size: 14px;
                text-align: center;
                color: #141944;

                a {
                  font-weight: 400;
                  font-size: 14px;
                  color: #475467;
                }
              }

              .back-button {
                i {
                  margin-right: 5px;
                }
              }
            }
          }
          .rbt-input-multi{
            .rbt-input-main {
              margin: 0px 0px 5px 0px;
            }
            .rbt-input-wrapper{
              display: flex;
              align-items: center;
              .rbt-token{
                margin: 5px 8px 8px 0px;
                .rbt-token-label{
                  font-size: 16px;
                  display: flex;
                  align-items: center;
                }
                .btn-close{
                  margin: 2px 0px 0px 4px !important;
                }
              }
            }

          }

        }
      }
    }
  }
}


.login-image {
  position: relative;
  width: 50%;
  height: 100vh;
  overflow: hidden;
  .img-box{
    align-items: center;
    width: -webkit-fill-available;
    height: 100% !important;
    justify-content: center !important;
    display: flex !important;
    img{
      width: -webkit-fill-available;
      height: 100vh;
    }
    }
}


#regCoolingPeriod {
  .content-wrap {
    padding: 0px 20px;
    text-align: center;

    .illustration {
      margin-bottom: 55px;

      img {
        width: 70%;
      }
    }

    h5 {
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      line-height: 1.7;
    }

    a {
      color: orangered;
    }
  }
}

.all-notification-section {
  height: 70vh;

}

/* Tabs
--------------------*/
.tab-default {
  position: relative;

  .tabs {
    .nav {
      border-bottom: 2px solid gray;
      margin-top: 12px;
      padding-bottom: 0px;
      display: grid;
      grid-template-columns: 17% 20% 71%;

      first-child {
        align-self: end;
      }

      .nav-item {
        padding-right: 30px;
        align-items: baseline;

        a {
          font-size: 16px;
          font-weight: 500;
          color: #888888;
          padding-left: 0px;
          padding-right: 0px;

          padding-bottom: 8px;
        }

        .notification-button {
          border-bottom: 0;
          color: black;
          background-color: #ffffff;
          font-size: 16px;
        }

        .notify-dot {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          margin-left: 10px;
          width: 26px;
          height: 19px;
          background: #E8E8E8;
          border-radius: 29px;

          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #878787;

          }
        }


      }

      .search-sort {
        padding-left: 22vh;

        .sort-by-header {
          p {
            padding-right: 7px;
            margin-bottom: 0px;
          }
        }
      }
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {

      color: #141944;
      background-color: #ffffff;
      border-radius: 0px;
      padding: 10px 0px;
      font-size: 16px;
      border-bottom: 3px solid #FAA31A;
    }

    .nav-pills .nav-link.active .notify-dot {

      background-color: #FFF3E0;
    }

    .nav-pills .nav-link.active .notify-dot span {
      color: #ff9c19;
    }

    .nav-pills .nav-link .notify-dot span {
      color: #878787;
    }

    .tab-content > .active {
      display: block;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    .tab-content {
      -ms-overflow-y: auto;

      .tab-pane {
        .list-group {
          margin-bottom: 10px;
          margin-right: 10px;

          .list-group-item {
            padding: 13px 0px;
            background-color: #ffffff;
            border: 0px solid #d2d8f1;
            border-radius: 0px;
            display: flex;
            width: 100%;
            justify-content: space-between;

            h6 {
              font-size: 12px;
              color: #1c285a;
              margin-bottom: 0px;
              line-height: 1.3;
            }

            p {
              font-size: 14px;
              color: #23d0f0;

              i {
                font-size: 12px;
                color: #1c285a;
                margin-right: 10px;
              }
            }

            p {
              span {
                font-weight: 500;
                font-size: 14px;
                color: #475467;

                strong {
                  color: black;
                }

              }
            }

            .date {
              color: #166b7b !important;
              font-weight: 500;
            }

            a {
              font-weight: 400;
              font-size: 14px;
              color: #475467;
            }
          }
        }
      }
    }
  }

  .add-more {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.tab-orange {
  .tabs {
    .nav {
      border-bottom: 0px solid #e0e0e0;
      margin-top: 20px;
      margin-bottom: 12px;
      box-shadow: RGB(255 255 255 / 10%) 0px 2px 0px 0px inset, RGB(50 50 93 / 25%) 0px 10px 31px -20px, RGB(0 0 0 / 30%) 0px 24px 2px -30px;
      padding: 0 14px;

      .nav-item {
        a {
          font-size: 14px;
          font-weight: 400;
          color: #878787;
          padding-left: 0px;
          padding-right: 0px;
          margin-right: 25px;
          padding-bottom: 8px;
        }
      }
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
      //border-bottom: 2px solid #c38c44;
      color: white;
      background-color: #E58F06;
      border-radius: 16px;
      padding: 8px 20px;
      //width: 110px;
      //height: 35px;
    }

    .tab-content {
      -ms-overflow-y: auto;

      .tab-pane {
        .list-group {
          margin-bottom: 5px;

          .list-group-item {
            padding: 10px 12px;
            background-color: #F5F5F5;
            border: 1px solid #d2d8f1;

            h6 {
              margin-bottom: 0px;
              line-height: 1.3;
              font-weight: 500;
              font-size: 14px;
              color: #4575EE;
            }

            h5 {
              margin-bottom: 0px;
              line-height: 1.3;
              font-weight: 600;
              font-size: 16px;
              color: #141944;
            }

            p {
              font-weight: 500;
              font-size: 12px;
              color: #878787;
            }

            .date {
              color: #166b7b !important;
              font-width: 500;
            }
          }
        }
      }
    }
  }

  @include xl {
    .tabs {
      .nav {
        .nav-item {
          a {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.tab-blue {
  position: relative;

  .tabs {
    .nav {
      border-bottom: 0px solid #e0e0e0;
      margin-top: 20px;
      margin-bottom: 20px;
      box-shadow: RGB(255 255 255 / 10%) 0px 2px 0px 0px inset, RGB(50 50 93 / 25%) 0px 10px 31px -20px, RGB(0 0 0 / 30%) 0px 24px 2px -30px;
      padding: 0 14px;

      .nav-item {
        a {
          font-size: 14px;
          font-weight: 400;
          color: #878787;
          padding-left: 0px;
          padding-right: 0px;
          margin-right: 25px;
          padding-bottom: 8px;
        }

        .notification-button {
          border-bottom: 0;
          color: black;
          background-color: #ffffff;
          font-size: 16px;
        }

        .notify-dot {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          margin-left: 10px;
          width: 26px;
          height: 19px;
          background: #E8E8E8;
          border-radius: 29px;

          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #878787;

          }
        }


      }

      .search-sort {
        padding-left: 22vh;

        .sort-by-header {
          p {
            padding-right: 7px;
          }
        }
      }
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
      // border-bottom: 2px solid #17a2b8;
      color: white;
      background-color: #11CDEF;
      border-radius: 16px;
      padding: 8px 20px;
    }


    .tab-content {
      -ms-overflow-y: auto;

      .tab-pane {
        .list-group {
          margin-bottom: 5px;

          .list-group-item {
            padding: 10px 12px;
            background: #F5F5F5;
            border: 1px solid #EBEBEB;
            // border-radius: 10px;

            h6 {
              margin-bottom: 0px;
              line-height: 1.3;
              font-weight: 500;
              font-size: 14px;
              color: #4575EE;
            }

            h5 {
              margin-bottom: 0px;
              line-height: 1.3;
              font-weight: 600;
              font-size: 16px;
              color: #141944;
            }


            p {
              font-weight: 500;
              font-size: 12px;
              color: #878787;
            }

            .date {
              color: #166b7b !important;
              font-width: 500;
            }
          }
        }
      }
    }
  }

  .add-more {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}


//Teachers Information page

.teachers-information {
  height: 88vh;
  position: relative;
  padding: 45px 30px 15px 42px;

  .title {
    display: flex;
    justify-content: space-between;
  }

  .tab-blue {
    position: initial;

    .tabs {
      .nav-pills {
        position: absolute;
        top: 20px;
        right: 50px;
        z-index: 1;
      }

      .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        // border-bottom: 2px solid #17a2b8;
        color: #E58F06;
        background-color: #FFF3E0;
        border-radius: 54px;
        padding: 8px 20px;
        font-weight: 600;
        font-size: 16px;
      }

      .tab-content {
        position: relative;
        top: 8vh;

        .tab-pane {
          .table-light {
            .table {
              border-collapse: collapse;

              thead {
                border: none !important;
              }

              tbody {
                tr {
                  td:first-child {
                    font-weight: 500;
                    font-size: 14px;
                    color: #141944;
                  }

                  td {
                    border-top: none !important;
                    border-bottom: none !important;
                    padding-left: 20px;
                  }
                }
              }
            }

            h3 {
              font-weight: 500 !important;
              font-size: 14px !important;
              color: #475467 !important;
              margin-left: 48px;
            }
          }

          .tab-heading {
            p {
              font-weight: 600;
              font-size: 20px;
              color: #141944;
              margin-bottom: 0px;
              position: absolute;
              top: -107px;
              left: 23px;
              z-index: 1;
            }

            .teachers-info-tab {
              .table {
                tbody {
                  tr:first-child {
                    border-bottom: 0.7px solid #CECECE;

                    td {
                      font-weight: 600;
                      font-size: 16px;
                      color: #475467;
                    }
                  }

                  tr {
                    td {

                      button {
                        background: none;
                        border: none;
                        text-decoration: underline;
                        margin-left: 15px;
                      }
                    }
                  }
                }
              }
            }
          }

          .room-information {
            height: 66vh;

            thead {
              th:first-child {
                width: 44%;
              }

              th {
                width: 18%;
              }
            }

            tbody {
              tr {
                td {
                  button {
                    font-size: 14px;
                    color: #071036;
                    border-color: #071036;
                    padding: 7px 8px;
                    width: 95px !important;
                    height: auto;

                    svg path {
                      fill: #071036;
                      padding-right: 5px;
                    }

                    &:hover {
                      background: #071036;
                      color: #ffffff;

                      svg path {
                        fill: #FFFFFF;
                        transition: 0.3s;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}

//Student Profile
.student-profile {
  #layoutSidenav_nav {
    display: none;
  }
}


//Events View Page

.card-default {

}

.event-detail {
  height: 90vh;
  overflow: overlay;
  padding: 0px;

  .eventview-header {
    padding: 18px 19px 0px 40px;
    border-bottom: 0.7px solid #C5C5C5;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);

    .block-group {
      justify-content: space-between;
      margin-top: 20px;
      padding-bottom: 40px;


      .block-25 {
        width: calc(26% - 0px);

        .event-title {
          font-weight: 400;
          font-size: 16px;
          color: #475467;
        }

        .event-desc {
          font-weight: 600;
          font-size: 17px;
          color: #141944;
        }
      }

      .block-40 {
        width: calc(40% - 110px);

        .event-title {
          font-weight: 400;
          font-size: 16px;
          color: #475467;
        }

        .event-desc {
          font-weight: 600;
          font-size: 17px;
          color: #141944;
        }
      }

    }

  }


  .comment-section {
    position: relative;
    height: 266px;
    background: #FFFFFF;
    border: 1.5px solid #E3E3E3;
    border-radius: 12px;
    margin: 20px 38px;
    padding: 15px 10px 10px 10px;
    overflow: auto;

    .add-comment-area {
      bottom: 0;
      position: absolute;
      width: 98%;
      margin-bottom: 15px;


      .input-default {
        input {
          background: #FFFFFF;
          border: 1px solid #E3E3E3;
          border-radius: 30px;
        }
      }

      .avatar {

        img {
          //box-shadow: 0px 4px 6px 4px RGB(146 153 177/20%);
        }
      }
    }

    .comment-area-event {
      display: flex;
      justify-content: space-between;
      width: 99%;

      .avatar {
        margin-right: 10px;
      }

      .card-alt-event {

        .card-p-event {
          margin: 0px;
        }

        .card-p-date {
          margin-top: 5px;
          font-weight: 300;
          font-size: 12px;
          color: #878787;

        }

      }
    }
  }

  ul {
    list-style: none;

  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    border-radius: 25px;
    margin-right: 20px;
    background: #3642551f;
    color: #81849b;
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background: #1c285a;
    color: #FFFFFF;
  }
}


.invitation-status {
  margin: 32px 0px 28px 38px;

  p {

    font-weight: 500;
    font-size: 18px;
    color: #141944;
  }

}

.no-members-available {

  svg {
    transform: scale(1);
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #475467;
    display: flex;
    justify-content: center;
  }
}


//Events View Page

.event-news {
  padding: 0px;

}


/* Global Media
--------------------*/
.display-lg {
  display: none;
}

/* Pages
--------------------*/
/* Grade Book */
.grade-book {
  #gradeBook {
    padding: 15px 0px;
    height: 90vh;
    overflow: auto;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 50px 0px 50px;

      .card-header {
        p {
          font-weight: 600;
          font-size: 20px;
          color: #141944;
          margin-bottom: 0px;
        }
      }

      button {
        display: flex;
        align-items: center;
        height: 38px;
        width: 122.5px;
        justify-content: center;

      }
    }

    .table-col-scroll-custom {
      .table-light {
        tbody {
          tr {
            td {
              position: relative;
              padding-left: 20px;

              .bg-c-blue-light {
                font-weight: 500;
                font-size: 12px;
                color: #4575EE;
                height: 20px;
                align-items: center;
                display: flex;

                &:hover {
                  background-color: #d1d9ff;
                }
              }

              .btn-show {
                position: absolute;
                right: 5px;

              }
            }
          }

          td:last-child {
            padding-left: 15px;

            .average-marks {
              display: none;
            }
          }

        }
      }
    }

    .table-col-scroll-custom {
      /* width */
      ::-webkit-scrollbar {
        width: 3px !important;
        height: 5px;
        cursor: pointer;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #10193e !important;
        cursor: pointer;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #5b6685;
        border-radius: 50px;
        cursor: pointer;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #7b8ab5;
        cursor: pointer !important;
      }

      table {
        td {
          max-width: 150px !important;
          overflow-x: auto;
          color: #475467;

          .makeStyles-root-1 {
            width: 500px;
          }
        }
      }
    }

  }

  .grading-modal {
    .modal-content {
      .modal-body {
        padding: 18px 0px 20px 0px !important;
        width: 80%;

        .popup-header {
          padding-bottom: 12px;

          p {
            font-weight: 500;
            font-size: 18px;
            color: #475467;
          }

        }

        tr {
          display: flex;
          justify-content: space-between;
        }

        table {
          width: 100%;

          thead {
            tr {
              th {
                font-weight: 400;
                font-size: 14px;
                color: #141944;
                padding: 0px 0px 15px 0px !important;
              }
            }
          }

          tbody {
            tr {
              margin: 7px 0px;

              td {
                font-weight: 400;
                font-size: 14px;
                color: #475467;
                padding: 0px 0px 0px 0px !important;

                td {
                  padding-right: 5px !important;
                }
              }
            }
          }
        }
      }

      .footer-button {
        margin-bottom: 25px;

        button {
          width: 62px;
          border-radius: 50px;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  .grading-finalize-modal {
    .modal-content {
      .modal-header {
        margin-top: 15px;
      }

      .modal-body {
        padding: 5px 0px 20px 0px !important;
        width: 80%;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
      }

      .footer-button {
        margin-bottom: 25px;

        button {
          border-radius: 50px;
          font-weight: 400;
          font-size: 14px;
          margin-right: 20px;
        }
      }
    }
  }
}


//AttendancePage
.attendance-page {
  padding: 1px 0px;
  height: 89vh;
  overflow: auto;

  .title {
    border-bottom: 0.7px solid #C5C5C5;
    padding: 15px 35px 25px 35px;

    .card-header {

      p {
        font-weight: 600;
        font-size: 18px;
        color: #141944;
        margin-bottom: 0px;

      }
    }

    .bg-c-blue-light {
      font-weight: 500;
      font-size: 14px;
      color: #4575EE;

      svg {
        margin-right: 10px;

      }
    }
  }

  .row {
    margin-right: 0px;
    margin-left: 0px;
    height: 100%;

    .col-3 {
      flex: 0 0 27%;
      max-width: 27%;
      border-right: 0.7px solid #C5C5C5;
      margin-top: 0px;
    }

    .col-8 {
      flex: 0 0 70%;
      max-width: 70%;

      .no-schedule {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 70%;

        img {
          width: 190px;
          height: 173px;
          margin-bottom: 20px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          color: #475467;
        }
      }

      .table-light {
        margin: 15px 0px 0px 0px;
        height: 72vh;

        .table {
          tbody {
            td:nth-child(3), td:nth-child(5), td:nth-child(6) {
              padding-left: 22px;
            }

            td:nth-child(2) {
              padding-left: 22px;
            }

            td:nth-child(4) {
              padding-left: 22px;
            }
          }
        }
      }
    }
  }
}


//AttendancePageTableCheckboxes
.btn-present {
  border: 2px solid #30B264;
  background-color: rgba(0, 100, 0, 0);
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
  padding: 8px 8px;
  justify-content: center;

  i {
    display: none;;
  }
}

.btn-present-check {
  border: 2px solid #30B264;
  background-color: #30B264;
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
  padding: 8px 8px;
  justify-content: center;

  i {
    font-size: 10px;
    color: #ffffff;
  }
}

.btn-late {
  border: 2px solid #4575EE;
  background-color: rgba(0, 100, 0, 0);
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
  padding: 8px 8px;
  justify-content: center;

  i {
    display: none;
  }
}

.btn-late-check {
  border: 2px solid #4575EE;
  background-color: #4575EE;
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
  padding: 8px 8px;
  justify-content: center;

  i {
    font-size: 10px;
    color: #ffffff;
  }
}

.btn-absent {
  border: 2px solid #D81414;
  background-color: rgba(0, 100, 0, 0);
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
  padding: 8px 8px;
  justify-content: center;

  i {
    display: none;
  }
}

.btn-absent-check {
  border: 2px solid #D81414;
  background-color: #D81414;
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
  padding: 8px 8px;
  justify-content: center;

  i {
    font-size: 10px;
    color: #ffffff;
  }
}

.btn-excused {
  border: 2px solid #E58F06;
  background-color: rgba(229, 143, 6, 0);
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
  padding: 8px 8px;
  justify-content: center;

  i {
    display: none;
  }
}

.btn-excused-check {
  border: 2px solid #E58F06;
  background-color: #E58F06;
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
  padding: 8px 8px;
  justify-content: center;

  i {
    font-size: 10px;
    color: #ffffff;
  }
}

//AttendanceReport
.attendance-report {
  padding: 1px 0px;
  height: 89vh;

  .title {
    padding: 23px 35px 0px 35px;

    .card-header {
      width: 85%;
      justify-content: space-between;

      p {
        font-weight: 600;
        font-size: 18px;
        color: #141944;
        margin-bottom: 0px;

      }
    }

    .bg-c-blue-light {
      font-weight: 500;
      font-size: 14px;
      color: #4575EE;

      svg {
        margin-right: 10px;

      }
    }
  }

  .table-light {
    position: relative;
    height: 83%;

    tbody {
      tr {
        td:nth-child(2) {
          padding-left: 18px;
        }

        td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6) {
          padding-left: 35px;
        }
      }

    }
  }
}


/* All Notification */
#allNotificationPage {
  .card-default {
    padding-left: 0;
    padding-right: 0;

    .card-header {
      padding: 0 46px;
    }

    .tab-default {
      .tabs {
        .tab-content {
          .tab-pane {
            .list-group {
              margin-bottom: 4px;
              margin-right: 0px;

              &:nth-child(odd) {
                background-color: #F7F7F7;
              }

              .list-group-item {
                padding: 10px 36px;
                margin: 0;
                background-color: transparent;

              }
            }
          }
        }
      }

      .nav {
        padding: 0 46px;
      }
    }
  }
}

//TableStriped
//.table-striped{
//  tbody tr:nth-child(odd) {
//    background-color: #E3E3E3;
//  }
//}


@include xl {
  body {
    font-size: 0.8rem;
  }

  h1, .h1 {
    font-size: 2.5rem;
  }

  h2, .h2 {
    font-size: 2rem;
  }

  h3, .h3 {
    font-size: 1.75rem;
  }

  h4, .h4 {
    font-size: 1.5rem;
  }

  h5, .h5 {
    font-size: 1.1rem;
  }

  h6, .h6 {
    font-size: 1rem;
  }
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .custom-width-full {
    width: 100%;
    padding: 0px 46px;
  }

  .display-lg {
    display: block;
  }

}

.result-area {
  .block-group {
    .block-25 {
      width: calc(25% - 16px);
    }
  }
}


.ad-sense-position-sidenav {
  position: absolute;
  left: 44px;
  bottom: 15px;
  width: 200px;
  height: 200px !important;
  //background-color: #00c4ca;
  .adsbygoogle {
    width: 200px !important;
    height: 200px !important;
  }
}

@media screen and (max-width: 1880px) {
  .ad-sense-position-sidenav {
    width: 190px;
    height: 190px;
  }
}

@media screen and (max-height: 813px) {
  .sb-sidenav-menu {

    max-height: 61vh !important;
  }
}

@media screen and (max-height: 737px) {
  .sb-sidenav-menu {

    max-height: 58vh !important;
  }
}

/* Modals
--------------*/

.modal-content {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.modal-content-without-border {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.modal-header {
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .circle-icon {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background: rgba(216, 20, 20, 0.1);
    display: flex;
    align-items: center;
  }
}

.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834) url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center no-repeat;
  z-index: 1;
}

.spinner-reg {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


// Pagination functionality included

.page-navigation {
  padding: 10px 282px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #E3E3E3;;

}

.paginationBttns {

  list-style: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  color: #8d8d8d;
  background-color: transparent;
  font-weight: 600;
}

.previousBttn {
  border: none;
  font-size: 14px;
  color: #8d8d8d;
  background-color: transparent;
}

.nextBttn {
  border: none;
  font-size: 14px;
  color: #8d8d8d;
  background-color: transparent;
}

.paginationBttns a:hover {
  color: #525252;
}

.paginationActive a {

  font-weight: 700;
  color: #212121;
}


//Global Repository

.global-repository {
  padding: 0px;

  .title-header {
    width: 100%;
    padding: 26px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-weight: 500;
      font-size: 18px;
      color: #475467;
      padding-left: 9px;
      margin-bottom: 0px;
    }

    .upload-materials {
      button {
        display: flex;
        justify-content: center;
        border: 0.5px dashed #4575EE;
        border-radius: 7px;
        align-items: center;
        color: #4575EE;
        font-weight: 400;
        font-size: 14px;
        color: #4575EE;
        padding: 20px 25px;

        svg {
          margin-right: 10px;
        }

        &:hover {
          background-color: #d4deff;
        }
      }
    }

    .sort-by {
      .dropdown-menu {
        top: 100%;
      }
    }
  }


  .table-light {
    tbody {
      tr {
        td {
          padding-left: 20px;

          .bg-c-blue-light {
            &:hover {
              background-color: #d7e1ff;
            }
          }

          .bg-c-red-light {
            &:hover {
              background-color: #ffd7d6;
            }
          }

          .view-items-btn {
            width: 40%;
            color: #4575EE;
            font-size: 14px;
            font-weight: 500;

            &:hover {
              background-color: #d6e1ff !important;
            }
          }
        }
      }
    }
  }
  .modal-dialog {
      max-width: 500px !important;
      margin: 0px auto;
      .modal-content {
        //align-items: flex-end;
        display: flex;
        //padding-right: 20px;
        .modal-body {
          font-size: 15px;
          font-weight: 300;
          color: #364255;
          padding: 20px 44px 0px 45px !important;
          width: 100% !important;

          .attachment-box {
            .bg-c-blue-light {
              height: 30px;
              width: 30px;
              padding: 0px;

              &:hover {
                background-color: #cad7ff !important;
              }
            }

            .bg-c-red-light {
              height: 30px;
              width: 30px;
              padding: 0px;

              &:hover {
                background-color: #ffccc7;
              }
            }

            p {
              margin-bottom: 0px;
              font-weight: 400;
              font-size: 14px;
              color: #475467;
            }

          }

          .footer-button {
            margin-bottom: 20px;

            button {
              height: 29px;
              width: 25%;
              padding: 0px;
              border-radius: 28px !important;
            }
          }
        }
        .modal-footer{
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
  }
}

.table-striped tbody tr:nth-child(even) {
  background-color: rgba(237, 237, 237, 0.45);
}

.table-striped tbody tr:nth-child(odd) {
  background-color: inherit;
}

.table-hover tbody tr:hover {
  background-color: #fff7ea;
}

.global-repository-uploads {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 1px;
  height: 89vh;

  .title {
    border-bottom: 0.7px solid #C5C5C5;
    padding: 15px 42px 30px 44px;

    p {
      font-weight: 600;
      font-size: 20px;
      color: #141944;
      margin-bottom: 0px;
    }
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .material-title {
      .form-group {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 20vw;
        padding: 55px 0px 0px 0px;

        label {
          font-weight: 500;
          font-size: 16px;
          color: #475467;
          margin-bottom: 15px;
        }

        .input-group {
          .form-control {
            background: #ffffff;
            border: 1px solid #bebebe;
          }

          .form-control::placeholder {
            color: #696969;
            font-size: 15px;
            padding-left: 10px;
          }
        }
      }
    }

    .choose-file {
      margin-top: 10px;
      width: 20vw;

      .form-group {
        .position-relative {
          .custom-file-label {
            width: 20vw;
          }
        }
      }

      .submit-area {
        margin-top: 26px !important;
        max-height: 38vh;
        overflow-y: auto;

        p {
          margin-bottom: 0px;
        }

        .btn {
          .fa-close {
            color: #dc1515;
          }
        }
      }
    }
  }
}


.title-header {
  border-bottom: 0.7px solid #C5C5C5;
  padding: 25px 42px 30px 44px;
  display: flex;
  justify-content: space-between;

  p {
    font-weight: 600;
    font-size: 20px;
    color: #141944;
    margin-bottom: 0px;
  }
}

//ProgramList
.program-list {
  padding: 1px 0px;
  height: 89vh;

  .title {
    margin-top: 15px;
  }

  .table-light {
    tbody {
      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }
}

//ProgramNew
.program-new {
  padding: 1px 0px;
  height: 89vh;

  .form-row {
    padding: 24px 40px 10px 40px;

    .col-4 {

      .form-group {
        .form-control {
          background: #fdfdfd;
        }
      }
    }
  }

  .col-btn {
    padding-left: 35px;

    .loader {
      top: 160px;
      left: 150%;
      position: absolute;
    }
  }
}

//FacultyList
.faculty-list {
  padding: 1px 0px;
  height: 89vh;

  .title-header {
    margin-top: 15px;

    .btn-purple-md {
      width: auto !important;
      font-weight: 500;
      font-size: 16px;
      height: 46px;
      display: flex;
      align-items: center;
      padding: 0px 20px;

      svg {
        margin-right: 8px;

        path {
          fill: #ffffff;
          transition: fill 0.3s ease;
        }
      }

      &:hover {
        svg path {
          fill: #1c285a !important;
        }
      }
    }
  }

  .table-light {
    th {
      border-top: 1px solid #dee2e6 !important;
    }

    tbody {
      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }
}

//FacultyNew
.faculty-new {
  padding: 1px 0px;
  height: 89vh;

  .row {
    margin: 25px 0px 0px 28px;
  }
}

//FacultyEdit
.faculty-edit {
  padding: 1px 0px;
  height: 89vh;

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;

    .card-body {
      width: 30%;

      button {
        width: 100%;
        height: 40px;
        font-size: 15px;
        font-weight: 400;
      }

      .table-responsive {
        thead {
          th {
            padding: 10px 0px 0px 0px !important;
            border: 0px solid black !important;
          }
        }

        tbody {
          td {
            padding: 10px 0px 0px 0px !important;
            border: 0px solid black !important;
          }
        }
      }
    }
  }
}

//DepartmentList
.department-list {
  padding: 1px 0px;
  height: 89vh;

  .title-header {
    margin-top: 5px;

    .btn-purple-md {
      width: auto !important;
      font-weight: 500;
      font-size: 16px;
      height: 46px;
      display: flex;
      align-items: center;
      padding: 0px 20px;

      svg {
        margin-right: 8px;

        path {
          fill: #ffffff;
          transition: fill 0.3s ease;
        }
      }

      &:hover {
        svg path {
          fill: #1c285a !important;
        }
      }
    }
  }

  .table-light {
    th {
      border-top: 1px solid #dee2e6 !important;
    }

    tbody {
      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }
}

//DepartmentNew
.department-new {
  padding: 1px 0px;
  height: 89vh;

  .title-header {
    margin-top: 5px;
  }

  .form-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 4%;

    .col-4 {
      .form-group {
        button {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
        }
      }
    }
  }
}


//SemesterNew
.semester-new {
  padding: 1px 0px;
  height: 89vh;

  .table-light {
    height: 55vh;

    tbody {
      tr {
        td {
          border-color: #E3E3E3;
          padding-top: 29px;
          background: white;
        }
      }

      .text-left {
        margin-left: 9%;
        height: 69px;
        width: 270%;

        button {
          width: 32%;
          height: 38px;
          font-size: 14px;
        }
      }
    }
  }
}


//SemesterList
.semester-list {
  padding: 1px 0px;
  height: 89vh;

  .title {
    margin-top: 15px;
  }

  .table-light {
    tbody {
      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }
}

//SemesterView
.semester-view {
  padding: 1px 0px;
  height: 89vh;

  .title {
    margin-top: 15px;
  }

  .table-light {
    tbody {
      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }
}

//SemesterEdit
.semester-edit {
  padding: 1px 0px;
  height: 89vh;

  .table-light {
    height: 55vh;

    tbody {
      tr {
        td {
          border-color: #E3E3E3;
          padding-top: 29px;
          background: white;
        }
      }

      .text-left {
        margin-left: 9%;
        height: 69px;
        width: 270%;

        button {
          width: 32%;
          height: 38px;
          font-size: 14px;
        }
      }
    }
  }
}


.bg-c-red-light {
  &:hover {
    background-color: #ffd9d8;
  }
}

.bg-c-light-orange {
  &:hover {
    background-color: #ffecc3 !important;
  }
}

.bg-c-light-green {
  &:hover {
    background-color: rgba(48, 178, 100, 0.4) !important;
  }
}

.bg-c-blue-light {
  &:hover {
    background-color: #d5deff;
  }
}

//gradinglistbychannel
.gradinglistbychannel {
  padding: 1px 0px;
  height: 89vh;

  .title {
    margin-top: 20px;
    padding: 6px 0px 25px 24px;

    p {
      font-weight: 500;
      font-size: 20px;
      color: #475467;
      margin-bottom: 0px;
      margin-top: 2px;
    }
  }

  .table-light {
    tbody {
      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }
}

// Employee List Page
.employee-list {
  padding: 1px 0px;

  .title {
    width: 100%;
    padding: 26px 50px;
    display: flex;
    justify-content: space-between;

    p {
      font-weight: 500;
      font-size: 18px;
      color: #475467;
      padding-left: 9px;
      margin-bottom: 0px;
    }
  }

  .table-light {
    tbody {
      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }

  .page-navigation {
    padding: 10px 32vw;
  }
}

//EmployeeRegistrationUpload
.employee-registration-upload {
  padding: 1px 0px;
  height: 89vh;

  .form-row {
    padding: 24px 40px;

    .col-4 {
      .form-group {
        .form-control {
          background: #ebebeb;
        }
      }
    }
  }
}

//StudentList
.student-list {
  padding: 1px 0px;

  .table-light {
    tbody {
      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }
}

//StudentDetails
.student-details {
  padding: 1px 0px;

  .title-header {
    border-bottom: 0.7px solid #C5C5C5;
    padding: 23px 42px 10px 25px;
  }

  .table-light {
    tbody {
      tr {
        td {
          padding-left: 20px;
        }

        td:nth-child(2) {
          padding-left: 40px;
          border-left: 1px solid #C5C5C5;
        }

        td:nth-child(1) {
          font-weight: 500;
        }

      }
    }
  }
}

//StudentEditForm
.student-edit-form {
  padding: 15px 0px 0px 0px;

  .container {
    width: 100% !important;
    padding-right: 15px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: 0px;

    .dropzone {
      border: 1px dashed #a1c2ff;
      align-items: center;

      img {
        height: 130px !important;
        width: 130px !important;
      }

      p {
        margin-top: 15px;
        margin-bottom: 0rem;
        padding: 0px 5px;
        color: #f38d8d;
      }

      &:hover {
        background-color: #f3f3f3;
        transition: 0.3s;
      }
    }
  }

  .table-light {
    tbody {
      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }
}

//StudentCourseHistory
.student-course-history {
  padding: 1px 0px;

  .title-header {
    padding: 25px 42px 23px 44px;
  }

  .table-light {
    margin-bottom: 0px !important;

    tbody {
      tr {
        td {
          padding-left: 20px;
        }
      }
    }
  }

  .studentcoursehistory-body {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: none;
  }
}

//AllAssignments
.all-assessment-list {
  padding: 1px 0px;
  height: 89vh;

  .title {
    margin-top: 15px;
  }

  .table-light {
    height: 100%;

    tr {
      td {
        padding-left: 20px;
      }

      p {
        margin-bottom: 0px;
      }
    }

  }

  .table-striped tr:nth-child(even) {
    background-color: rgba(237, 237, 237, 0.45);
  }

  .table-hover tr:hover {
    background-color: #fff7ea;
  }
}


// Create Events page

.create-event {
  padding: 0px;
  position: relative;

  .loader {
    position: absolute;
  }

  .title {
    width: 100%;
    padding: 18px 51px;
    border-bottom: 0.7px solid #E3E3E3;

    p {
      font-weight: 500;
      font-size: 18px;
      color: #475467;
      padding-left: 12px;
      margin-bottom: 0px;
    }
  }

  .event-names {
    margin: 20px 48px 1px 48px;

    .form-row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 0px 0px 0px 2px;
      justify-content: space-between;

      .col-3 {
        flex: 0 0 24%;
      }

      .form-group {
        label {
          font-weight: 500;
          font-size: 16px;
          color: #475467;
          padding-left: 18px;
        }

        .form-control {
          border: 1.5px solid #dadae0;

        }
      }

      .event-description {
        width: 100%;

        .form-control {
          margin-top: 20px;
          background: #F5F5F5;
          border: 1px solid #CDCDCD;
          border-radius: 10px;
          height: 108px;

        }
      }
    }

    p {
      color: #475467;
      padding-left: 20px;
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 20px;
      color: #475467;
    }
  }

  .table-light {
    background-color: #FFFFFF !important;
    position: relative;
    margin: 0px 40px;
    //padding: 0px 14px;
    //height: 598px;
    border: 1px solid #D9D9D9;
    border-radius: 10px !important;
    box-shadow: none !important;

    tbody {
      tr:hover {
        background-color: #fafafa;
      }
    }

    thead {
      background-color: black;
    }


    h6 {
      font-weight: 500;
      font-size: 20px;
      color: #475467;
    }
  }
}

//Change Password
.change-password {
  padding: 1px 0px;
  height: 89vh;

  .title-header {
    margin-top: 5px;
  }

  .pass-box {
    height: 80%;
  }

  .changepass-box {
    width: 30%;
    border: 1px solid #eaeaea;
    background-color: #e1e1e1;
    background-image: linear-gradient(to bottom, #fffbf8, #ffffff);
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 50px;

    p {
      color: #a1a1a1;
      font-weight: 400;
      margin: 20px 0px;
    }

    button {
      margin-top: 25px;
    }

    .form-control {
      border: 1px solid #b3b3b3;

      &::placeholder {
        color: #bdbdbd;
      }
    }
  }

  .password-box {
    position: relative;

    .password-icon {
      position: absolute;
      top: 63%;
      right: 15px;
      transform: translateY(20%);
      fill: #878787;
      cursor: pointer;
    }
  }

}

.MuiTable-root {
  //border: 1px solid red;
}

.MuiTableHead-root {
  background-color: blue;
}

.MuiTableRow-root {
  background-color: transparent;

}

.tss-1qtl85h-MUIDataTableBodyCell-root {
  font-weight: 400;
  font-size: 14px;
  color: #475467;
}

.MuiTablePagination-root:last-child {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}


// Student List Pagination

.student-list {
  .page-navigation {
    padding: 10px 32vw;
  }
}


//All Events Search box
.all-events{
  .position-relative {
    position: relative;
  }

  .form-control-feedback {
    position: absolute;
    transform: translateY(64%);
    left: 6px;
    z-index: 2;
  }

}


// Confirmation Delete Pop-up

.modal-dialog {
  .modal-content {
    .modal-header {
      margin-top: 25px;

      .modal-body {
        font-weight: 500;
        font-size: 14px;
        color: #222222;
      }
    }

    .modal-footer {
      border-top: 0px;
      padding-top: 0px;
      margin-bottom: 20px;

      button {

        width: 100px;
        height: 38px;
        border: none;
        color: white;
        background: #D81414;
        border-radius: 46px;

        &:hover {
          background: #ec4949;
          transition: 0.3s;
        }

        &:last-child {
          font-size: 14px;
          color: #475467;
          border-radius: 46px;
          background: #ffffff;
          border: 1px solid #475467;

          &:hover {
            background: #e8e8e8;
            transition: 0.3s;
          }
        }
      }
    }
  }
}

//Create Post Input Text Area

//.expandable-textarea {
//  resize: none;
//  overflow-y: hidden !important;
//}
//.expandable-textarea.max-height {
//  max-height: 20px !important;
//}

//Responsive Design Classes

@media only screen and (min-width: 1600px) {
  .container-responsive {
    max-width: 1900px;
    //margin: 0 auto !important;
  }
}
