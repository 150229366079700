.rdw-storybook-root {
    /*margin: 50px;*/
    width: 100%;
}

.rdw-storybook-wrapper {
}

.rdw-storybook-editor {
    border: 1px solid #F1F1F1;
    padding: 5px;
    border-radius: 2px;
    height: 200px;
    background: white;
}

.rdw-storybook-editor-small {
    border: 1px solid #F1F1F1;
    padding: 5px;
    border-radius: 2px;
    height: 100px;
    background: white;
}

.rdw-storybook-editor-auto {
    border: 1px solid #F1F1F1;
    padding: 5px;
    border-radius: 2px;
    height: auto;
    background: white;
}

.rdw-storybook-toolbar {
    top: -130px;
}

.rdw-storybook-textarea {
    margin-top: 20px;
    resize: none;
    width: 100%;
    border: 1px solid #F1F1F1;
    height: 100px;
}

.rdw-storybook-custom-option {
    border: 1px solid #f1f1f1;
    padding: 3px 10px;
    height: 22px;
}