
.message-box {
    width: 100%;
    /*height: 150px;*/
    padding: 4px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #f8f8f8;
    resize: none;

}
