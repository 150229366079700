.file-display {
    display: flex !important;
}

.code-input {
    pointer-events: none;
    background: #eaeaea !important;
    width: 150px !important;
    height: 50px !important;
}

.phone-input {
    height: 50px;
}

.spinner {
    margin: 0 auto;
    text-align: center;
    padding: 15px;
}

.spinner-border {
    height: 50px;
    width: 50px;
}

.error_text_danger {
    color: red;
}

a.resend_otp_link {
    cursor: pointer;
}

.header-tnc {
    width: 100%;
}

.header-tnc h3 {
    text-align: left;
    font-weight: 600;
    font-size: 28px;
    color: #29394e;
    margin-bottom: 20px;
}

p.update-tnc {
    font-size: 13px;
    color: #29394e;
    text-align: left;
    margin-bottom: 8px;
}

.header-tnc h2 {
    color: #29394e;
    text-align: left;
    font-size: 14px;
}

.header-otp h2 {
    font-size: 30px;
    font-weight: 600;
}

.agree {
    float: right;
    margin-top: 40px;
}

@media only screen and (max-width: 480px) {
    .header-tnc h3 {
        text-align: center;
        font-size: 18px;
        margin-bottom: 5px;
    }

    .header-otp h2 {
        font-size: 25px;
        font-weight: 600;
    }
}

.custom-width-full {
    width: 100%;
    padding: 0px 46px;
}

.custom-width-sidebar {
    width: 100%;
    padding-left: 28px;
}

span.spinner-border.spinner-border-sm {
    height: 25px;
    width: 25px;
}
