@import "../../style/css/mixins&variable";

.text-small {
  font-size: 0.9rem;
}

.messages-box {
  height: 67vh;
  overflow-y: scroll;
  padding: 0;
}




.btn-outline-light {
  padding: 10px;
  min-width: 145px;
  line-height: 1;
}

.bg-dark {
  background: #1c285a !important;
}
.bg-grey {
  background: #c3c7d5;
}

.chat-form {
  .input-group{
    width: 94%;
    .form-control{
      color: #090909 !important;
      background: #FFFFFF !important;
      border: 1px solid #D0D5DD !important;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 64px !important;
      width: 99% !important;
      height: 50px;
      flex: none;
    }
  }

  ::-webkit-input-placeholder { /* Edge */
    color: #bfbfbf;
  }

  :-ms-input-placeholder { /* Internet Explorer */
    color: #bfbfbf;
  }

  ::placeholder {
    color: #bfbfbf;
  }
  .form-control {
    color: #FFFFFF;
    .form-control:focus {
      color: #FFFFFF;
    }
  }
  .btn-link {
    padding: 0.375rem 2rem;
    i {
      color: #FFFFFF;
    }
  }
}

.h-70vh {
  height: 70vh;
}

.rounded-lg {
  border-radius: 0.5rem;
}

input::placeholder {
  font-size: 0.9rem;
  color: #999;
}
input {
  color: #999;
}

//Chat new CSS

.left-message-body {
  //border-right: 1px solid #CBCBCB;
  .left-section-heading{
    padding: 18px 0px 18px 17px;
    background-color: #FFFBF6;
    .form-control{
      background: #F9F9F9 !important;
      border: 1px solid #c8c8c8 !important;
    }
    p{
      color: #494949;
    }
    .has-search input[type="text"]::placeholder {
      color: #b0b0b0;
      font-style: italic;
    }
  }
}

.right-message-body{
  .title-avatar{
    padding: 18px 0px 12px 17px;
    background-color: #FFFBF6;
    .name{
      p{
        margin-bottom: 0px;
        margin-left: 10px;
        font-weight: 600;
        font-size: 20px;
        color: #222222;
      }
    }
  }
  .chat-box {
    height: 71vh;
    overflow-y: scroll;
    padding: 1.5rem 1.5rem !important;
    background-color: #EFE9E1;

    .media {
      img {
        object-fit: cover;
        width: 35px;
        height: 35px;
      }
      .media-body{
        .sender-messagebox {
          padding: 10px 15px 10px 10px;
          margin-bottom: 10px;
          background: #FFFFFF;
          border-radius: 0px 8px 8px 8px;
          max-width: 31vw;
          p{
            font-weight: 400;
            font-size: 16px;
            color: #222222;
            margin-bottom: 0px;
          }
        }
        .receiver-messagebox{
          padding: 10px 15px 10px 10px;
          margin-bottom: 10px;
          background: #FF1616;
          border-radius: 8px 0px 8px 8px;
          max-width: 31vw;
          p{
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            margin-bottom: 0px;
          }
        }
      }
    }
    .form-group {
      label{
        font-weight: 400;
        font-size: 14px;
        color: #475467;
      }
      .form-control {
        height: 45px;
        background: #F3F3F3;
        border: 1px solid #E7E7E7 !important;

        &::placeholder {
          color: #bfbfbf;
        }

        &:focus {
          color: #FFFFFF;
        }
      }

      &::-webkit-input-placeholder { /* Edge */
        color: #bfbfbf;
      }

      &:-ms-input-placeholder { /* Internet Explorer */
        color: #bfbfbf;
      }

    }
    .col-12{
      .col-12{
        .form-control{
          height: 200px;
          border-radius: 10px !important;
        }
        .btn-red-md{
          padding: 0px 10px;
          height: 38px;
          font-weight: 400;
          font-size: 14px;
        }
        .btn:disabled {
          opacity: .65;
          pointer-events: none;
        }
        .btn-cancel{
          padding: 0px 10px;
          height: 38px;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
  .chat-form{
    background-color: #FFFBF6;
  }
}
.create-new-chat{
  .title-avatar {
    padding: 70px 0px 12px 17px;
    border-bottom: 0px solid #CBCBCB;
  }
}


.media-body {
  .rounded {
    border-radius: 15px !important;
  }
 h6 {
   font-weight: 500;
   font-size: 18px;
   color: #222222;
   margin-bottom: 5px;
 }
  p {
    color: #FFFFFF;
  }
  span{
    display: inline;
    font-size: 14px;
    color: #4575EE;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 255px;
  }
}



.media {
  width: max-content;
  img {
    object-fit: cover;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border: 0px solid #f1f3f9;
    border-radius: 50%;
    font-size: 12px;
  }
}




@media only screen and (max-width: 863px) {
 .left-message-body {
   .left-section-heading {
     display: flex;
     flex-direction: column !important;
   }
 }
}

@media only screen and (max-width: 741px) {
  .messages-box{
    .media-body{
      display: none;
    }
    .unread-time{
      display: none;
    }
  }
}



.list-group .list-group-item {
  background: #ffffff;
}

#contentAreaChatPage {
  display: flex;

  .form-control{
    color: #090909;
    border: 0px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    background: #F0F2F5;
    border-radius: 40px;
    flex: none;
  }

  .block-center {
    width: calc(100% - 15px);
    padding: 0px;

    .card-default{
      padding: 15px 15px;
      margin-bottom: 15px;
      background-color: #8d8d8d;
      border-radius: 20px;
    }

    .title-header{
      border-bottom: 0px solid #C5C5C5;
      padding: 16px 0px 0px 30px;
      .card-header{
        background-color: transparent;
      }
    }

    .list-group .list-group-item {
      padding: 10px 30px;
      border-radius: 0px !important;
      border: 0;
      background-color: #F6FAFC;
      border-bottom: 1px solid #e2e2e2;
      .unread-time{
        span{
          margin-right: 15px;
          font-weight: 300;
          font-size: 14px;
          color: #878787;
        }
      }
    }
    .list-group .list-group-item:hover {
      background-color: #fffbf6 !important;
    }
    .list-group {
      margin-bottom: 0;
    }

    .add-post-area {
      display: flex;

      .input-default {
        width: 100%;

        &::placeholder {
          color: red;
        }

        .form-control {
          overflow: hidden;
          &:hover {
            overflow: overlay;
          }
        }

        .block-group {
          .block {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .add-assets {
              display: flex;
              align-items: center;
              margin-bottom: 0;

              i {
                font-size: 20px;
                color: #1c285a;
                margin-right: 10px;
              }

              p {
                font-size: 14px;
                font-weight: 500;
                color: #1c285a;
                margin: 0px;
              }
            }

            .submit-area {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              p {
                margin-bottom: 0px;
                margin-right: 10px;
                color: #ced3e3;
                font-size: 14px;
              }

              button {
              }
            }
          }
        }
      }
    }

    .comment-area {
      display: flex;
      margin-top: 50px;

      .avatar {
        img {
          margin-right: 15px;
        }
      }

      .card-alt {
        width: 100%;

        h6 {
          font-weight: 600;
          margin-bottom: 15px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .add-comment-area {
      display: flex;

      .input-default {
        width: 100%;
        display: flex;

        &::placeholder {
          color: red;
        }

        .form-control {

        }

        button {
          font-size: 12px;
        }

        .block-group {
          .add-assets {
            display: flex;
            align-items: center;

            i {
              font-size: 20px;
              color: #1c285a;
              margin-right: 10px;
            }

            p {
              color: #1c285a;
              margin: 0px;
            }
          }
        }
      }
    }

    .view-post-area {
      .post-content {
        h5 {
          font-weight: 600;
          margin-bottom: 15px;
        }

        p {
          margin-bottom: 5px;
        }

        .thumb-lg {
          width: 100%;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            vertical-align: middle;
            border-radius: 5px;
          }
        }

        .post-by {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .avatar {
            margin-right: 10px;
          }

          .post-by-info {
            h6 {
              font-weight: 600;
              margin-bottom: 5px;
            }

            p {
              font-size: 12px;
              color: #acb3c9;
              margin-bottom: 0px;
            }
          }
        }
      }

      .post-status {
        font-size: 14px;
        font-weight: 400;
        color: #939393;
        margin-top: 12px;
        margin-bottom: 10px;
      }

      .like-comment-share {
        display: flex;
        justify-content: space-evenly;
        margin-top: 5px;
        margin-bottom: 0px;
        padding: 0;
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;

        .box {
          display: flex;
          align-items: center;
          margin-right: 0px;
          background: #FFFFFF;
          padding: 5px 10px;
          width: 100%;
          justify-content: center;
          box-shadow: 0px 3px 3px 0px RGB(146 153 177 / 5%);
          border-radius: 2px;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            background: #e9eff7;
          }

          img {
            width: 30px;
            margin-right: 10px;
          }

          p {
            font-size: 12px;
            color: #949aa9;
            margin: 0px;

            b {
              color: black;
            }
          }
        }

        .comment-count {
          display: flex;
          align-items: center;
          background: #FFFFFF;
          padding: 5px 10px;
          width: 100%;
          justify-content: center;
          box-shadow: 0px 3px 3px 0px RGB(146 153 177 / 5%);
          border-radius: 2px;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            background: #e9eff7;
          }

          img {
            width: 28px;
          }
        }
      }

      .comment-area {
        display: flex;
        margin-top: 0px;
        background: transparent;
        padding: 8px 10px;
        padding-bottom: 0px;
        border-radius: 15px;

        &:first-child {
          padding-top: 10px;
        }

        .avatar {
          z-index: 1;

          img {
            margin-right: -8px;
            padding: 3px;
            background: #FFFFFF;
          }
        }

        .card-alt {
          width: auto;
          min-width: 100px;
          margin-bottom: 0px;
          padding: 7px 16px;
          background-color: #f0f2f5;
          border-radius: 20px;

          h6 {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 5px;
          }

          p {
            font-size: 12px;
            color: #000000;
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .block-right {
    width: calc(26% - 0px);
    position: fixed;
    right: 30px;
    padding-bottom: 120px;
    height: 100%;
    overflow: hidden;

    &:hover {
      overflow: overlay;
    }

    .card-default-transparent {
      .sidebar-btn-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        padding: 0 15px;

        button {
          font-size: 12px;
          margin-right: 10px;
          margin-bottom: 12px;
        }
      }

      #calender {
        padding: 0 15px;
        .header {
          background-color: #fff3f4;
          padding: 15px 20px;
          border-radius: 6px;
          margin-bottom: 15px;

          h6 {
            margin: 0px;
          }
        }

        .block-group {
          justify-content: start;

          .block-25 {
            width: calc(24% - 2px);
            background: #ffffff73;
            border: 1px dashed #1f2d42a1;
            border-radius: 20px;
            padding: 5px 3px;
            margin-right: 5px;
            margin-bottom: 6px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            &:hover {
              background-color: #f1f3f9;
            }

            .reminder {
              background-color: transparent;
              padding: 3px 0px;
              border-radius: 6px;
              margin-left: 5px;

              p {
                font-size: 10px;
                font-weight: 500;
                margin: 0px;
              }
              .event {
                background: #ffa814;
                color: #000000 !important;
                border-radius: 25px;
                padding: 2px 5px;
                text-align: center;
              }
              .assignment {
                background: #11cdef;
                color: #000000 !important;
                border-radius: 25px;
                padding: 2px 5px;
                text-align: center;
              }
            }

            .date {
              margin-left: 5px;

              h4 {
                margin: 5px 0px;
                font-size: 11px;
                font-weight: 600;
                color: #000000;
              }
            }
          }
        }

        @include xxlg {
          .block-group {
            justify-content: flex-start;

            .block-25 {
              width: calc(25% - 0px);
            }
          }
        }
      }

      .tabs {
        .tab-content {
          max-height: 235px;
          overflow: hidden;
          mix-blend-mode: screen;
          box-shadow: inset 0px -7px 6px -4px RGB(0 0 0/8%);
          padding: 12px;
          border-radius: 15px;
          &:hover {
            overflow: overlay;
            scroll-behavior: smooth;
          }
        }
      }

      hr {
        margin: 10px 16px;
        background: #f7f9ff;
      }
    }

    hr {
      margin: 20px 16px;
      background: #f7f9ff;
    }
  }


  #newAssigments {
    .task-list {
      textarea {
        background-color: #f1f3f9;
      }
    }
  }

  #assigmentTaskSubmitView {
    .card-default {
      .qns-heading {
        margin-bottom: 30px;
        padding: 20px 25px;
        border-left: 8px solid #11cdef;
      }

      .left-icon-heading-sm {
        margin-bottom: 15px;
        color: #004c58;

        i {

        }

        p {

        }
      }

      .ans-card {
        .ans-desc {
          background-color: #ffffff;
          display: flex;
          padding: 20px 30px;
          border-radius: 8px;
          margin-bottom: 32px;

          p {
            font-size: 14px;
            line-height: 1.9;
            margin-bottom: 0px;
          }
        }

        .comment-area {
          margin-top: 18px;

          .avatar {
            img {
            }
          }

          .card-alt {
            background-color: #d9eef3;

            h6 {
            }

            p {
            }
          }
        }

        .add-comment-area {
          margin-top: 20px;
        }
      }
    }
  }

  @include xxlg {
    .block-center {
      width: calc(100% - 0px);
      padding: 0px 2%;

      .add-post-area {
        .input-default {
          .form-control {
          }
          .block-group {
            .block {
              .add-assets {
                i {
                }
                p {
                  font-size: 12px;
                }
              }
              .submit-area {
                p {
                  font-size: 12px;
                }
                button {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .view-post-area {
        .post-content {
          h5 {
          }
          p {
            font-size: 12px;
          }
          .thumb-lg {
            img {
            }
          }
          .post-by {
            .avatar {
            }
            .post-by-info {
              h6 {
                font-size: 14px;
              }
              p {
              }
            }
          }
        }
        .post-status {
          font-size: 14px;
          font-weight: 400;
          color: #939393;
          margin-top: 25px;
          margin-bottom: 10px;
        }
        .like-comment-share {
          display: flex;
          justify-content: flex-start;
          margin-top: 5px;
          margin-bottom: 0px;
          padding: 12px 5px;
          border-top: 1px solid #f3f3f3;
          .box {
            display: flex;
            align-items: center;
            margin-right: 22px;
            background: #FFFFFF;
            padding: 6px 14px;
            border: 1px solid #c4e8ef;
            box-shadow: 0px 3px 3px 0px RGB(146 153 177 / 5%);
            border-radius: 15px;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
              background: #effaff;
            }
            img {
              width: 17px;
              margin-right: 10px;
            }
            p {
              font-size: 12px;
              color: #949aa9;
              margin: 0px;

              b {
                color: black;
              }
            }
          }
          .comment-count {
            display: flex;
            align-items: center;
            margin-right: 22px;
            background: #FFFFFF;
            padding: 6px 14px;
            border: 1px solid #d9d5f1;
            box-shadow: 0px 3px 3px 0px RGB(146 153 177 / 5%);
            border-radius: 15px;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              background: #f2f0ff;
            }

            img {
              width: 16px;
            }
          }
        }

        .comment-area {
          display: flex;
          margin-top: 0px;
          background: transparent;
          padding: 20px 20px;
          padding-bottom: 0px;
          border-radius: 15px;

          &:last-child {
            padding-bottom: 20px;
          }

          .avatar {
            z-index: 1;

            img {
              margin-right: -8px;
              padding: 3px;
              background: #FFFFFF;
            }
          }

          .card-alt {
            width: auto;
            min-width: 100px;
            margin-bottom: 0px;
            padding: 7px 13px;
            background-color: #eceff9;
            border-radius: 8px;

            h6 {
              font-size: 12px;
              font-weight: 600;
              margin-bottom: 5px;
            }

            p {
              font-size: 12px;
              color: #000000;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
    .block-right {
      width: calc(30% - 0px);
    }
  }
  @include xxl {
    .block-center {

    }
    .block-right {
      .card-default-transparent {
        #calender {
          .block-group {
            .block-25 {
              width: calc(25% - 0px);
            }
          }
        }
      }
    }
  }

  @include xl {
    .block-center {
      padding: 0px 1%;
    }
    .block-right {
      width: calc(28% - 0px);
      .card-default-transparent {
        #calender {
          .block-group {
            .block-25 {
              width: calc(25% - 0px);
            }
          }
        }
      }
    }
  }

  @include lg {
    .block-center {
    }
    .block-right {
      .card-default-transparent {
        #calender {
          .block-group {
            .block-25 {
              width: calc(30% - 0px);
            }
          }
        }
      }
    }
  }

  @include sm {
    .block-center {
      width: 100%;
    }

    .block-right {
      width: 100%;
    }
  }

  @include xs {
    .block-center {
      padding-left: 0px;
    }
  }
}
